import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ServiceService } from 'src/app/service.service';
import { LoadingComponent } from 'src/app/utils/loading.component';

import { MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-editar-aluno',
  templateUrl: './editar-aluno.component.html',
  styleUrls: ['./editar-aluno.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class EditarAlunoComponent implements OnInit {

  submitted = false;

  isLinear = true;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  notifier: NotifierService;
  id_escola: number;
  user: any;

  estados: string[] = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
    'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
    'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
    'SC', 'SP', 'SE', 'TO'
  ];

  turnos: string[] = ['Manhã', 'Tarde', 'Noite', 'Integral'];

  turmas: string[] = ['A', 'B', 'C', 'D', 'E', 'F'];

  series: string[] = [
    '1º ano Fundamental', '2º ano Fundamental', '3º ano Fundamental', '4º ano Fundamental',
    '5º ano Fundamental', '6º ano Fundamental', '7º ano Fundamental', '8º ano Fundamental',
    '9º ano Fundamental', '1º ano Médio', '2º ano Médio', '3º ano Médio',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditarAlunoComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private service: ServiceService
  ) {
    this.notifier = notifierService;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.id_escola = this.user.id_escola;

    this.data.aluno.data_nascimento = this.dataNascimentoFormatada(this.data.aluno.data_nascimento);
  }

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      id_escola: this.id_escola,
      data_nascimento: [this.data.aluno.data_nascimento, Validators.required],
      nome_aluno: [this.data.aluno.nome_aluno, Validators.required],
      sexo_aluno: [this.data.aluno.sexo_aluno, Validators.required],
      serie_aluno: [this.data.aluno.serie_aluno, Validators.required],
      turno_aluno: [this.data.aluno.turno_aluno],
      turma_aluno: [this.data.aluno.turma_aluno],
      tel_aluno: [this.data.aluno.tel_aluno, Validators.required]
    }
    );
    this.secondFormGroup = this.formBuilder.group({
      estado: [this.data.aluno.estado],
      municipio: [this.data.aluno.municipio],
      bairro: [this.data.aluno.bairro],
      logradouro: [this.data.aluno.logradouro],
      numero: [this.data.aluno.numero],
      cep: [this.data.aluno.cep],
      complemento: [this.data.aluno.complemento]
    });
  }
  
  dataNascimentoFormatada(date) {
    var data = new Date(date),
      dia = (data.getDate() + 1).toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return anoF + "-" + mesF + "-" + diaF;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  editarAluno() {
    const loading = this.dialog.open(LoadingComponent, {
      width: '150px',
    });

    const objAluno = {
      usuario: {},
      endereco: this.secondFormGroup.value,
      aluno: this.firstFormGroup.value
    };

    const obj = {
      ids: {
        id_aluno: this.data.aluno.id_aluno,
        id_endereco: this.data.aluno.id_endereco,
        id_escola: this.data.aluno.id_escola,
        id_usuario: this.data.aluno.id_usuario
      },
      aluno: objAluno
    };

    this.service.verificarSeTelefoneAlunoPossuiCadastro(this.firstFormGroup.value.tel_aluno).subscribe(data => {
      if (data.json().length > 0 && data.json()[0].id_aluno != obj.ids.id_aluno) {
        this.showAlert('warning', 'Este Telefone já possui cadastro! Tente outro número!');
        loading.close();
      } else {
        this.service.editarAluno(obj).subscribe(res => {
          console.log(res);
          this.showAlert('success', 'Sucesso!!');
          this.onNoClick();
          loading.close();
        }, err => {
          console.log(err);
          this.showAlert('error', err);
          loading.close();
        });
      }
    });

  }



  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 5000);
  }

}
