import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ServiceService } from '../service.service';

@Injectable({
  providedIn: 'root'
})
export class PainelAdminService {

  private headers: {};
  private Headers: Headers;

  constructor(private http: Http) {

    this.Headers = new Headers();
    this.Headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.Headers.append('Content-Type', 'application/json');
    this.Headers.append('Authorization', 'Auth_Token');
    this.Headers.append('RequestToken', localStorage.getItem('token'));
    this.headers = { headers: this.Headers };
  }

  alterarStatusEscola(obj) {
    return this.http.put(ServiceService.URL + '/escola/alterarStatusEscola', obj, this.headers);
  }


}


