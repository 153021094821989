<body class="is-boxed has-animations">
  <div class="body-wrap boxed-container" (scroll)="onWindowScroll($event);">
    <app-menu></app-menu>
    <main>
      <header id="fh5co-header" class="fh5co-cover" role="banner"
        style="background-image:url(assets/img/site/bg-nobu.png);" data-stellar-background-ratio="0.5">
        <!-- <div class="overlay"></div> -->
        <div class="player">
          <div class="row">
            <div class="col-md-12">
              <div class="display-t">
                <!-- Video com iframe -->
                <!-- <iframe width="2048px" height="100%" src="https://www.youtube.com/embed/n1fjJ6pLrag?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

                <!-- Video com Lib Jquery -->
                <!-- <div id="bgVideo" class="player"
                  data-property="{
                    videoURL:'https://www.youtube.com/watch?v=n1fjJ6pLrag',
                    containment:'#fh5co-header',
                    startAt:0,
                    mute:false,
                    autoPlay:true,
                    showControls:true,
                    quality:'hd1080p',
                    useOnMobile:true,
                    optimizeDisplay:true,
                    playOnlyIfVisible:true,
                    anchor:'center,center',
                    loop:3,
                    vol:3,
                    opacity:1}">
                </div> -->

                <div class="play player-nobullying" data-video-id="n1fjJ6pLrag">
                  <img src="assets/img/site/play.png" alt="">
                </div>

              </div><!-- display-t -->
            </div>
          </div>
        </div><!-- container -->
      </header><!-- fh5co-header -->

      <div id="fh5co-project">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading  animate-box">
              <span>#Nobully #História #App</span>
              <h2>Quem somos</h2>
              <p>Conheça nossa história</p>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-6 col-md-push-6">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn">O projeto NoBully é uma startup
                    alagoana, que surgiu da ideia de Allan Duarte, que sofreu Bullying em sua
                    adolescência e não soube como lidar, o que lhe trouxe problemas pessoais na época.
                    Superando seus desafios e buscando ajudar outras pessoas que passam por situações
                    semelhantes, juntamente com João Luiz e Janiel Tertuliano, lapidaram a ideia e
                    começaram a desenvolver o projeto. É sabido que a tecnologia viabiliza o
                    cyberbullying, mas também pode ser um meio para proporcionar ações benéficas, como a
                    conscientização, prevenção e o combate ao Bullying. Foi pensando nisso, que o
                    NoBully é um projeto inovador que usa a tecnologia em prol do combate a essa causa e
                    a cada dia vem se profissionalizando e buscando novas ferramentas e soluções para
                    melhorias na qualidade de serviço, segurança e praticidade.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-md-pull-6">
              <div class="fh5co-img">
                <figure class="fh5co-figure  animate-box" data-animate-effect="fadeIn">
                  <img class="img-2" src="assets/img/site/intro-quem.png" alt="01">
                </figure>
              </div>
            </div>
          </div><!-- row -->
          <div class="row row-pb-md gtco-services">
            <div class="col-md-4 col-sm-4 service-wrap">
              <div class="service animate-box" data-animate-effect="fadeInUp">
                <h3>
                  <i class="icon-command"></i>
                  Missão
                </h3>
                <p> Fomentar a conscientização, prevenção e combate ao Bullying através da tecnologia e
                  inovação. Promover um canal de comunicação acolhedor às vítimas de Bullying, em foco as
                  ocorrências nas escolas. Educar e combater em detrimento ao Bullying.</p>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 service-wrap">
              <div class="service animate-change changed animate-box" data-animate-effect="fadeInUp">
                <h3>
                  <i class="icon-eye"></i>
                  Visão
                </h3>
                <p>Tornar-se referência nacional, quiçá, internacional como ferramenta antibullying,
                  combatendo-o com uma metodologia inovadora e de forma exemplar.</p>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 service-wrap">
              <div class="service animate-box" data-animate-effect="fadeInUp">
                <h3>
                  <i class="icon-layers"></i>
                  Valores
                </h3>
                <p>Idoneidade, compromisso social, respeito ao próximo; promover a cidadania, a capacidade
                  empática, uma cultura de paz e tolerância mútua; sigilo e credibilidade.</p>
              </div>
            </div>
            <div class="clearfix visible-md-block visible-sm-block"></div>
          </div>

          <div class="row fh5co-feature fh5co-reverse">
            <div class="col-md-6">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner animate-box" data-animate-effect="fadeInUp">
                  <h2>O que é bullying ?</h2>
                  <p>O termo Bullying foi criado na década de 70 pelo psicólogo sueco Dan Olweus, seu
                    radical – BULLY – é um termo da língua inglesa que significa “valentão”. Refere-se
                    também a todas as formas de atitudes agressivas, sejam elas verbais, psicológicas ou
                    físicas; intencionais e repetitivas; sem motivação evidente. São exercidas por um ou
                    mais indivíduos causando dor e angustia à(s) vítima(s), em uma relação de
                    desequilíbrio de poder entre as partes envolvidas.</p>
                  <a routerLink="/bullying"><button class="btn-green btn-special">Saiba Mais</button></a>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="fh5co-img">
                <figure class="fh5co-figure animate-box">
                  <img class="img-2" src="assets/img/site/bullying-01.png" alt="Bully">
                </figure>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading">
              <span>#Patrocidaores #noBully</span>
              <h2 class="animate-box">Seja um herói!</h2>
              <p>Saiba como se tornar um colaborador desta causa.</p>
              <div class="buttons-more">
                <a routerLink="/donation" class="btn-green btn-special">Saiba como doar</a>
                <a routerLink="/partner" class="btn-green btn-special">Saiba como
                  adotar</a>
              </div>
            </div>
          </div><!-- row -->
          <div id="fh5co-testimonial" class="row">
            <div class="col-md-6 animate-box">
              <div class="testimonial">
                <blockquote>
                  <p>&ldquo; Vou falar o que acho do bullying. Ocorre geralmente nas escolas, é praticado
                    por pessoas de todos os tipos, entao isso ta machucando muitas pessoas. Bullying não
                    é legal! App NoBully é muito bom! Pode me ajudar e ajudar a você. Faça sua
                    parte.&rdquo;</p>
                  <p class="author"> <img src="assets/img/site/02.jpeg"
                      alt="Free HTML5 Bootstrap Template by gettemplates.co">
                    <cite>&mdash; Luiz
                      gabriel, 9 anos</cite></p>
                </blockquote>
              </div>

              <div class="testimonial fh5co-selected">
                <blockquote>
                  <p>&ldquo;Toda informação de combate ao bullying vinda de qualquer meio de comunicação e
                    principalmen te ligadas ao meio digital é de grande importância,pois pode acabar com
                    a dimensão deste problema gravíssimo que é o bullying! As escolas estão precisando
                    muito da ajuda de todos seja: Meios de comunicação, especialistas, pedagogos e
                    principalmente o aplicativo NoBully, pois as crianças e os jovens vivem conectados e
                    com certeza teremos um público bem abrangente para combater o bullying.Vamos lá!!As
                    escolas agradecem!.&rdquo;</p>
                  <p class="author"><img src="assets/img/site/04.jpeg"
                      alt="Free HTML5 Bootstrap Template by gettemplates.co">
                    <cite>&mdash; Giselma
                      Torres, professora da rede pública e rede privada</cite></p>
                </blockquote>
              </div>
            </div>
            <div class="col-md-6 animate-box">
              <div class="testimonial fh5co-selected">
                <blockquote>
                  <p>&ldquo;Pra mim o app NoBully foi uma coisa incrivel , e vai me ajudar com certeza e a
                    outras crianças a superar o bullying que é um grave problema atual nas
                    escolas.&rdquo;</p>
                  <p class="author"><img src="assets/img/site/01.jpeg"
                      alt="Free HTML5 Bootstrap Template by gettemplates.co">
                    <cite>&mdash; Ana Clara,
                      10 anos</cite></p>
                </blockquote>
              </div>

              <div class="testimonial">
                <blockquote>
                  <p>&ldquo;O app nobully é uma ferramenta importante ao combate ao bullying nas escolas.
                    O mapeamento e análise de dados nos permite saber como e onde agir de acordo com a
                    incidência e características do ato de violência escolar. Enquanto professor de
                    sociologia entendo a urgência de mecanismos de análise, prevenção e intervenção no
                    espaço escolar, para assim, mantermos a experiência escolar do estudante
                    satisfatória e segura. O Bullying não é brincadeira, não é engraçado é sintoma de
                    uma sociedade que não aceita a alteridade, o outro e que, portanto, deve ser
                    combatido..&rdquo;</p>
                  <p class="author"><img src="assets/img/site/03.jpeg"
                      alt="Free HTML5 Bootstrap Template by gettemplates.co">
                    <cite>&mdash; Adolfo
                      Silva, Professor de Sociologia</cite></p>
                </blockquote>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading">
              <span>#Patrocidaores #Parceiros #noBully</span>
              <h2 class="animate-box">Patrocinadores e Parceiros</h2>
              <p class="animate-box" data-animate-effect="fadeInUp">Conheça nossos patrocinadores</p>
            </div>
          </div><!-- row -->

          <div class="container">
            <owl-carousel [options]="mySlideOptions" [carouselClasses]="['owl-theme', 'sliding']">
              <div class="item">
                <div class="owl-content">
                  <div class="owl-image">
                    <img src="assets/img/site/nextframe-logo.png" alt="NextFrame" />
                  </div>
                  <div class="owl-title">
                    <p class="owl-header">NextFrame</p>
                  </div>
                </div>
              </div>
            </owl-carousel>
          </div>

        </div><!-- container -->
      </div><!-- fh5co-project -->

      <app-footer></app-footer>
    </main>
  </div>
</body>
