<footer class="site-footer text-light">
    <div class="container">
      <div class="site-footer-inner has-top-divider">
        <div class="brand footer-brand">
            <a href="http://www.nobully.com.br">
              <img src="assets/img/marca_horizontal_.png" alt="NoBully" />
            </a>
        </div>
        <ul class="footer-links list-reset">
            <li>
                <a href="https://web.whatsapp.com/send?phone=558281199568" target="_blank">Contato</a>
            </li>
            <li>
                <a href="#">Sobre</a>
            </li>
            <li>
                <a href="#">FAQ's</a>
            </li>
            <li>
                <a href="#">Suporte</a>
            </li>
        </ul>
        <ul class="footer-links list-reset">
            <li>
                <a href="../assets/pdf/POLITICA_DE_PRIVACIDADE_DO_APP_ALUNO.pdf" target="_blank">Políticas de privacidade App Aluno</a>
            </li>
            <li>
                <a href="../assets/pdf/NOVA_POLITICA_DE_PRIVACIDADE_DO_APP_GESTAO.pdf" target="_blank">Políticas de privacidade App Aluno</a>
            </li>
        </ul>   
        <ul class="footer-social-links list-reset">
            <li>
                <a href="https://www.facebook.com/appnobully/">
                    <span class="screen-reader-text">Facebook</span>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 90 90" style="enable-background:new 0 0 90 90;" xml:space="preserve">
                        <g>
                            <path fill="#FFFFFF" id="Facebook__x28_alt_x29_" d="M90,15.001C90,7.119,82.884,0,75,0H15C7.116,0,0,7.119,0,15.001v59.998
                                C0,82.881,7.116,90,15.001,90H45V56H34V41h11v-5.844C45,25.077,52.568,16,61.875,16H74v15H61.875C60.548,31,59,32.611,59,35.024V41
                                h15v15H59v34h16c7.884,0,15-7.119,15-15.001V15.001z"/>
                        </g>
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/app_nobully/">
                    <span class="screen-reader-text">Instagram</span>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 97.395 97.395" style="enable-background:new 0 0 97.395 97.395;"  xml:space="preserve">
                        <g>
                            <path fill="#FFFFFF" d="M12.501,0h72.393c6.875,0,12.5,5.09,12.5,12.5v72.395c0,7.41-5.625,12.5-12.5,12.5H12.501C5.624,97.395,0,92.305,0,84.895
                                V12.5C0,5.09,5.624,0,12.501,0L12.501,0z M70.948,10.821c-2.412,0-4.383,1.972-4.383,4.385v10.495c0,2.412,1.971,4.385,4.383,4.385
                                h11.008c2.412,0,4.385-1.973,4.385-4.385V15.206c0-2.413-1.973-4.385-4.385-4.385H70.948L70.948,10.821z M86.387,41.188h-8.572
                                c0.811,2.648,1.25,5.453,1.25,8.355c0,16.2-13.556,29.332-30.275,29.332c-16.718,0-30.272-13.132-30.272-29.332
                                c0-2.904,0.438-5.708,1.25-8.355h-8.945v41.141c0,2.129,1.742,3.872,3.872,3.872h67.822c2.13,0,3.872-1.742,3.872-3.872V41.188
                                H86.387z M48.789,29.533c-10.802,0-19.56,8.485-19.56,18.953c0,10.468,8.758,18.953,19.56,18.953
                                c10.803,0,19.562-8.485,19.562-18.953C68.351,38.018,59.593,29.533,48.789,29.533z"/>
                        </g>
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCzX-7weJtu3Om6Vi73ytnAA">
                    <span class="screen-reader-text">Youtube</span>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 310 310" style="enable-background:new 0 0 310 310;" xml:space="preserve">
                        <g id="XMLID_822_">
                            <path fill="#FFFFFF" id="XMLID_823_" d="M297.917,64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359,0-61.369,5.776-72.517,19.938
                                C0,79.663,0,100.008,0,128.166v53.669c0,54.551,12.896,82.248,83.386,82.248h143.226c34.216,0,53.176-4.788,65.442-16.527
                                C304.633,235.518,310,215.863,310,181.835v-53.669C310,98.471,309.159,78.006,297.917,64.645z M199.021,162.41l-65.038,33.991
                                c-1.454,0.76-3.044,1.137-4.632,1.137c-1.798,0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764
                                c0-3.492,1.822-6.732,4.808-8.546c2.987-1.814,6.702-1.938,9.801-0.328l65.038,33.772c3.309,1.718,5.387,5.134,5.392,8.861
                                C204.394,157.263,202.325,160.684,199.021,162.41z"/>
                        </g>
                    </svg>
                </a>
            </li>
            <li>
                <a href="nobullysac@gmail.com">
                    <span class="screen-reader-text">Email</span>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 490.2 490.2" style="enable-background:new 0 0 490.2 490.2;" xml:space="preserve">
                        <g>
                            <path fill="#FFFFFF" d="M420.95,61.8C376.25,20.6,320.65,0,254.25,0c-69.8,0-129.3,23.4-178.4,70.3s-73.7,105.2-73.7,175
                                c0,66.9,23.4,124.4,70.1,172.6c46.9,48.2,109.9,72.3,189.2,72.3c47.8,0,94.7-9.8,140.7-29.5c15-6.4,22.3-23.6,16.2-38.7l0,0
                                c-6.3-15.6-24.1-22.8-39.6-16.2c-40,17.2-79.2,25.8-117.4,25.8c-60.8,0-107.9-18.5-141.3-55.6c-33.3-37-50-80.5-50-130.4
                                c0-54.2,17.9-99.4,53.6-135.7c35.6-36.2,79.5-54.4,131.5-54.4c47.9,0,88.4,14.9,121.4,44.7s49.5,67.3,49.5,112.5
                                c0,30.9-7.6,56.7-22.7,77.2c-15.1,20.6-30.8,30.8-47.1,30.8c-8.8,0-13.2-4.7-13.2-14.2c0-7.7,0.6-16.7,1.7-27.1l18.6-152.1h-64
                                l-4.1,14.9c-16.3-13.3-34.2-20-53.6-20c-30.8,0-57.2,12.3-79.1,36.8c-22,24.5-32.9,56.1-32.9,94.7c0,37.7,9.7,68.2,29.2,91.3
                                c19.5,23.2,42.9,34.7,70.3,34.7c24.5,0,45.4-10.3,62.8-30.8c13.1,19.7,32.4,29.5,57.9,29.5c37.5,0,69.9-16.3,97.2-49
                                c27.3-32.6,41-72,41-118.1C488.05,152.9,465.75,103,420.95,61.8z M273.55,291.9c-11.3,15.2-24.8,22.9-40.5,22.9
                                c-10.7,0-19.3-5.6-25.8-16.8c-6.6-11.2-9.9-25.1-9.9-41.8c0-20.6,4.6-37.2,13.8-49.8s20.6-19,34.2-19c11.8,0,22.3,4.7,31.5,14.2
                                s13.8,22.1,13.8,37.9C290.55,259.2,284.85,276.6,273.55,291.9z"/>
                        </g>
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://web.whatsapp.com/send?phone=558281199568">
                    <span class="screen-reader-text">Whatsapp</span>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 16.087 16.087" style="enable-background:new 0 0 16.087 16.087;" xml:space="preserve">
                        <g>
                            <g>
                                <path fill="#FFFFFF" d="M6.5,3.478c-3.59,0-6.5,2.238-6.5,5c0,1.354,0.701,2.585,1.839,3.485
                                    c-0.066,0.918-0.287,2.041-0.927,2.646c1.274,0,2.576-0.798,3.399-1.422c0.684,0.188,1.42,0.291,2.189,0.291
                                    c3.59,0,6.5-2.237,6.5-5C13,5.716,10.09,3.478,6.5,3.478z"/>
                                <path fill="#FFFFFF" d="M15.14,8.965C15.687,8.234,16,7.384,16,6.478c0-2.762-2.91-5-6.5-5
                                    c-1.58,0-3.028,0.434-4.154,1.154C5.876,2.532,6.43,2.478,7,2.478c3.866,0,7,2.463,7,5.5c0,1.197-0.485,2.306-1.312,3.207
                                    c0.821,0.624,2.125,1.424,3.399,1.424C15.181,11.749,15.116,9.843,15.14,8.965z"/>
                            </g>
                        </g>
                    </svg>
                </a>
            </li>
        </ul>
        <div class="footer-copyright">&copy; 2019 NoBully. Todos os direitos reservados.</div>
      </div>
    </div>
  </footer>
