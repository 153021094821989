import {
  MatButtonModule, MatFormFieldModule, MatInputModule,
  MatRippleModule, MatCardModule, MatOptionModule,
  MatSelectModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialog,
  MatNativeDateModule,
  MatDialogModule,
  MatListModule,
  MatStepperModule,
  MatProgressSpinnerModule, MatToolbarModule, MatSidenavModule,
  MatIconModule,
  MatGridListModule,
  MatMenuModule,
  MatTabsModule,
  MatTableModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatBadgeModule,
  MatCheckboxModule,
  MatRadioModule
} from '@angular/material';

import { BrowserModule } from '@angular/platform-browser';

import { NgsRevealModule } from 'ngx-scrollreveal';

import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routingComponents, AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceService, AuthGuard, PermissionGuard } from './service.service';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NotifierModule } from 'angular-notifier';
import { LoadingComponent } from './utils/loading.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlunosModule } from './alunos/alunos.module';
import { NgxMaskModule } from 'ngx-mask';
import { DashBoardService } from './dashboard/dashboard.service';
import { EscolaModule } from './escola/escola.module';
import { EmailDialogComponent } from './login/EmailDialog.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EscolasModule } from './painel-admin/escolas/escolas.module';
import { PainelAdminService } from './painel-admin/painel-admin.service';
import { SocketService } from './socket.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SalePageComponent } from './site-layout/pages/sale-page/sale-page.component';
import { FooterComponent } from './site-layout/footer/footer.component';
import { MenuComponent } from './site-layout/menu/menu.component';
import { DonationPageComponent } from './site-layout/pages/donation-page/donation-page.component';
import { PageSeguroService } from './provider/pagseguro.service';
import { DatePipe } from '@angular/common';
import { VarGlobalProvider } from './provider/var-global';
import { ModalPagseguroComponent } from './site-layout/pages/sale-page/modal-pagseguro/modal-pagseguro.component';
import { RelatosModule } from './relatos/relatos.module';
import { ScrollSmoothDirective } from './directives/scroll-smooth.directive';
import { OwlModule } from 'ngx-owl-carousel';
import { BullyingComponent } from './site-layout/pages/bullying/bullying.component';
import { InicioComponent } from './site-layout/pages/inicio/inicio.component';
import { PartnerPageComponent } from './site-layout/pages/partner-page/partner-page.component';
import { CheckoutPagseguroComponent } from './site-layout/checkout-pagseguro/checkout-pagseguro.component';
import { NgxCurrencyModule } from "ngx-currency";
import { CheckoutTransparentComponent } from './site-layout/checkout-transparent/checkout-transparent.component';
import { ModalPartnerComponent } from './site-layout/pages/partner-page/modal-partner/modal-partner.component';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    LoadingComponent,
    EmailDialogComponent,
    MainNavComponent,
    DashboardComponent,
    NotFoundComponent,
    SalePageComponent,
    FooterComponent,
    MenuComponent,
    DonationPageComponent,
    PartnerPageComponent,
    ModalPagseguroComponent,
    ScrollSmoothDirective,
    InicioComponent,
    BullyingComponent,
    CheckoutPagseguroComponent,
    CheckoutTransparentComponent,
    ModalPartnerComponent
  ],
  imports: [
    AlunosModule,
    EscolaModule,
    EscolasModule,
    RelatosModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OwlModule,
    LayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatInputModule,
    MatRippleModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatListModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      }
    }),
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatGridListModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    InfiniteScrollModule,
    NgsRevealModule,
    NgxCurrencyModule,
    NgxMaskModule.forRoot()
  ],
  entryComponents: [
    AppComponent,
    routingComponents,
    EmailDialogComponent,
    LoadingComponent,
    ModalPagseguroComponent,
    ModalPartnerComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    ServiceService,
    PainelAdminService,
    DashBoardService,
    AuthGuard,
    PermissionGuard,
    SocketService,
    PageSeguroService,
    VarGlobalProvider,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
