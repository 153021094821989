<mat-card-header>
  <div mat-card-avatar>
    <img *ngIf="denuncia.anonimo" src="assets/img/user.png">
    <img *ngIf="!denuncia.anonimo" src="assets/img/boy.png">
  </div>
  <mat-card-title *ngIf="denuncia.anonimo">Anônimo</mat-card-title>
  <mat-card-title *ngIf="!denuncia.anonimo">{{ denuncia.nome_aluno }}</mat-card-title>
  <mat-card-subtitle>
    {{ denuncia.dth_cadastro | date: 'dd/MM/yyyy hh:mm'}} {{ denuncia.hora_denuncia }}
    <span class="label label-primary" *ngIf="desc_tipo_denuncia != 'Emergência'">{{ desc_tipo_denuncia }}</span>
    <span class="label label-danger" *ngIf="desc_tipo_denuncia == 'Emergência'">{{ desc_tipo_denuncia }}</span>
  </mat-card-subtitle>
</mat-card-header>
<mat-card-content>

  <mat-tab-group>
    <mat-tab label="Tipos de bullying sofridos">
      <mat-list *ngFor="let item of arrayTiposBullying">
        <mat-list-item> {{ item.desc_tipo_bullying }} </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </mat-tab>
    <mat-tab label="Local Bullying">
      <mat-list>
        <mat-list-item> {{ localBullying }} </mat-list-item>
      </mat-list>
    </mat-tab>
    <mat-tab label="Local Bullying Escola">
      <mat-list>
        <mat-list-item> {{ localBullyingEscola }} </mat-list-item>
      </mat-list>
    </mat-tab>
    <mat-tab label="Sentimentos">
      <mat-list *ngFor="let item of arraySentimentos">
        <mat-list-item> {{ item.desc_sentimento }} </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </mat-tab>
  </mat-tab-group>
  <mat-divider></mat-divider>
  <mat-list>
    <h6 style="color: #1e88e5">Relato:</h6>
    <mat-list-item> {{ relato }} </mat-list-item>
  </mat-list>
</mat-card-content>

<button mat-raised-button color="primary" (click)="onNoClick()">Fechar</button>
