import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PageSeguroService } from 'src/app/provider/pagseguro.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { LoadingComponent } from 'src/app/utils/loading.component';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-checkout-pagseguro',
  templateUrl: './checkout-pagseguro.component.html',
  styleUrls: ['./checkout-pagseguro.component.css']
})
export class CheckoutPagseguroComponent implements OnInit {

  checkedTerms: boolean;
  credencial: any;
  tipo: string;
  valor: any;
  quantidade = 1;

  constructor(
    private pagSeguro: PageSeguroService,
    public dialog: MatDialog,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.iniciarSessionPagSeguro();
    this.tipo = this.route.snapshot.paramMap.get('page');
    if (this.tipo === 'partner') {
      this.valor = 2000;
    }
  }

  iniciarSessionPagSeguro() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.pagSeguro.iniciar('inovappweb@gmail.com',
      environment.pagSeguroToken,
      environment.isSandBox
    ).then(res => {
      this.credencial = JSON.parse(localStorage.getItem('credencial'));
      console.log(this.credencial);
      loading.close();
    }, err => {
      console.log(err);
      loading.close();
    });
  }

  pagSeguroLightbox(quantidade, description) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    const v = this.valor.toFixed(2);

    this.pagSeguro.checkoutPagSeguro({
      itemAmount1: v,
      itemQuantity1: quantidade,
      itemDescription1: description
    }, this.credencial).subscribe(data => {
      const code = data.json().code;

      $('#code').attr('action', this.credencial.urlCheckoutPayment);
      $('#code').val(code);
      $('#pagar').submit();

      console.log(code);
      console.log(this.credencial.urlCheckoutPayment);

      loading.close();

    }, err => {
      loading.close();
      console.log(err);
    });
  }

  pagSeguroRedirect(quantidade, description) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    const v = this.valor.toFixed(2);

    this.pagSeguro.checkoutPagSeguro({
      itemAmount1: v,
      itemQuantity1: quantidade,
      itemDescription1: description
    }, this.credencial).subscribe(data => {
      const code = data.json().code;

      window.open(this.credencial.urlCheckoutPayment + '?code=' + code);

      loading.close();

    }, err => {
      loading.close();
      console.log(err);
    });
  }

  addQuantidade() {
    this.quantidade = this.quantidade + 1;
  }

  removeQuantidade() {
    this.quantidade = this.quantidade - 1;
  }

  validator() {
    let valid = true;

    if (!this.checkedTerms) {
      valid = false;
    }

    if (!this.valor) {
      valid = false;
    }

    return valid;
  }

}
