import { Http } from '@angular/http';
import { Router } from '@angular/router';
import * as jwt from 'jwt-decode';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
var ServiceService = /** @class */ (function () {
    function ServiceService(http) {
        this.http = http;
        ServiceService.URL = environment.urlApi;
        // ServiceService.URL = 'http://nobully.com.br:5000';
        // ServiceService.URL = 'http://localhost:3000';
        this.Headers = new Headers();
        this.Headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.Headers.append('Content-Type', 'application/json');
        this.Headers.append('Authorization', 'Auth_Token');
        this.Headers.append('RequestToken', localStorage.getItem('token'));
        this.headers = { headers: this.Headers };
    }
    ServiceService.prototype.validarEmailUsuario = function (email) {
        return this.http.get(ServiceService.URL + '/usuarios/email/' + email);
    };
    ServiceService.prototype.cadastrarUsuarioEscola = function (obj) {
        return this.http.post(ServiceService.URL + '/usuarios/escola', obj, this.headers);
    };
    ServiceService.prototype.login = function (obj) {
        return this.http.post(ServiceService.URL + '/auth/login', obj, this.headers);
    };
    ServiceService.prototype.loginAdmin = function (obj) {
        return this.http.post(ServiceService.URL + '/auth/admin/login', obj, this.headers);
    };
    ServiceService.prototype.listarAlunos = function (id_escola, skip, limit) {
        if (skip === void 0) { skip = 0; }
        if (limit === void 0) { limit = 10; }
        return this.http.get(ServiceService.URL + '/aluno/id_escola/' + id_escola + ("/?skip=" + skip + "&limit=" + limit), this.headers);
    };
    ServiceService.prototype.getAlunoEscolaByName = function (id_escola, nome_aluno) {
        return this.http.get(ServiceService.URL + '/aluno/id_escola/' + id_escola + '/nome_aluno/' + nome_aluno, this.headers);
    };
    ServiceService.prototype.cadastrarAluno = function (obj) {
        return this.http.post(ServiceService.URL + '/aluno', obj, this.headers);
    };
    ServiceService.prototype.verificarSeTelefoneAlunoPossuiCadastro = function (tel_aluno) {
        return this.http.get(ServiceService.URL + '/aluno/validar_telefone/' + tel_aluno, this.headers);
    };
    ServiceService.prototype.getAlunoById = function (id) {
        return this.http.get(ServiceService.URL + "/aluno/id_aluno/" + id);
    };
    ServiceService.prototype.editarAluno = function (obj) {
        return this.http.put(ServiceService.URL + "/aluno/id_aluno/" + JSON.stringify(obj.ids), obj.aluno);
    };
    ServiceService.prototype.getEscolaById = function (id) {
        return this.http.get(ServiceService.URL + "/escola/id_escola/" + id);
    };
    ServiceService.prototype.editEscola = function (object) {
        return this.http.put(ServiceService.URL + "/escola/id_escola/" + JSON.stringify(object.ids), object.escola);
    };
    ServiceService.prototype.enviarEmailRecuperarSenhaEscola = function (email) {
        return this.http.get(ServiceService.URL + "/usuarios/recuperar_senha/" + email);
    };
    ServiceService.prototype.enviarEmailBemVindoParaUsuario = function (email) {
        return this.http.get(ServiceService.URL + "/usuarios/bem_vindo/" + email);
    };
    ServiceService.prototype.mensagemEmailEscolaCadastrada = function (obj) {
        return this.http.get(ServiceService.URL + "/usuarios/escola_cadastrada/" + JSON.stringify(obj));
    };
    ServiceService.prototype.listarEscola = function () {
        return this.http.get(ServiceService.URL + "/escola");
    };
    ServiceService.prototype.getEscolaByName = function (nome_escola) {
        return this.http.get(ServiceService.URL + "/escola/nome_escola/" + nome_escola);
    };
    ServiceService.prototype.verificaEscolaAtiva = function (id_escola) {
        return this.http.get(ServiceService.URL + "/usuarios/escola/" + JSON.stringify(id_escola) + "/ativa");
    };
    ServiceService.prototype.listarPlanos = function () {
        return this.http.get(ServiceService.URL + "/planos");
    };
    ServiceService.prototype.listEscolasByAtivo = function (ativo) {
        return this.http.get(ServiceService.URL + "/escola/lista?ativo=" + ativo);
    };
    ServiceService.prototype.listEscolasParaAdocao = function (ativo, tipo) {
        return this.http.get(ServiceService.URL + "/escola/lista?ativo=" + ativo + "&tipo_escola=" + tipo);
    };
    ServiceService.prototype.alterarDenunciaStatusEscola = function (object) {
        return this.http.put(ServiceService.URL + "/denuncia/status", object, this.headers);
    };
    ServiceService.prototype.insertParceiro = function (object) {
        return this.http.post(ServiceService.URL + "/parceiro", object, this.headers);
    };
    ServiceService.prototype.editarParceiro = function (object) {
        return this.http.put(ServiceService.URL + "/parceiro", object, this.headers);
    };
    ServiceService.prototype.findParceiroByCNPJ = function (cnpj) {
        return this.http.get(ServiceService.URL + "/parceiro/cnpj/" + cnpj);
    };
    ServiceService.URL = environment.urlApi;
    ServiceService.URL_ApiPagamento = environment.urlApiPagamento;
    ServiceService.ngInjectableDef = i0.defineInjectable({ factory: function ServiceService_Factory() { return new ServiceService(i0.inject(i1.Http)); }, token: ServiceService, providedIn: "root" });
    return ServiceService;
}());
export { ServiceService };
var AuthGuard = /** @class */ (function () {
    function AuthGuard(service, router) {
        this.service = service;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function () {
        // console.log(jwt(localStorage.getItem('token')));
        if (localStorage.getItem('token')) {
            var decoded = jwt(localStorage.getItem('token'));
            if (decoded.data.ativo === 'S' || decoded.data.login === 'admin') {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    return AuthGuard;
}());
export { AuthGuard };
var PermissionGuard = /** @class */ (function () {
    function PermissionGuard(service, router) {
        this.service = service;
        this.router = router;
        this.user = JSON.parse(localStorage.getItem('user'));
    }
    PermissionGuard.prototype.canActivate = function () {
        if (this.user.id_tipo_usuario === 3) {
            return true;
        }
        else {
            return false;
        }
    };
    return PermissionGuard;
}());
export { PermissionGuard };
