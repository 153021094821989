<body class="is-boxed has-animations">
  <div class="body-wrap boxed-container" (scroll)="onWindowScroll($event);">
    <app-menu></app-menu>
    <main>
      <div id="fh5co-project" style="margin-top: 70px">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading animate-box">
              <span>#Nobully #Informações #App</span>
              <h2>O que é Bullying?</h2>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-9 col-md-push-2">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn">É um termo da língua inglesa –
                    BULLY, que quer dizer “valentão”, “Tirano”. Refere-se também a todas as formas de
                    atitudes agressivas, sejam elas verbais, psicológicas ou físicas, intencionais e
                    repetitivas, sem nenhuma motivação evidente. São exercidas por um ou mais indivíduos
                    causando dor e angustia a alguém. O termo Bullying foi criado na década de 70 pelo
                    psicólogo sueco Dan Olweus.
                  </p>
                </div>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading animate-box">
              <span>#Nobully #Informações #App</span>
              <h2>Qual o Objetivo do Bullying?</h2>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-9 col-md-push-2">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn"> É intimidar ou agredir outras
                    pessoas que não tem a possibilidade ou capacidade de se defender, essa agressão
                    normalmente é realizada dentro de uma relação desigual de força ou poder.
                  </p>
                </div>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading animate-box">
              <span>#Nobully #Informações #App</span>
              <h2>Como se divide o Bullying?</h2>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-9 col-md-push-2">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn"> Divide-se em duas categorias:
                    Bullying Direto – É a forma mais comum entre os agressores masculinos.
                    Bullying Indireto – É a forma mais comum entre os agressores mulheres e crianças,
                    tendo como característica o isolamento social da vitima.


                    No Bullying geralmente a vitima tem medo do agressor por causa das ameaças ou mesmo
                    a concretização da violência física, sexual ou a perda dos meios de subsistência.
                    Esse é hoje um problema mundial podendo ocorrer em qualquer ambiente onde exista o
                    contato interpessoal como: escola, universidade, local de trabalho, família,
                    vizinho, no clube, na igreja, etc.

                  </p>
                </div>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading animate-box">
              <span>#Nobully #Informações #App</span>
              <h2>Bullying na Escola</h2>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-9 col-md-push-2">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn">
                    Existe uma tendência, na maioria das escolas, de não admitirem a prática do Bullying
                    entre seus alunos. Muitas vezes desconhecem o problema ou se negam a enfrenta-lo.
                    Conflitos entre crianças e adolescentes são comuns, uma vez que se trata de uma fase
                    de insegurança e de autoafirmação. Quando os desentendimentos são frequentes e geram
                    humilhações, é nesta situação que o Bullying se prolifera.
                    Esse tipo de agressão normalmente ocorre em espaços onde não existe a presença ou
                    supervisão de um adulto, geralmente na entrada ou saída de locais de convívio – como
                    em condomínios -, como ainda quando os professores não estão por perto, no caso das
                    escolas. Acontecem também de forma silenciosa, na sala de aula, com gestos, bilhetes
                    e outras formas de abuso e desrespeito. Quando ocorrem ataques físicos, se assim
                    forem expostos pela vitima ou se for de conhecimento publico, a família – um dos
                    principais agentes nessa situação- toma a atitude de transferir a
                    criança/adolescente da escola ao invés de enfrentar o problema ou tomar uma
                    providencia mais efetiva no local, o que torna o Bullying impune na maioria das
                    vezes.

                  </p>
                </div>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading animate-box">
              <span>#Nobully #Informações #App</span>
              <h2>Tipos de Bullying</h2>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-9 col-md-push-2">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn">
                    ° Cyberbullying - Ocorre por meio da tecnologia da informação, seja pelas redes
                    sociais, e-mail ou pelos celulares.
                    ° Verbal – Quando o Bullying acontece por meio de palavras de baixo calão, apelidos
                    pejorativos e insultos.
                    ° Moral – Está associado ao Bullying verbal, e ocorre através de boatos, difamações
                    e calunias.
                    ° Físicas – Quando envolve a agressão física, empurrão, espancamento, chutes.
                    ° Psicológico – Envolve aspectos que afetam o psicológico como, por exemplo,
                    chantagem, manipulação, exclusão, perseguição e outros.
                    ° Material – Quando é definido por ações que envolvem roubo, furtos e destruição de
                    objetos pertencentes a alguém.
                    ° Sexual – Quando é cometido por meio de abusos e assédios sexuais.
                  </p>
                </div>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading animate-box">
              <span>#Nobully #Informações #App</span>
              <h2>Consequência do Bullying</h2>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-9 col-md-push-2">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn">
                    Principais sinais típicos dos que sofrem Bullying:
                    As vitimas desse ato normalmente tem vergonha e medo de falar para família,
                    apresentam autoestima baixa, possuem tendência ao isolamento, normalmente têm
                    mudanças de comportamento, recusa de ir aos locais antes comuns, falta de apetite,
                    Insônia e dor de cabeça constante, queda no desempenho escolar, tem alterações no
                    quadro fisiológico como a presença de febre, tremor e lesões.
                    O que a Legislação brasileira afirma quanto ao Bullying
                    Em 06 de novembro de 2015 foi sancionada a Lei Nº 13.185, denominada “Programa de
                    combate à intimidação sistemática (Bullying)”. Em coadunação com essa lei,
                    afirma-se:
                    “Considera-se intimidação sistemática (Bullying) todo ato de violência física ou
                    psicológica intencional e repetitiva que ocorre sem motivação evidente, praticado
                    por individuo ou grupo, contra uma ou mais pessoas com o objetivo de intimidá-la ou
                    agredi-la, causando dor e angústia á vitima em uma relação de desequilíbrio de poder
                    entre as partes envolvidas”.
                    No entanto, segundo as estatísticas, cerca de 80% das escolas brasileiras ainda não
                    punem os agressores.
                    Com isso, para abordar o tema, o Dia Mundial de Combate ao Bullying é comemorado em
                    todo o mundo no dia 20 de outubro. No Brasil o dia nacional de combate ao Bullying e
                    a violência na escola é comemorado em 7 de abril.
                    Logo, tais servem para alertar e colocar em pauta esse assunto que está virando algo
                    banal na sociedade vigente e tratar essa problemática como um fato social, de forma
                    a buscar soluções contundentes com a realidade do Bullying sofrida pela civilização.

                  </p>
                </div>
              </div>
            </div>
          </div><!-- row -->

          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-left fh5co-heading animate-box">
              <span>#Nobully #Informações #App</span>
              <h2>Referências</h2>
            </div>
          </div>

          <div class="row fh5co-feature">
            <div class="col-md-9 col-md-push-2">
              <div class="fh5co-copy">
                <div class="fh5co-copy-inner">
                  <p class="animate-box" data-animate-effect="fadeIn">
                    Gomes, F.L. e sanzovo, M.N. Bullying e a Prevenção da Violência nas Escolas-
                    Quebrando mitos, construindo verdades. Coleção Saberes Monográficos. Editora Saraiva
                    Neves, M.M. Bullying Escolar. De acordo com a Lei Nacional de Combate ao Bullying
                    13.185/2015 e outros aspectos jurídicos.
                    Palacio. R.J. Extraordinário. Editora Intrínseca – 2014
                  </p>
                </div>
              </div>
            </div>
          </div><!-- row -->


        </div><!-- container -->
      </div>
      <app-footer></app-footer>
    </main>
  </div>
</body>
