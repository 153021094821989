var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import * as ChartLabel from 'chartjs-plugin-labels';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { DashBoardService } from './dashboard.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { MainNavComponent } from '../main-nav/main-nav.component';
var ɵ0 = MAT_MOMENT_DATE_FORMATS;
var DashboardComponent = /** @class */ (function () {
    // tslint:disable-next-line:max-line-length
    function DashboardComponent(service, serviceDash, router, formBuilder, notifierService, mainNavComponent) {
        this.service = service;
        this.serviceDash = serviceDash;
        this.router = router;
        this.formBuilder = formBuilder;
        this.mainNavComponent = mainNavComponent;
        this.tiposDenuncia = [];
        this.local_bulying = [];
        this.local_bulying_escola = [];
        this.desc_tipo_denuncia = [];
        this.count_tipo_denuncia = [];
        this.desc_local_bullying = [];
        this.count_local_bullying = [];
        this.desc_local_bullying_escola = [];
        this.count_local_bullying_escola = [];
        this.list_tipo_bullying = [];
        this.desc_tipo_bullying = [];
        this.count_tipo_bullying = [];
        this.ListDenunciasEscola = [];
        this.desc_denuncia = [];
        this.count_denuncia = [];
        this.list_tipo_bullying_por_data = [];
        this.visible = true;
        this.messageDt_inicial = '';
        this.messageDt_final = '';
        this.errorDtInicial = false;
        this.errorDtFinal = false;
        this.emergencias = [];
        this.emergencias = JSON.parse(localStorage.getItem('emergencias'));
        this.notifier = notifierService;
        this.dtForm = formBuilder.group({
            dt_inicial: [Validators.required],
            dt_final: [Validators.required]
        });
        this.user = JSON.parse(localStorage.getItem('user'));
        this.id_escola = this.user.id_escola;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        Chart.pluginService.register(ChartLabel);
        this.verificaEscolaAtiva(this.id_escola);
        this.countAlunos();
        this.countDenunciasParaEscola();
        this.tiposDenunciasEscola();
        this.countDenunciasEscolaPorSerie();
        this.countLocalBullying();
        this.countLocalBullyingEscola();
        this.countTiposDeBullingByEscola();
        this.tipoBullyingByDataAndEscola();
        this.countDenunciasEscolaEmergencia();
        this.serviceDash.emergencys.subscribe(function (msg) {
            _this.showAlert('warning', 'Nova Emergencia!');
            _this.emergencias.push(msg.data);
            localStorage.setItem('emergencias', JSON.stringify(_this.emergencias));
        });
    };
    DashboardComponent.prototype.setPage = function (page) {
        this.mainNavComponent.setPage(page);
    };
    DashboardComponent.prototype.generatePDF = function (id) {
        var _this = this;
        this.visible = false;
        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = document.getElementById(id);
                        return [4 /*yield*/, html2canvas(data).then(function (canvas) {
                                // Few necessary setting options
                                var imgWidth = 208;
                                var pageHeight = 295;
                                var imgHeight = canvas.height * imgWidth / canvas.width;
                                var heightLeft = imgHeight;
                                var contentDataURL = canvas.toDataURL('image/png');
                                var pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
                                var position = 0;
                                pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                                pdf.save("Relat\u00F3rio " + id + ".pdf"); // Generated PDF
                                _this.visible = true;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, 500);
    };
    DashboardComponent.prototype.chartBarVertical = function (id, label, labels, data, arrayTotal, type) {
        this.chart_bar = new Chart(id, {
            type: type,
            data: {
                datasets: [{
                        label: label,
                        data: data,
                        backgroundColor: [
                            '#2AF598',
                            '#F76B1C',
                            '#21D4FD',
                            '#0093E9',
                            '#FFE32C',
                            '#85FFBD',
                            '#3e95cd',
                            '#8e5ea2',
                            '#3cba9f',
                            '#e8c3b9',
                            '#c45850',
                        ],
                        borderColor: [
                            '#2AF598',
                            '#F76B1C',
                            '#21D4FD',
                            '#0093E9',
                            '#FFE32C',
                            '#85FFBD',
                            '#3e95cd',
                            '#8e5ea2',
                            '#3cba9f',
                            '#e8c3b9',
                            '#c45850',
                        ],
                        borderWidth: 1
                    }, {
                        label: 'Line Dataset',
                        data: arrayTotal,
                        type: 'line',
                        hidden: true,
                    }],
                labels: labels
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                            stacked: true
                        }],
                    yAxes: [{
                            stacked: true
                        }]
                },
                title: {
                    display: true,
                    text: label
                }
            }
        });
    };
    DashboardComponent.prototype.chartBar = function (id, label, labels, data, type) {
        this.chart_bar = new Chart(id, {
            type: type,
            data: {
                labels: labels,
                datasets: [{
                        label: label,
                        data: data,
                        backgroundColor: [
                            '#2AF598',
                            '#F76B1C',
                            '#21D4FD',
                            '#0093E9',
                            '#FFE32C',
                            '#85FFBD',
                            '#3e95cd',
                            '#8e5ea2',
                            '#3cba9f',
                            '#e8c3b9',
                            '#c45850',
                        ],
                        borderColor: [
                            '#2AF598',
                            '#F76B1C',
                            '#21D4FD',
                            '#0093E9',
                            '#FFE32C',
                            '#85FFBD',
                            '#3e95cd',
                            '#8e5ea2',
                            '#3cba9f',
                            '#e8c3b9',
                            '#c45850',
                        ],
                        borderWidth: 1
                    }]
            },
            options: {
                responsive: true,
                legend: { display: false },
                scales: {
                    xAxes: [{
                            stacked: true
                        }],
                    yAxes: [{
                            stacked: true
                        }]
                },
                title: {
                    display: true,
                    text: label
                }
            }
        });
    };
    DashboardComponent.prototype.chartPie = function (id, label, labels, data, type) {
        // let options = {
        //   chart: {
        //     type: type,
        //   },
        //   labels: labels,
        //   series: data,
        //   responsive: [{
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         // width: 200,
        //         // height: 200,
        //       },
        //       legend: {
        //         position: 'bottom'
        //       }
        //     }
        //   }]
        // }
        // let chart = new ApexCharts(
        //   document.querySelector("#" + id),
        //   options
        // );
        // chart.render();
        this.chart_pie = new Chart(id, {
            type: type,
            data: {
                labels: labels,
                datasets: [
                    {
                        label: label,
                        data: data,
                        backgroundColor: [
                            '#3e95cd',
                            '#8e5ea2',
                            '#3cba9f',
                            '#e8c3b9',
                            '#c45850',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                    }
                ]
            },
            options: {
                title: {
                    display: true,
                    text: label
                },
                legend: {
                    display: false
                },
                plugins: {
                    labels: [
                        {
                            render: 'label',
                            position: 'outside'
                        },
                        {
                            render: 'percentage'
                        }
                    ],
                    fontSize: 12,
                    fontColor: '#fff',
                    fontStyle: 'normal',
                    // tslint:disable-next-line:quotemark
                    fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                }
            }
        });
    };
    DashboardComponent.prototype.chartLine = function (id, label, labels, data) {
        this.chart_line = new Chart(id, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                        label: label,
                        data: data,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
            },
            options: {
                title: {
                    display: true,
                    text: label
                },
                scales: {
                    yAxes: [{
                            stacked: true
                        }]
                }
            }
        });
    };
    DashboardComponent.prototype.chartLineMultiAxis = function (id, label, labels, data) {
        this.cart_line_multi_axis = new Chart(id, {
            type: 'line',
            data: {
                labels: labels,
                datasets: data
            },
            options: {
                title: {
                    display: true,
                    text: label
                }
            }
        });
    };
    DashboardComponent.prototype.verificaEscolaAtiva = function (id_escola) {
        var _this = this;
        this.service.verificaEscolaAtiva(id_escola).subscribe(function (data) {
            if (data.json().ativo === 'N') {
                localStorage.clear();
                _this.router.navigate(['/']);
            }
        });
    };
    DashboardComponent.prototype.countDenunciasEscolaEmergencia = function () {
        var _this = this;
        this.serviceDash.countDenunciasEscolaEmergencia(this.id_escola).subscribe(function (data) {
            _this.qtdEmergencia = data.json().count;
        }, function (err) {
            console.log(err);
        });
    };
    DashboardComponent.prototype.countAlunos = function () {
        var _this = this;
        this.serviceDash.countAlunos(this.id_escola).subscribe(function (res) {
            _this.qtdAlunos = res.json().qtdAlunos;
        });
    };
    DashboardComponent.prototype.countDenunciasParaEscola = function () {
        var _this = this;
        this.serviceDash.countDenunciasParaEscola(this.id_escola).subscribe(function (res) {
            _this.qtdDenuncias = res.json().qtdDenuncias;
        });
    };
    DashboardComponent.prototype.tiposDenunciasEscola = function () {
        var _this = this;
        this.serviceDash.tiposDenunciasEscola(this.id_escola).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.tiposDenuncia = res.json();
                        return [4 /*yield*/, this.tiposDenuncia.forEach(function (value) {
                                _this.desc_tipo_denuncia.push(value.desc_tipo_denuncia);
                                // tslint:disable-next-line:radix
                                _this.count_tipo_denuncia.push(parseInt(value.count));
                            })];
                    case 1:
                        _a.sent();
                        this.chartPie('denuncia', 'Nº de Denúncias', this.desc_tipo_denuncia, this.count_tipo_denuncia, 'doughnut');
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DashboardComponent.prototype.countDenunciasEscolaPorSerie = function () {
        var _this = this;
        this.serviceDash.countDenunciasEscolaPorSerie(this.id_escola).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ListDenunciasEscola = res.json();
                        return [4 /*yield*/, this.ListDenunciasEscola.forEach(function (value) {
                                _this.desc_denuncia.push(value.desc);
                                // tslint:disable-next-line:radix
                                _this.count_denuncia.push(parseInt(value.count));
                            })];
                    case 1:
                        _a.sent();
                        this.chartPie('qtdDenuncia', 'Denúncias', this.desc_denuncia, this.count_denuncia, 'pie');
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DashboardComponent.prototype.countDenunciasEscolaPorIdade = function () {
        var _this = this;
        this.serviceDash.countDenunciasEscolaPorIdade(this.id_escola).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ListDenunciasEscola = res.json();
                        return [4 /*yield*/, this.ListDenunciasEscola.forEach(function (value) {
                                _this.desc_denuncia.push(value.idade + ' Anos');
                                // tslint:disable-next-line:radix
                                _this.count_denuncia.push(parseInt(value.count));
                            })];
                    case 1:
                        _a.sent();
                        this.chartPie('qtdDenuncia', 'Denúncias', this.desc_denuncia, this.count_denuncia, 'pie');
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DashboardComponent.prototype.countLocalBullying = function () {
        var _this = this;
        this.serviceDash.countLocalBullying(this.id_escola).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var arrayTotal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.local_bulying = res.json();
                        arrayTotal = [];
                        return [4 /*yield*/, this.local_bulying.forEach(function (value) {
                                _this.desc_local_bullying.push(value.desc_local_bullying);
                                // tslint:disable-next-line:radix
                                // this.count_local_bullying.push({data: [parseInt(value.count)], label: value.desc_local_bullying});
                                arrayTotal.push(_this.qtdDenuncias);
                                // tslint:disable-next-line:radix
                                _this.count_local_bullying.push(parseInt(value.count));
                            })];
                    case 1:
                        _a.sent();
                        // this.chartBar('local', 'Nº de Locais do Bullying', this.desc_local_bullying, this.count_local_bullying, 'bar');
                        this.chartBarVertical('local', 'Nº de Locais do Bullying', this.desc_local_bullying, this.count_local_bullying, arrayTotal, 'bar');
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DashboardComponent.prototype.countLocalBullyingEscola = function () {
        var _this = this;
        this.serviceDash.countLocalBullyingEscola(this.id_escola).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.local_bulying_escola = res.json();
                        return [4 /*yield*/, this.local_bulying_escola.forEach(function (value) {
                                _this.desc_local_bullying_escola.push(value.desc_local_escola);
                                // tslint:disable-next-line:radix
                                _this.count_local_bullying_escola.push(parseInt(value.count));
                            })];
                    case 1:
                        _a.sent();
                        // tslint:disable-next-line:max-line-length
                        this.chartBar('escola', 'Nº de Locais do Bullying na Escola', this.desc_local_bullying_escola, this.count_local_bullying_escola, 'horizontalBar');
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DashboardComponent.prototype.countTiposDeBullingByEscola = function () {
        var _this = this;
        this.serviceDash.countTiposDeBullingByEscola(this.id_escola).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.list_tipo_bullying = res.json();
                        return [4 /*yield*/, this.list_tipo_bullying.forEach(function (value) {
                                _this.desc_tipo_bullying.push(value.desc_tipo_bullying);
                                // tslint:disable-next-line:radix
                                _this.count_tipo_bullying.push(parseInt(value.count));
                            })];
                    case 1:
                        _a.sent();
                        this.chartLine('tipos_bullying', 'Nº total de Tipos de Bullying Sofridos', this.desc_tipo_bullying, this.count_tipo_bullying);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DashboardComponent.prototype.tipoBullyingByDataAndEscola = function (dt_inicial, dt_final) {
        var _this = this;
        // tslint:disable-next-line:prefer-const
        var arrayDt = [];
        var arrayDatas = [];
        var arrayDesc = [];
        // tslint:disable-next-line:prefer-const
        var dataset = [];
        // tslint:disable-next-line:prefer-const
        var count = [];
        // tslint:disable-next-line:prefer-const
        var colors = ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850', '#466580', '#5cb85c'];
        var indexColors = 0;
        this.serviceDash.tipoBullyingByDataAndEscola({ dt_inicial: dt_inicial, dt_final: dt_final, id_escola: this.id_escola })
            .subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var object;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        object = {
                            label: '',
                            data: [],
                            backgroundColor: '',
                            borderColor: '',
                            // borderWidth: 1,
                            fill: false
                        };
                        this.list_tipo_bullying_por_data = res.json();
                        return [4 /*yield*/, this.list_tipo_bullying_por_data.forEach(function (value) {
                                arrayDatas.push(value.data_denuncia);
                                arrayDesc.push(value.desc_tipo_bullying);
                            })];
                    case 1:
                        _a.sent();
                        arrayDesc = arrayDesc.filter(function (elem, i, arr) {
                            return arr.indexOf(elem) === i;
                        });
                        arrayDatas = arrayDatas.filter(function (elem, i, arr) {
                            return arr.indexOf(elem) === i;
                        });
                        return [4 /*yield*/, arrayDesc.map(function (value) {
                                // tslint:disable-next-line:prefer-const
                                var ar = _this.list_tipo_bullying_por_data.filter(function (elem, i, arr) {
                                    if (value === elem.desc_tipo_bullying) {
                                        return arr.indexOf(elem) === i;
                                    }
                                });
                                ar.forEach(function (elem) {
                                    count.push(elem.count);
                                    object = {
                                        label: elem.desc_tipo_bullying,
                                        data: count,
                                        backgroundColor: colors[indexColors],
                                        borderColor: colors[indexColors],
                                        // borderWidth: 1,
                                        fill: false
                                    };
                                    dataset.push(object);
                                });
                                count = [];
                                indexColors++;
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, dataset.reduce(function (acc, value, index) {
                                // tslint:disable-next-line:no-unused-expression
                                (index === 0) && (acc.push(value));
                                // tslint:disable-next-line:no-unused-expression
                                (acc.every(function (v) { return JSON.stringify(v) !== JSON.stringify(value); })) && (acc.push(value));
                                return acc;
                            }, [])];
                    case 3:
                        dataset = _a.sent();
                        arrayDatas = arrayDatas.map(function (elem) {
                            return new Date(elem).getDate() + "-" + (new Date(elem).getMonth() + 1) + "-" + new Date(elem).getFullYear();
                        });
                        this.chartLineMultiAxis('tipos_bullying_por_data', 'Nº de Tipos de Bullying Sofridos pro périodos de datas', arrayDatas, dataset);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DashboardComponent.prototype.buscarTiposDeBullyingPorData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, dt_inicial, dt_final, dtIncial, dtFinal;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.dtForm.controls, dt_inicial = _a.dt_inicial, dt_final = _a.dt_final;
                        if (!!this.dtForm.valid) return [3 /*break*/, 1];
                        if (!dt_inicial.valid) {
                            this.errorDtInicial = true;
                            this.messageDt_inicial = 'Por Favor, Preencha a data inicial!';
                            this.showAlert('error', this.messageDt_inicial);
                        }
                        else {
                            this.messageDt_inicial = '';
                        }
                        if (!dt_final.valid) {
                            this.errorDtFinal = true;
                            this.messageDt_final = 'Por Favor, Preencha a data final!';
                            this.showAlert('error', this.messageDt_final);
                        }
                        else {
                            this.messageDt_final = '';
                        }
                        return [3 /*break*/, 3];
                    case 1:
                        dtIncial = this.dtForm.value.dt_inicial;
                        dtFinal = this.dtForm.value.dt_final;
                        // tslint:disable-next-line:max-line-length
                        return [4 /*yield*/, this.tipoBullyingByDataAndEscola(dtIncial._i.year + "-" + (dtIncial._i.month + 1) + "-" + dtIncial._i.date, dtFinal._i.year + "-" + (dtFinal._i.month + 1) + "-" + dtFinal._i.date)];
                    case 2:
                        // tslint:disable-next-line:max-line-length
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.buscarDenunciasPor = function (filter) {
        this.desc_denuncia = [];
        this.count_denuncia = [];
        if (filter === 'series') {
            this.countDenunciasEscolaPorSerie();
        }
        if (filter === 'idade') {
            this.countDenunciasEscolaPorIdade();
        }
        if (filter === 'horario') {
        }
    };
    DashboardComponent.prototype.showAlert = function (type, message) {
        var _this = this;
        this.notifier.show({
            type: type,
            message: message
        });
        setTimeout(function () {
            _this.notifier.hideOldest();
        }, 3000);
    };
    return DashboardComponent;
}());
export { DashboardComponent };
export { ɵ0 };
