var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '../utils/MatchPassword';
import { ServiceService } from '../service.service';
import { NotifierService } from 'angular-notifier';
import { LoadingComponent } from '../utils/loading.component';
import { Router } from '@angular/router';
var EscolaComponent = /** @class */ (function () {
    function EscolaComponent(dialogRef, 
    // @Inject(MAT_DIALOG_DATA)
    // public data: DialogData,
    formBuilder, service, notifierService, dialog, router) {
        this.dialogRef = dialogRef;
        this.formBuilder = formBuilder;
        this.service = service;
        this.dialog = dialog;
        this.router = router;
        this.submitted = false;
        this.editar = false;
        this.formDisabled = true;
        this.escola = {
            dth_cadastro: null,
            id_endereco: null,
            id_usuario: null,
            id_escola: null,
            nome_escola: '',
            telefone: ''
        };
        this.usuario = {
            email: '',
            id_tipo_usuario: null,
            id_usuario: null,
            login: null,
            senha: ''
        };
        this.endereco = {
            bairro: '',
            cep: '',
            complemento: '',
            estado: '',
            id_endereco: null,
            logradouro: '',
            municipio: '',
            numero: ''
        };
        this.isLinear = true;
        this.estados = [
            'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
            'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
            'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
            'SC', 'SP', 'SE', 'TO'
        ];
        this.notifier = notifierService;
    }
    EscolaComponent.prototype.ngOnInit = function () {
        this.getEscolaById();
        this.verificaEscolaAtiva(JSON.parse(localStorage.getItem('user')).id_escola);
        this.firstFormGroup = this.formBuilder.group({
            nome_escola: [{ value: '', disabled: this.formDisabled }, Validators.required],
            telefone: [{ value: '', disabled: this.formDisabled }, Validators.required],
            email: [{ value: '', disabled: this.formDisabled }, [Validators.required, Validators.email]],
            senha: [{ value: '', disabled: this.formDisabled }, [Validators.required, Validators.minLength(6)]],
            confirmPassword: [{ value: '', disabled: this.formDisabled }, [Validators.required, Validators.minLength(6)]]
        }, {
            validator: PasswordValidation.MatchPassword
            // validator: [PasswordValidation.MatchPassword, EmailValidation.EmailExisting]// your validation method
        });
        this.secondFormGroup = this.formBuilder.group({
            estado: [{ value: '', disabled: this.formDisabled }, Validators.required],
            municipio: [{ value: '', disabled: this.formDisabled }, Validators.required],
            bairro: [{ value: '', disabled: this.formDisabled }, Validators.required],
            logradouro: [{ value: '', disabled: this.formDisabled }, Validators.required],
            numero: [{ value: '', disabled: this.formDisabled }, Validators.required],
            cep: [{ value: '', disabled: this.formDisabled }],
            complemento: [{ value: '', disabled: this.formDisabled }]
        });
    };
    EscolaComponent.prototype.getEscolaById = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.service.getEscolaById(JSON.parse(localStorage.getItem('user')).id_escola)
                            .subscribe(function (res) {
                            console.log(res.json());
                            _this.escola = res.json().escola;
                            _this.usuario = res.json().usuario;
                            _this.endereco = res.json().endereco;
                            _this.firstFormGroup.setValue({
                                nome_escola: _this.escola.nome_escola,
                                telefone: _this.escola.telefone,
                                email: _this.usuario.email,
                                senha: _this.usuario.senha,
                                confirmPassword: _this.usuario.senha
                            });
                            _this.secondFormGroup.setValue({
                                estado: _this.endereco.estado,
                                municipio: _this.endereco.municipio,
                                bairro: _this.endereco.bairro,
                                logradouro: _this.endereco.logradouro,
                                numero: _this.endereco.numero,
                                cep: _this.endereco.cep,
                                complemento: _this.endereco.complemento
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EscolaComponent.prototype.activateEdit = function () {
        var _this = this;
        this.editar ? this.editar = false : this.editar = true;
        this.formDisabled = !this.formDisabled;
        var state = this.formDisabled ? 'disable' : 'enable';
        Object.keys(this.firstFormGroup.controls).forEach(function (controlName) {
            _this.firstFormGroup.controls[controlName][state]();
        });
        Object.keys(this.secondFormGroup.controls).forEach(function (controlName) {
            _this.secondFormGroup.controls[controlName][state]();
        });
    };
    EscolaComponent.prototype.verifyEdit = function () {
        var _this = this;
        var loading = this.dialog.open(LoadingComponent, {
            width: '150px',
        });
        this.usuario.email = this.firstFormGroup.controls.email.value;
        this.usuario.senha = this.firstFormGroup.controls.senha.value;
        this.endereco = this.secondFormGroup.value;
        this.escola.nome_escola = this.firstFormGroup.controls.nome_escola.value;
        this.escola.telefone = this.firstFormGroup.controls.telefone.value;
        var objEscola = {
            usuario: this.usuario,
            endereco: this.endereco,
            escola: this.escola,
        };
        var obj = {
            ids: {
                id_escola: this.escola.id_escola,
                id_endereco: this.escola.id_endereco,
                id_usuario: this.escola.id_usuario
            },
            escola: objEscola
        };
        var user = JSON.parse(localStorage.getItem('user'));
        var objLocalStorage = {
            email: objEscola.usuario.email,
            id_escola: user.id_escola,
            id_tipo_usuario: user.id_tipo_usuario,
            id_usuario: user.id_usuario
        };
        if (user.email !== obj.escola.usuario.email) {
            this.service.validarEmailUsuario(obj.escola.usuario.email)
                .subscribe(function (res) {
                if (res.json().length > 0) {
                    _this.showAlert('warning', 'Este e-mail já está sendo utilizado por outro usuário!');
                    loading.close();
                }
                else {
                    _this.editEscola(obj, loading);
                    _this.setLocalStorage(objLocalStorage);
                }
            });
        }
        else {
            this.editEscola(obj, loading);
            this.setLocalStorage(objLocalStorage);
        }
    };
    EscolaComponent.prototype.editEscola = function (obj, loading) {
        var _this = this;
        this.service.editEscola(obj).subscribe(function (r) {
            console.log(r);
            _this.showAlert('success', 'Sucesso!!');
            _this.activateEdit();
            loading.close();
        }, function (err) {
            console.log(err);
            _this.showAlert('error', err);
            loading.close();
        });
    };
    EscolaComponent.prototype.setLocalStorage = function (obj) {
        localStorage.setItem('user', JSON.stringify(obj));
    };
    EscolaComponent.prototype.showAlert = function (type, message) {
        var _this = this;
        this.notifier.show({
            type: type,
            message: message
        });
        setTimeout(function () {
            _this.notifier.hideOldest();
        }, 3000);
    };
    EscolaComponent.prototype.verificaEscolaAtiva = function (id_escola) {
        var _this = this;
        this.service.verificaEscolaAtiva(id_escola).subscribe(function (data) {
            if (data.json().ativo == 'N') {
                localStorage.clear();
                _this.router.navigate(['/']);
            }
        });
    };
    return EscolaComponent;
}());
export { EscolaComponent };
