
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)">
    <mat-toolbar><img matCardImage src="assets/img/marca_horizontal_.png"></mat-toolbar>
    <mat-divider></mat-divider>
    <mat-nav-list id="list">
      <!-- <a mat-list-item href="#">DashBoard</a>
      <a mat-list-item href="#">Alunos</a> -->
      <a mat-list-item class="btn" (click)="setPage('Escolas')"> <mat-icon>school</mat-icon> Escolas</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{ page }}</span>
        <span class="spacer"></span>
        <mat-icon class="icon-rigth"></mat-icon>
        <button mat-icon-button (click)="logoff()">
          <mat-icon>power_settings_new</mat-icon>
        </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div class="main">
        <app-escolas *ngIf="page == 'Escolas'"></app-escolas>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
