import { Component, OnInit, HostListener } from '@angular/core';

import ScrollReveal from 'scrollreveal';
import { ServiceService } from '../../../service.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ModalPartnerComponent } from './modal-partner/modal-partner.component';

@Component({
  selector: 'app-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.css']
})
export class PartnerPageComponent implements OnInit {
  urlBackground: string;
  listEscolas: any = [];

  /**
   * Config para Ow-carousel
   */
  public urlJson = 'assets/schools.json';
  public mySlideOptions = {
    items: 4,
    loop: false,
    center: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: true,
    nav: false,
    responsive: {
      0: {
          items: 1,
          nav: true
      },
      600: {
          items: 5,
          nav: false
      },
      1000: {
          items: 5,
          dots: true,
          nav: false,
          loop: false
      }
    }
  };

  constructor(
    private service: ServiceService,
    public dialog: MatDialog) {
    this.urlBackground = 'assets/img/adoacao.jpg';
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.makeEffect();
    this.linkChangeTabs();
    this.listEscolasParaAdocao();
  }

  listEscolasParaAdocao() {
    this.service.listEscolasParaAdocao('N', 'Pública').subscribe(data => {
      this.listEscolas = data.json().data
      .map(element => {
        element.image = 'assets/img/school-default.svg';
        return element;
      });
    }, err => {
      console.log(err);
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 100) {
       const element = document.getElementById('navbar');
       element.classList.add('fixed-top');
     } else {
      const element = document.getElementById('navbar');
      element.classList.remove('fixed-top');
     }
  }

  makeEffect(): any {
  const body = document.querySelector('.has-animations');
    if (body.classList.contains('has-animations')) {

    const sr = ScrollReveal();

    sr.reveal('.hero-title, .donation-paragraph, .hero-cta', {
      duration: 600,
      distance: '20px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'top',
      interval: 150
    });

    sr.reveal('.donation-illustration', {
      duration: 600,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'bottom'
    });

    sr.reveal('.feature, .tabs-links li, .testimonial, .pricing-faqs', {
      duration: 600,
      distance: '40px',
      easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      interval: 100,
      origin: 'bottom',
      viewFactor: 0.2
    });

    sr.reveal('.feature-extended:nth-child(odd) .feature-extended-image', {
      duration: 600,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'left',
      viewFactor: 0.5
    });
    }
  }

  linkChangeTabs(): any {
    const tabLinksAll = document.getElementsByClassName('tab-link');

    if (tabLinksAll.length) {
      for (let i = 0; i < tabLinksAll.length; i++) {
        tabLinksAll[i].addEventListener('click', function (e) {
          e.preventDefault();
          const tabLinksContainer = (<HTMLElement>( <HTMLElement>tabLinksAll[i] ).parentNode.parentNode);

          const tabPanels = tabLinksContainer.nextElementSibling.getElementsByClassName('tab-panel');
          const tabLinks = tabLinksContainer.getElementsByClassName('tab-link');
          // Remove is-active class from all links and panels
          for (let i = 0; i < tabLinks.length; i++) {
            tabLinks[i].classList.remove('is-active');
          }
          for (let i = 0; i < tabPanels.length; i++) {
            tabPanels[i].classList.remove('is-active');
          }
          // Get the ID of panel to display
          const tabID = this.getAttribute('href');
          // Add is-active class to matching link and panel
          tabLinksAll[i].classList.add('is-active');
          document.querySelector(tabID).classList.add('is-active');
        });
      }
    }
  }

  openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';

    const loading = this.dialog.open(ModalPartnerComponent, dialogConfig);
  }

}
