<notifier-container></notifier-container>
<body class="is-boxed has-animations">
  <div class="body-wrap boxed-container">
    <app-menu></app-menu>
    <main>
      <section class="donation text-center" [style.backgroundImage]="'url(' + urlBackground + ')'"
        style="background-size: cover; background-position: 50% 50%">
        <div class="container-sm">
          <div class="donation-inner">
            <div class="donation-copy">
              <h2 class="hero-title mt-0 is-revealing">Seja um parceiro! Ajude a combater o bullying nas escolas
                públicas.</h2>
              <p class="donation-paragraph is-revealing">#JuntosNoCombateAoBullying</p>
              <p class="hero-cta is-revealing"><a class="button button-primary button-shadow" (click)="openModal()">Seja um
                  parceiro</a>
              </p>
            </div>
            <div class="donation-illustration is-revealing">
            </div>
          </div>
        </div>
      </section>

      <section class="features-extend section">
        <div class="container">
          <div class="features-extend-inner section-inner">
            <div class="feature-extended">
              <div class="feature-extended-image is-revealing">
                <img src="assets/img/connect.jpg" />
              </div>
              <div class="feature-extended-body">
                <small class="hero-paragraph">#BeneficiosEVantagens</small>
                <h3>Seu negócio ajudando no combate ao Bullying</h3>
                <p class="text-sm">
                  <ul class="list-description">
                    <li class="topics">
                      <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                          style="enable-background:new 0 0 512 512;" xml:space="preserve">
                          <path style="fill:#C3E678;"
                            d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                          <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                                  C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                                  C432.012,421.638,404.342,426.667,375.467,426.667z" />
                          <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                                  c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                                  c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                                  C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg>
                        Melhore a qualidade de vida das crianças e adolescentes das escolas da rede pública</p>
                    </li>
                    <li class="topics">
                      <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                          style="enable-background:new 0 0 512 512;" xml:space="preserve">
                          <path style="fill:#C3E678;"
                            d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                          <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                                  C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                                  C432.012,421.638,404.342,426.667,375.467,426.667z" />
                          <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                                  c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                                  c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                                  C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Um ano de visibilidade
                        em nosso site</p>
                    </li>
                    <li class="topics">
                      <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                          style="enable-background:new 0 0 512 512;" xml:space="preserve">
                          <path style="fill:#C3E678;"
                            d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                          <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                                        C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                                        C432.012,421.638,404.342,426.667,375.467,426.667z" />
                          <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                                        c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                                        c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                                        C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg>
                        Uma publicação nos stories do FaceBook e Instagram por semana durante um ano
                      </p>
                    </li>
                    <li class="topics">
                      <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                          style="enable-background:new 0 0 512 512;" xml:space="preserve">
                          <path style="fill:#C3E678;"
                            d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                          <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                                        C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                                        C432.012,421.638,404.342,426.667,375.467,426.667z" />
                          <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                                        c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                                        c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                                        C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg>
                        Sua marca apresentada como nosso parceiro em eventos que participarmos
                      </p>
                    </li>
                    <li class="topics">
                      <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                          style="enable-background:new 0 0 512 512;" xml:space="preserve">
                          <path style="fill:#C3E678;"
                            d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                          <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                                        C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                                        C432.012,421.638,404.342,426.667,375.467,426.667z" />
                          <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                                        c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                                        c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                                        C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg>
                        Marketing positivo levantando a bandeira do combate ao Bullying
                      </p>
                    </li>
                  </ul>
                  <p class="hero-cta is-revealing"><a class="button button-primary button-shadow" (click)="openModal()">Seja um
                      parceiro</a>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="features section text-center">
        <div class="container">
          <div class="features-inner section-inner has-top-divider">
            <div class="features-header text-center">
              <div class="container-sm">
                <h2 class="section-title mt-0">Bullying é assunto sério!</h2>
                <p class="section-paragraph mb-0" style="text-align: justify">O Bullying gera consequências negativas
                  para todos,
                  independentemente da classe social ou do tipo de escola. As agressões físicas, que geralmente são
                  visíveis e de mais fácil percepção, provocam lesões e outros danos prejudiciais ou
                  até fatais. Porém, existem as sequelas mais silenciosas e de difícil percepção, que
                  são as psicológicas, sociais e emocionais.</p>
              </div>
            </div>
            <div class="features-wrap">
              <div class="feature is-revealing">
                <div class="feature-inner">
                  <div class="feature-icon">
                    <img src="assets/img/warning.png" alt="Feature 01">
                  </div>
                  <h5 class="feature-title">Sinais</h5>
                  <br />
                  <p class="text-sm">Dificuldades em dormir, distúrbios na alimentação, podendo até levar ao consumo de
                    álcool e drogas</p>
                </div>
              </div>
              <div class="feature is-revealing">
                <div class="feature-inner">
                  <div class="feature-icon">
                    <img src="assets/img/cry.png" alt="Feature 04">
                  </div>
                  <h5 class="feature-title">Doenças Psicossomática</h5>
                  <p class="text-sm">Febre, tremores, ansiedade, ataques de pânico, tendência à depressão e baixa
                    autoestima</p>
                </div>
              </div>
              <div class="feature is-revealing">
                <div class="feature-inner">
                  <div class="feature-icon">
                    <img src="assets/img/raiva.png" alt="Feature 02">
                  </div>
                  <h5 class="feature-title">Violência</h5>
                  <br />
                  <p class="text-sm">Comportamentos violentos consigo (automutilação), com os outros e com o ambiente
                  </p>
                </div>
              </div>

              <div class="feature is-revealing">
                <div class="feature-inner">
                  <div class="feature-icon">
                    <img src="assets/img/dead.png" alt="Feature 03">
                  </div>
                  <h5 class="feature-title">Agravantes</h5>
                  <br />
                  <p class="text-sm">Pensamentos homicidas e suicidas, ocasionando, infelizmente, por vezes, fins
                    trágicos!</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section class="features-extend section">
        <div class="container">
          <div class="features-extend-inner section-inner">
            <div class="feature-extended">
              <div class="feature-extended-image is-revealing">
                <img src="assets/img/heroi-diferente.jpg" />
              </div>
              <div class="feature-extended-body">
                <h3 class="mt-0">Seja um herói!</h3>
                <span class="section-paragraph">#AdoteESalveVidas</span>
                <p [style.margin-top.px]="20" style="text-align: justify">A No Bully fomenta a conscientização,
                  prevenção e
                  combate ao
                  Bullying, atuando como uma ponte entre as vítimas e as escolas. Com nosso aplicativo é possível
                  relatar de forma prática e segura o
                  Bullying, recorrer ao alerta de emergência, bem como ter acesso a jogos e materiais
                  educativos e personalização de avatar.</p>
                <p class="hero-cta is-revealing"><a class="button button-primary button-shadow" (click)="openModal()">Seja um
                    parceiro</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="features-tabs section">
        <div class="container">
          <div class="features-tabs-inner section-inner has-top-divider">
            <div class="features-header text-center" style="margin-bottom: 120px">
              <div class="container-sm">
                <h2 class="section-title mt-0">Por que adotar uma escola?</h2>
                <p class="section-paragraph mb-0">#NoBullyEuAPoio</p>
              </div>
            </div>
            <div class="tabs-container">
              <ul class="tabs-links">
                <li class="is-revealing">
                  <a href="#tab-1" class="tab-link is-active">
                    <img src="assets/img/bandeira.jpg" alt="Tabs icon 01" style="width: 55px">
                    <span class="h6 m-0">Combate ao Bullying</span>
                  </a>
                </li>
                <li class="is-revealing">
                  <a href="#tab-2" class="tab-link">
                    <img src="assets/img/cell.jpg" alt="Tabs icon 02" style="width: 55px">
                    <span class="h6 m-0">App para smartphone</span>
                  </a>
                </li>
                <li class="is-revealing">
                  <a href="#tab-3" class="tab-link">
                    <img src="assets/img/grafic.jpg" alt="Tabs icon 03" style="width: 55px">
                    <span class="h6 m-0">Mapeamento estatístico e relatórios gerenciais</span>
                  </a>
                </li>
              </ul>
              <div class="tabs-content">
                <div id="tab-1" class="tab-panel is-active">
                  <h2>Combate ao Bullying</h2>
                  <p>Nosso projeto é conscientizar, prevenir e combater o Bullying nas escolas.</p>
                </div>
                <div id="tab-2" class="tab-panel">
                  <h2>O aplicativo</h2>
                  <p>Canal de comunicação sem barreiras para as vítimas, que podem relatar de forma
                    prática, segura e com total sigilo as ocorrências de Bullying.</p>
                </div>
                <div id="tab-3" class="tab-panel">
                  <h2>Mapeamento estatístico</h2>
                  <p>Apresenta um cenário de Bullying mais fidedigno, auxiliando as escolas a agirem de maneira
                    preventiva, cuidadosa e amorosa com as vítimas.</p>
                </div>
                <p class="hero-cta is-revealing"><a class="button button-primary button-shadow" (click)="openModal()">Seja um
                    parceiro</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="features-extend" [style.padding-bottom.em]="'5'">
        <div class="container" [style.padding-top.em]="'5'">
          <div class="text-center">
            <div class="container-sm">
              <h2 class="section-title mt-0">Escolas Para Serem Adotadas</h2>
              <p class="section-paragraph mb-0">#Escolas</p>
            </div>
          </div>
        </div>
        <div class="container" *ngIf="listEscolas.length != 0">
          <owl-carousel [options]="mySlideOptions" [carouselClasses]="['owl-theme', 'sliding']">
            <div class="item" *ngFor="let school of listEscolas;let i = index">
              <div class="owl-content">
                <div class="owl-image">
                  <img [src]="school.image" alt="Clientes" />
                </div>
                <div class="owl-title">
                  <p class="owl-header">{{ school.nome_escola }}</p>
                </div>
              </div>
            </div>
          </owl-carousel>
        </div>
      </section>
      <app-footer></app-footer>
    </main>
  </div>
</body>
