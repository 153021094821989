<notifier-container></notifier-container>
<div class="fab">
  <button mat-fab (click)="openCadastro()">
    <mat-icon aria-label="Example icon-button with a heart icon">person_add</mat-icon>
  </button>
</div>
<div class="mat-elevation-z8" [style.margin-bottom.em]="'8'">

  <div class="box">
    <div class="container-1">
      <span class="icon"><i class="fa fa-search"></i></span>
      <input type="search" id="search" [(ngModel)]="nome_aluno" placeholder="Nome do aluno..." (keypress)="pesquisarAluno($event)" />
    </div>
  </div>

  <table mat-table [dataSource]="listAlunos" style="margin-top: 5%;">
    <!-- Position Column -->
    <ng-container matColumnDef="nome_aluno">
      <th mat-header-cell *matHeaderCellDef> Nome Aluno </th>
      <td mat-cell *matCellDef="let element"> {{element.nome_aluno}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="sexo_aluno">
      <th mat-header-cell *matHeaderCellDef> Sexo </th>
      <td mat-cell *matCellDef="let element"> {{element.sexo_aluno}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="serie_aluno">
      <th mat-header-cell *matHeaderCellDef> Serié </th>
      <td mat-cell *matCellDef="let element"> {{element.serie_aluno}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="turno_aluno">
      <th mat-header-cell *matHeaderCellDef> Turno </th>
      <td mat-cell *matCellDef="let element"> {{element.turno_aluno}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="tel_aluno">
      <th mat-header-cell *matHeaderCellDef> Telefone </th>
      <td mat-cell *matCellDef="let element"> {{element.tel_aluno}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="data_nascimento">
      <th mat-header-cell *matHeaderCellDef> Data Nascimento </th>
      <td mat-cell *matCellDef="let element"> {{element.data_nascimento}} </td>
    </ng-container>

    <ng-container matColumnDef="acao">
      <th mat-header-cell *matHeaderCellDef> Ação </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="Editar Dados de Aluno" (click)="openEditar(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <!-- <mat-paginator [pageSizeOptions]="[15]" showFirstLastButtons></mat-paginator> -->
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [length]="total" showFirstLastButtons (page)="pageNext($event)"></mat-paginator>
</div>
