import { Component, OnInit, OnDestroy } from '@angular/core';
import { CadastroEscolaComponent } from '../escola/cadastro-escola/cadastro-escola.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { LoadingComponent } from '../utils/loading.component';
import { ServiceService, AuthGuard } from '../service.service';
import { Router } from '@angular/router';
import { EmailDialogComponent } from './EmailDialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginFormGroup: FormGroup;
  notifier: NotifierService;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private service: ServiceService,
    private router: Router) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    document.body.classList.add('body_login');

    this.loginFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      senha: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    document.body.classList.remove('body_login');
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';

    const dialogRef = this.dialog.open(CadastroEscolaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //  this.animal = result;
    });
  }

  onSubmit() {
    console.log(this.loginFormGroup.value);
  }

  login() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.service.login(this.loginFormGroup.value).subscribe(res => {
      localStorage.setItem('token', res.json().token);
      localStorage.setItem('user', JSON.stringify(res.json().usuario));
      this.router.navigate(['/home']);
      loading.close();
    }, err => {
      this.showAlert('warning', err.json().error);
      loading.close();
    });
  }

  openDialogEmail() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    const loading = this.dialog.open(EmailDialogComponent, dialogConfig);
  }

  teste() {
    const loading = this.dialog.open(LoadingComponent, {
      width: '150px',
    });
    setTimeout(() => {
      loading.close();
    }, 3000);
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }

}
