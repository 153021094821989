var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import ScrollReveal from 'scrollreveal';
import ModalVideo from 'modal-video';
import { PageSeguroService } from '../../../provider/pagseguro.service';
import { ServiceService } from '../../../service.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ModalPagseguroComponent } from './modal-pagseguro/modal-pagseguro.component';
import { environment } from 'src/environments/environment';
import { LoadingComponent } from '../../../utils/loading.component';
import { HttpClient } from '@angular/common/http';
var SalePageComponent = /** @class */ (function () {
    function SalePageComponent(pagSeguro, service, dialog, _httpClient) {
        this.pagSeguro = pagSeguro;
        this.service = service;
        this.dialog = dialog;
        this._httpClient = _httpClient;
        this.planos = [];
        this.listEscolas = [];
        /**
         * Config para Ow-carousel
         */
        this.urlJson = 'assets/schools.json';
        this.mySlideOptions = {
            items: 4,
            loop: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            dots: true,
            nav: false,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                600: {
                    items: 5,
                    nav: false
                },
                1000: {
                    items: 5,
                    dots: true,
                    nav: false,
                    loop: true
                }
            }
        };
        // this.listarPlanos();
        this.listEscolasClientes();
    }
    SalePageComponent.prototype.ngOnInit = function () {
        window.scrollTo(0, 0);
        this.animations();
        var modalVideo = new ModalVideo('.hero-cta', { channel: 'youtube' });
    };
    SalePageComponent.prototype.listEscolasClientes = function () {
        var _this = this;
        this.service.listEscolasByAtivo('S').subscribe(function (data) {
            _this.listEscolas = data.json().data
                .map(function (element) {
                element.image = 'assets/img/school-default.svg';
                return element;
            });
        }, function (err) {
            console.log(err);
        });
    };
    SalePageComponent.prototype.iniciarSessionPagSeguro = function (item) {
        var _this = this;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '150px';
        var loading = this.dialog.open(LoadingComponent, dialogConfig);
        this.pagSeguro.iniciar('inovappweb@gmail.com', environment.pagSeguroToken, environment.isSandBox).then(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.pagSeguro.buscarMeiosDepagamento().then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.MeiosPagamento = result;
                                this.openPagSeguro(item);
                                loading.close();
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SalePageComponent.prototype.listarPlanos = function () {
        var _this = this;
        this.service.listarPlanos().subscribe(function (data) {
            _this.planos = data.json();
        }, function (err) {
            console.log(err);
        });
    };
    SalePageComponent.prototype.openPagSeguro = function (item) {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '80%';
        dialogConfig.data = {
            MeiosPagamento: this.MeiosPagamento.paymentMethods,
            planoSelecionado: item
        };
        var dialogRef = this.dialog.open(ModalPagseguroComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            //  this.animal = result;
        });
    };
    SalePageComponent.prototype.onWindowScroll = function (e) {
        if (window.pageYOffset > 100) {
            var element = document.getElementById('navbar');
            element.classList.add('fixed-top');
        }
        else {
            var element = document.getElementById('navbar');
            element.classList.remove('fixed-top');
        }
    };
    SalePageComponent.prototype.animations = function () {
        ScrollReveal().reveal('.hero-title, .hero-paragraph, .hero-cta', {
            duration: 1000,
            distance: '40px',
            easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
            origin: 'left',
            interval: 150
        });
        ScrollReveal().reveal('.hero-illustration', {
            duration: 1000,
            distance: '40px',
            easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
            origin: 'right',
            interval: 150
        });
        ScrollReveal().reveal('.feature', {
            duration: 1000,
            distance: '40px',
            easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
            interval: 100,
            origin: 'bottom',
            scale: 0.9,
            viewFactor: 0.5
        });
        ScrollReveal().reveal('.topics', {
            duration: 1000,
            distance: '40px',
            easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
            interval: 100,
            origin: 'bottom',
            scale: 0.9,
            viewFactor: 0.5
        });
        ScrollReveal().reveal('.feature-extended-image', {
            duration: 600,
            scale: 0.9,
            easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
            viewFactor: 0.5
        });
        var pricingTables = document.querySelectorAll('.pricing-table');
        pricingTables.forEach(function (pricingTable) {
            var pricingTableHeader = [].slice.call(pricingTable.querySelectorAll('.pricing-table-header'));
            var pricingTableList = [].slice.call(pricingTable.querySelectorAll('.pricing-table-features li'));
            var pricingTableCta = [].slice.call(pricingTable.querySelectorAll('.pricing-table-cta'));
            var elements = pricingTableHeader.concat(pricingTableList).concat(pricingTableCta);
            ScrollReveal().reveal(elements, {
                duration: 600,
                distance: '20px',
                easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
                interval: 100,
                origin: 'bottom',
                viewFactor: 0.5
            });
        });
    };
    return SalePageComponent;
}());
export { SalePageComponent };
