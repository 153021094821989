<body class="is-boxed has-animations">
    <div class="body-wrap boxed-container" (scroll)="onWindowScroll($event);">
        <app-menu></app-menu>
        <main>
            <section class="donation text-center" [style.backgroundImage]="'url(' + urlBackground + ')'"
                style="background-size: cover; background-position: 50% 50%">
                <div class="container-sm">
                    <div class="donation-inner">
                        <div class="donation-copy">
                            <h2 class="hero-title mt-0 is-revealing">Torne-se um apoiador à causa de combate ao Bullying
                                nas escolas públicas.</h2>
                            <p class="donation-paragraph is-revealing">#JuntosNoCombateAoBullying</p>
                            <p class="hero-cta is-revealing"><a class="button button-primary button-shadow" [routerLink]="['/checkout', 'donation']">Doe agora</a>
                              <!-- href="https://pag.ae/7U_9zSSEq" -->
                            </p>
                        </div>
                        <div class="donation-illustration is-revealing">
                            <!-- <img src="assets/img/bg.jpg"> -->
                        </div>
                    </div>
                </div>
            </section>

            <section class="features-extend section">
                <div class="container">
                    <div class="features-extend-inner section-inner">
                        <div class="feature-extended">
                            <div class="feature-extended-image is-revealing">
                                <img src="assets/img/connect.jpg" />
                            </div>
                            <div class="feature-extended-body">
                                <!-- <small class="hero-paragraph">#Seja um herói!</small> -->
                                <h3> Junte-se a nós e ajude a combater o Bullying.</h3>
                                <p class="text-sm">
                                    <ul class="list-description">
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                                C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                                C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                                c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                                c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                                C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Inclusão social</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Maior qualidade de vida</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Ambiente escolar mais seguro,
                                                saudável e socializável</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg>Mais saúde</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> educação</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Melhor rendimento escolar</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Empatia</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Cultura de paz</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Respeito ao próximo</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Menos depressão</p>
                                        </li>
                                        <li class="topics">
                                            <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <path style="fill:#C3E678;"
                                                        d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                                                    <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                            C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                            C432.012,421.638,404.342,426.667,375.467,426.667z" />
                                                    <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                            c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                            c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                            C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Menos agressões contra si, ao
                                                próximo e ambiente</p>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="features section text-center">
                <div class="container">
                    <div class="features-inner section-inner has-top-divider">
                        <div class="features-header text-center">
                            <div class="container-sm">
                                <h2 class="section-title mt-0">Bullying é assunto sério!</h2>
                                <p class="section-paragraph mb-0" style="text-align: justify">O Bullying gera
                                    consequências negativas para todos, independentemente da classe social ou do tipo de
                                    escola. As agressões físicas, que geralmente são
                                    visíveis e de mais fácil percepção, provocam lesões e outros danos prejudiciais ou
                                    até fatais. Porém, existem as sequelas mais silenciosas e de difícil percepção, que
                                    são as psicológicas, sociais e emocionais.</p>
                            </div>
                        </div>
                        <div class="features-wrap">
                            <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
                                        <img src="assets/img/warning.png" alt="Feature 01">
                                    </div>
                                    <h5 class="feature-title">Sinais</h5>
                                    <br />
                                    <p class="text-sm">Dificuldades em dormir, distúrbios na alimentação, podendo até
                                        levar ao consumo de álcool e drogas.</p>
                                </div>
                            </div>
                            <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
                                        <img src="assets/img/cry.png" alt="Feature 04">
                                    </div>
                                    <h5 class="feature-title">Doenças Psicossomática</h5>
                                    <p class="text-sm">Febre, tremores, ansiedade, ataques de pânico, tendência à
                                        depressão e baixa autoestima.</p>
                                </div>
                            </div>
                            <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
                                        <img src="assets/img/raiva.png" alt="Feature 02">
                                    </div>
                                    <h5 class="feature-title">Violência</h5>
                                    <br />
                                    <p class="text-sm">Comportamentos violentos consigo (automutilação), com os outros e
                                        com o ambiente.</p>
                                </div>
                            </div>

                            <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
                                        <img src="assets/img/dead.png" alt="Feature 03">
                                    </div>
                                    <h5 class="feature-title">Agravantes</h5>
                                    <br />
                                    <p class="text-sm">Pensamentos homicidas e suicidas, ocasionando, infelizmente, por
                                        vezes, fins trágicos!</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="features-extend section">
                <div class="container">
                    <div class="features-extend-inner section-inner">
                        <div class="feature-extended">
                            <div class="feature-extended-image is-revealing">
                                <img src="assets/img/heroina.jpg" />
                            </div>
                            <div class="feature-extended-body">
                                <h3 class="mt-0">Seja um herói desta causa</h3>
                                <p>A No Bully fomenta a conscientização, prevenção e
                                  combate ao
                                  Bullying, atuando como uma ponte entre as vítimas e as escolas. Com nosso aplicativo é possível relatar de forma prática e segura o
                                  Bullying, recorrer ao alerta de emergência, bem como ter acesso a jogos e materiais
                                  educativos e personalização de avatar.</p>
                                <p class="hero-cta is-revealing">
                                  <a class="button button-primary button-shadow" [routerLink]="['/checkout', 'donation']">Doe agora</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="features-tabs section">
                <div class="container">
                    <div class="features-tabs-inner section-inner has-top-divider">
                        <div class="features-header text-center" style="margin-bottom: 120px">
                            <div class="container-sm">
                                <h2 class="section-title mt-0">Por que doar para nosso projeto?</h2>
                                <p class="section-paragraph mb-0">#NobullyEuAPoio</p>
                            </div>
                        </div>
                        <div class="tabs-container">
                            <ul class="tabs-links">
                                <li class="is-revealing">
                                    <a href="#tab-1" class="tab-link is-active">
                                        <img src="assets/img/bandeira.jpg" alt="Tabs icon 01" style="width: 55px">
                                        <span class="h6 m-0">Combate ao Bullying</span>
                                    </a>
                                </li>
                                <li class="is-revealing">
                                    <a href="#tab-2" class="tab-link">
                                        <img src="assets/img/cell.jpg" alt="Tabs icon 02" style="width: 55px">
                                        <span class="h6 m-0">App para smartphone</span>
                                    </a>
                                </li>
                                <li class="is-revealing">
                                    <a href="#tab-3" class="tab-link">
                                        <img src="assets/img/grafic.jpg" alt="Tabs icon 03" style="width: 55px">
                                        <span class="h6 m-0">Mapeamento estatístico e relatórios gerenciais</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tabs-content">
                                <div id="tab-1" class="tab-panel is-active">
                                    <h2>Combate ao Bullying</h2>
                                    <p>Nosso projeto é conscientizar, prevenir e combater o Bullying nas escolas.</p>
                                </div>
                                <div id="tab-2" class="tab-panel">
                                    <h2>O aplicativo</h2>
                                    <p>Canal de comunicação sem barreiras para as vítimas, que podem relatar de forma
                                        prática, segura e com total sigilo as ocorrências de Bullying.</p>
                                </div>
                                <div id="tab-3" class="tab-panel">
                                    <h2>Mapeamento estatístico</h2>
                                    <p>Apresenta um cenário de Bullying mais fidedigno, auxiliando as escolas a agirem
                                        de maneira preventiva, cuidadosa e amorosa com as vítimas.</p>
                                </div>
                                <p class="hero-cta is-revealing">
                                  <a class="button button-primary button-shadow" [routerLink]="['/checkout', 'donation']">Doe agora</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--
      <section class="features-extend">
            <div class="container" [style.padding-top.em]="'5'">
                <div class="text-center">
                  <div class="container-sm">
                      <h2 class="section-title mt-0">Escolas Rodando</h2>
                      <p class="section-paragraph mb-0">#Escolas</p>
                  </div>
                </div>
            </div>
            <div class="container">
                <owl-carousel
                [options]="mySlideOptions"
                [carouselClasses]="['owl-theme', 'sliding']">
                  <div class="item" *ngFor="let school of mySlideImages;let i = index">
                  <div class="owl-content">
                    <div class="owl-image">
                        <img [src]="school.image" alt="Clientes" />
                    </div>
                    <div class="owl-title">
                      <p class="owl-header">{{ school.title }}</p>
                    </div>
                  </div>
                  </div>
              </owl-carousel>
            </div>
        </section> -->
            <!--
      <section class="testimonials section">
        <div class="container">
            <div class="testimonials-inner section-inner">
                <div class="testimonials-header text-center text-light">
                    <h2 class="section-title mt-0">Depoimentos</h2>
                </div>
                <div class="testimonials-wrap">
                    <div class="testimonial text-sm is-revealing">
                        <div class="testimonial-inner">
                            <div class="testimonial-main">
                                <div class="testimonial-body">
                                    <p>I’ve read a ton of blog posts about how some startups hit this milestone with ease. They came up with an amazing idea that “just clicked”, made!</p>
                                </div>
                            </div>
                            <div class="testimonial-footer">
                                <div class="testimonial-name"><a href="#">@mikesmith</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial text-sm is-revealing">
                        <div class="testimonial-inner">
                            <div class="testimonial-main">
                                <div class="testimonial-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eleifend donec pretium vulputate sapien. Quisque egestas diam in arcu cursus euismod quis.</p>
                                </div>
                            </div>
                            <div class="testimonial-footer">
                                <div class="testimonial-name"><a href="#">@mikesmith</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial text-sm is-revealing">
                        <div class="testimonial-inner">
                            <div class="testimonial-main">
                                <div class="testimonial-body">
                                    <p>I’ve read a ton of blog posts about how some startups hit this milestone with ease. They came up with an amazing idea that “just clicked”, made!</p>
                                </div>
                            </div>
                            <div class="testimonial-footer">
                                <div class="testimonial-name"><a href="#">@mikesmith</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial text-sm is-revealing">
                        <div class="testimonial-inner">
                            <div class="testimonial-main">
                                <div class="testimonial-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eleifend donec pretium vulputate sapien. Quisque egestas diam in arcu cursus euismod quis.</p>
                                </div>
                            </div>
                            <div class="testimonial-footer">
                                <div class="testimonial-name"><a href="#">@mikesmith</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial text-sm is-revealing">
                        <div class="testimonial-inner">
                            <div class="testimonial-main">
                                <div class="testimonial-body">
                                    <p>I’ve read a ton of blog posts about how some startups hit this milestone with ease. They came up with an amazing idea that “just clicked”, made!</p>
                                </div>
                            </div>
                            <div class="testimonial-footer">
                                <div class="testimonial-name"><a href="#">@mikesmith</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial text-sm is-revealing">
                        <div class="testimonial-inner">
                            <div class="testimonial-main">
                                <div class="testimonial-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eleifend donec pretium vulputate sapien. Quisque egestas diam in arcu cursus euismod quis.</p>
                                </div>
                            </div>
                            <div class="testimonial-footer">
                                <div class="testimonial-name"><a href="#">@mikesmith</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    -->
            <section class="pricing section">
                <div class="container">
                    <!-- <div class="pricing-faqs container-sm is-revealing">
                <h4 class="mt-40 mb-32">FAQs</h4>
                <ul class="accordion">
                    <li>
                        <div class="accordion-title">
                            <span>In a professional context it often happens that private?</span>
                            <div class="accordion-icon"></div>
                        </div>
                        <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </div>
                    </li>
                    <li>
                        <div class="accordion-title">
                            <span>Lorem ipsum dolor sit amet, consectetur</span>
                            <div class="accordion-icon"></div>
                        </div>
                        <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Ut enim ad minim veniam semper. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </li>
                    <li>
                        <div class="accordion-title">
                            <span>In a professional context it often happens that private?</span>
                            <div class="accordion-icon"></div>
                        </div>
                        <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </div>
                    </li>
                    <li>
                        <div class="accordion-title">
                            <span>Lorem ipsum dolor sit amet, consectetur</span>
                            <div class="accordion-icon"></div>
                        </div>
                        <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Ut enim ad minim veniam semper. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </li>
                </ul>
            </div> -->
                </div>
            </section>
            <app-footer></app-footer>
        </main>
    </div>
</body>
