/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./loading.component";
import * as i7 from "@angular/material/dialog";
var styles_LoadingComponent = [];
var RenderType_LoadingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingComponent, data: {} });
export { RenderType_LoadingComponent as RenderType_LoadingComponent };
export function View_LoadingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i1.View_MatSpinner_0, i1.RenderType_MatSpinner)), i0.ɵdid(1, 49152, null, 0, i2.MatSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 1).diameter; var currVal_2 = i0.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LoadingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i0.ɵdid(1, 114688, null, 0, i6.LoadingComponent, [i7.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingComponentNgFactory = i0.ɵccf("ng-component", i6.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
export { LoadingComponentNgFactory as LoadingComponentNgFactory };
