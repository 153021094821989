import { Component, OnInit } from '@angular/core';
import { DashBoardService } from '../dashboard/dashboard.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { RelatosDetalhesComponent } from './relatos-detalhes/relatos-detalhes.component';
import { ServiceService } from '../service.service';
import { NotifierService } from 'angular-notifier';
import { LoadingComponent } from '../utils/loading.component';

@Component({
  selector: 'app-relatos',
  templateUrl: './relatos.component.html',
  styleUrls: ['./relatos.component.css']
})
export class RelatosComponent implements OnInit {
  notifier: NotifierService;
  id_escola: number;
  user: any;
  listRelatos: any = [];
  totalRelatos: number;
  skip: number = 0;
  limit: number = 5;

  constructor(
    private serviceDash: DashBoardService,
    private service: ServiceService,
    public dialog: MatDialog,
    notifierService: NotifierService) {
    this.notifier = notifierService;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.id_escola = this.user.id_escola;
  }

  ngOnInit() {
    this.findRelatosByEscola();
  }

  findRelatosByEscola() {
    this.serviceDash.findRelatosByEscola(this.id_escola, this.skip, this.limit).subscribe(data => {
      this.listRelatos = data.json().data;
      this.totalRelatos = data.json().total;
    });
  }

  pageEventRelato(event) {
    this.skip = event.pageIndex * event.pageSize;
    this.limit = event.pageSize;
    this.findRelatosByEscola();
  }

  alterarDenunciaStatusEscola(status, id_denuncia) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.service.alterarDenunciaStatusEscola({ table: 'status_escola', value: status, id_denuncia: id_denuncia })
      .subscribe(res => {
        loading.close();
        this.showAlert('success', 'alterado para ' + status);
        this.findRelatosByEscola();
      }, err => {
        console.log(err);
        loading.close();
        this.showAlert('error', err);
      });

  }

  openDetalhes(relato) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // dialogConfig.width = '600px';
    dialogConfig.data = {
      relato
    };

    const dialogRef = this.dialog.open(RelatosDetalhesComponent, dialogConfig);
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }
}
