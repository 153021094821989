import { ServiceService } from './../service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatDialogConfig } from '@angular/material';
import { CadastroAlunoComponent } from './cadastro-aluno/cadastro-aluno.component';
import { NotifierService } from 'angular-notifier';
import { LoadingComponent } from '../utils/loading.component';
import { EditarAlunoComponent } from './editar-aluno/editar-aluno.component';
import { Aluno } from '../interfaces/aluno.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alunos',
  templateUrl: './alunos.component.html',
  styleUrls: ['./alunos.component.css']
})
export class AlunosComponent implements OnInit {

  ELEMENT_DATA: PeriodicElement[];
  notifier: NotifierService;

  displayedColumns: string[] = ['nome_aluno', 'sexo_aluno', 'serie_aluno', 'turno_aluno', 'tel_aluno', 'acao'];
  dataSource: any;
  id_escola: number;
  user: any;
  nome_aluno: string;

  listAlunos: any = [];

  skip: number = 0;
  limit: number = 5;
  total: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog, private service: ServiceService, notifierService: NotifierService, private router: Router) {
    this.notifier = notifierService;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.id_escola = this.user.id_escola;
    this.listarAlunos(this.id_escola);
  }

  ngOnInit() {
    this.verificaEscolaAtiva(this.id_escola);
  }

  openCadastro() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(CadastroAlunoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.listarAlunos(this.id_escola);
      //  this.animal = result;
    });
  }

  openEditar(aluno: Aluno) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      aluno
    };
    const dialogRef = this.dialog.open(EditarAlunoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.listarAlunos(this.id_escola);
      //  this.animal = result;
    });
  }

  listarAlunos(id_escola: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.service.listarAlunos(id_escola, this.skip, this.limit).subscribe(data => {
      // this.listAlunos = data.json();
      this.total = data.json().total
      this.ELEMENT_DATA = data.json().data;
      this.listAlunos = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
      // this.listAlunos.paginator = this.paginator;
      loading.close();
    }, err => {
      loading.close();
      this.showAlert('error', err.json().error);
    });
  }

  pesquisarAluno(event) {
    if (this.nome_aluno) {
      if (event.code !== 'Space') {
        this.service.getAlunoEscolaByName(this.id_escola, this.nome_aluno).subscribe(data => {
          this.ELEMENT_DATA = data.json();
          this.listAlunos = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          // this.listAlunos.paginator = this.paginator;
        });
      }
    }
    if (event.key === 'Enter' && this.nome_aluno === '') {
      this.listarAlunos(this.id_escola);
    }
  }

  pageNext(event) {
    this.limit = event.pageSize;
    this.skip = event.pageIndex * this.limit;

    this.listarAlunos(this.id_escola);
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }

  verificaEscolaAtiva(id_escola) {
    this.service.verificaEscolaAtiva(id_escola).subscribe(data => {
      if (data.json().ativo == 'N') {
        localStorage.clear();
        this.router.navigate(['/']);
      }
    });
  }
}

export interface PeriodicElement {
  id_aluno: number;
  id_usuario: number;
  id_escola: number;
  id_endereco: number;
  data_nascimento: Date;
  nome_aluno: string;
  sexo_aluno: string;
  serie_aluno: string;
  turno_aluno: string,
  tel_aluno: string;
  avatar_aluno: string;
  token_aluno: string;
  dth_cadastro: Date;
}


