<notifier-container></notifier-container>
<div id="total">
  <div class="row information">
    <div class="col" (click)="setPage('Alunos')">
      <mat-card>
        <div class="d-flex flex-row">
          <div class="round round-lg align-self-center round-info">
            <mat-icon>assignment_ind</mat-icon>
          </div>
          <div class="align-self-center">
            <h3 class="qtd">{{ qtdAlunos }}</h3>
            <h5 class="title">Alunos Cadastrados</h5>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <div class="d-flex flex-row">
          <div class="round round-lg align-self-center round-info">
            <mat-icon>record_voice_over</mat-icon>
          </div>
          <div class="align-self-center">
            <h3 class="qtd">{{ qtdDenuncias }}</h3>
            <h5 class="title">Denúncias Feitas</h5>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <div class="d-flex flex-row">
          <div class="round round-lg align-self-center round-info">
            <mat-icon>pan_tool</mat-icon>
          </div>
          <div class="align-self-center">
            <h3 class="qtd">{{ qtdEmergencia }}</h3>
            <h5 class="title">Emergência</h5>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <!-- <ngc-float-button icon="insert_drive_file" *ngIf="visible" (click)="generatePDF('total')">
  </ngc-float-button> -->

  <div class="row" id="tipo_bullying_data">
    <div class="line">
      <mat-card class="card-1">
        <button mat-icon-button [matMenuTriggerFor]="paramMenu" class="filter">
          <mat-icon>filter_list</mat-icon>
        </button>

        <mat-menu #paramMenu="matMenu">
          <form padding [formGroup]="dtForm" (submit)="buscarTiposDeBullyingPorData()" novalidate>
            <mat-form-field>
              <input matInput [matDatepicker]="picker" placeholder="Data Inicial" formControlName="dt_inicial">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput [matDatepicker]="pic" placeholder="Data Final" formControlName="dt_final">
              <mat-datepicker-toggle matSuffix [for]="pic"></mat-datepicker-toggle>
              <mat-datepicker #pic></mat-datepicker>
            </mat-form-field>
            <button mat-menu-item>Filtrar</button>
          </form>
        </mat-menu>
        <canvas id="tipos_bullying_por_data" height="100">{{ cart_line_multi_axis }}</canvas>
        <button  mat-mini-fab class="fab-toggler" *ngIf="visible" (click)="generatePDF('tipo_bullying_data')">
          <i class="material-icons">insert_drive_file</i>
        </button>
        Gerar Arquivo PDF!
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6" id="local_bullying">
      <mat-card>
        <canvas id="local">{{ chart_bar }}</canvas>
        <button  mat-mini-fab class="fab-toggler" *ngIf="visible" (click)="generatePDF('local_bullying')">
          <i class="material-icons">insert_drive_file</i>
        </button>
        Gerar Arquivo PDF!
      </mat-card>
    </div>
    <div class="col-sm-6" id="numero_denuncias_bullying">
      <mat-card>
        <canvas id="denuncia">{{ chart_pie }}</canvas>
        <button  mat-mini-fab class="fab-toggler" *ngIf="visible" (click)="generatePDF('numero_denuncias_bullying')">
          <i class="material-icons">insert_drive_file</i>
        </button>
        Gerar Arquivo PDF!
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6" id="denuncias_serie_idade">
      <mat-card class="card-1">
        <button mat-icon-button [matMenuTriggerFor]="appMenu" class="filter">
          <mat-icon>filter_list</mat-icon>
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="buscarDenunciasPor('series')">Por Séries</button>
          <button mat-menu-item (click)="buscarDenunciasPor('idade')">Por Idade</button>
        </mat-menu>
        <canvas id="qtdDenuncia">{{ chart_pie }}</canvas>
        <button  mat-mini-fab class="fab-toggler" *ngIf="visible" (click)="generatePDF('denuncias_serie_idade')">
          <i class="material-icons">insert_drive_file</i>
        </button>
        Gerar Arquivo PDF!
      </mat-card>
    </div>
    <div class="col-sm-6" id="numero_locais_bullying_escola">
      <mat-card class="effect">
        <canvas id="escola">{{ chart_bar }}</canvas>
        <button  mat-mini-fab class="fab-toggler" *ngIf="visible" (click)="generatePDF('numero_locais_bullying_escola')">
          <i class="material-icons">insert_drive_file</i>
        </button>
        Gerar Arquivo PDF!
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="line">
      <mat-card class="card-1">
        <canvas id="tipos_bullying" height="100">{{ chart_line }}</canvas>
        <button  mat-mini-fab class="fab-toggler" *ngIf="visible" (click)="generatePDF('tipos_bullying')">
          <i class="material-icons">insert_drive_file</i>
        </button>
        Gerar Arquivo PDF!
      </mat-card>
    </div>
  </div>
</div>
