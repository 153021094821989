import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import * as ChartLabel from 'chartjs-plugin-labels';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { DashBoardService } from './dashboard.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Observable } from 'rxjs/Observable';
// @ts-ignore
// import ApexCharts from 'apexcharts';

declare var $: any;

import * as jspdf from 'jspdf';

import html2canvas from 'html2canvas';
import { MainNavComponent } from '../main-nav/main-nav.component';


declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DashboardComponent implements OnInit {
  chart_pie: any;
  chart_bar: any;
  chart_line: any;
  cart_line_multi_axis: any;
  id_escola: number;
  user: any;

  qtdAlunos: number;
  qtdDenuncias: number;
  qtdEmergencia: number;
  tiposDenuncia: Array<any> = [];
  local_bulying: Array<any> = [];
  local_bulying_escola: Array<any> = [];
  desc_tipo_denuncia: Array<string> = [];
  count_tipo_denuncia: Array<number> = [];
  desc_local_bullying: Array<string> = [];
  count_local_bullying: Array<any> = [];
  desc_local_bullying_escola: Array<string> = [];
  count_local_bullying_escola: Array<number> = [];
  list_tipo_bullying: Array<any> = [];
  desc_tipo_bullying: Array<any> = [];
  count_tipo_bullying: Array<any> = [];
  ListDenunciasEscola: Array<any> = [];
  desc_denuncia: Array<string> = [];
  count_denuncia: Array<number> = [];
  list_tipo_bullying_por_data: Array<any> = [];

  visible: Boolean = true;

  dtForm: any;
  messageDt_inicial = '';
  messageDt_final = '';
  errorDtInicial = false;
  errorDtFinal = false;

  notifier: NotifierService;

  emergencias: Array<any> = [];

  // tslint:disable-next-line:max-line-length
  constructor(private service: ServiceService,
    private serviceDash: DashBoardService,
    private router: Router,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private mainNavComponent: MainNavComponent) {
    this.emergencias = JSON.parse(localStorage.getItem('emergencias'));
    this.notifier = notifierService;
    this.dtForm = formBuilder.group({
      dt_inicial: [Validators.required],
      dt_final: [Validators.required]
    });
    this.user = JSON.parse(localStorage.getItem('user'));
    this.id_escola = this.user.id_escola;
  }

  ngOnInit() {
    Chart.pluginService.register(ChartLabel);
    this.verificaEscolaAtiva(this.id_escola);
    this.countAlunos();
    this.countDenunciasParaEscola();
    this.tiposDenunciasEscola();
    this.countDenunciasEscolaPorSerie();
    this.countLocalBullying();
    this.countLocalBullyingEscola();
    this.countTiposDeBullingByEscola();
    this.tipoBullyingByDataAndEscola();
    this.countDenunciasEscolaEmergencia();
    this.serviceDash.emergencys.subscribe(msg => {
      this.showAlert('warning', 'Nova Emergencia!');
      this.emergencias.push(msg.data);
      localStorage.setItem('emergencias', JSON.stringify(this.emergencias));
    });
  }

  setPage(page) {
    this.mainNavComponent.setPage(page);
  }

  generatePDF(id: string) {
    this.visible = false;
    setTimeout(async () => {
      const data = document.getElementById(id);
      await html2canvas(data).then(canvas => {
        // Few necessary setting options
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
        const position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.save(`Relatório ${id}.pdf`); // Generated PDF
        this.visible = true;
      });
    }, 500);
  }

  chartBarVertical(id, label, labels, data, arrayTotal, type) {
    this.chart_bar = new Chart(id, {
      type: type,
      data: {
        datasets: [{
          label: label,
          data: data,
          backgroundColor: [
            '#2AF598',
            '#F76B1C',
            '#21D4FD',
            '#0093E9',
            '#FFE32C',
            '#85FFBD',
            '#3e95cd',
            '#8e5ea2',
            '#3cba9f',
            '#e8c3b9',
            '#c45850',
          ],
          borderColor: [
            '#2AF598',
            '#F76B1C',
            '#21D4FD',
            '#0093E9',
            '#FFE32C',
            '#85FFBD',
            '#3e95cd',
            '#8e5ea2',
            '#3cba9f',
            '#e8c3b9',
            '#c45850',
          ],
          borderWidth: 1
        }, {
          label: 'Line Dataset',
          data: arrayTotal,
          type: 'line',
          hidden: true,
        }],
        labels: labels
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        },
        title: {
          display: true,
          text: label
        }
      }
    });
  }

  chartBar(id, label, labels, data, type) {
    this.chart_bar = new Chart(id, {
      type: type,
      data: {
        labels: labels,
        datasets: [{
          label: label,
          data: data,
          backgroundColor: [
            '#2AF598',
            '#F76B1C',
            '#21D4FD',
            '#0093E9',
            '#FFE32C',
            '#85FFBD',
            '#3e95cd',
            '#8e5ea2',
            '#3cba9f',
            '#e8c3b9',
            '#c45850',
          ],
          borderColor: [
            '#2AF598',
            '#F76B1C',
            '#21D4FD',
            '#0093E9',
            '#FFE32C',
            '#85FFBD',
            '#3e95cd',
            '#8e5ea2',
            '#3cba9f',
            '#e8c3b9',
            '#c45850',
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        legend: { display: false },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        },
        title: {
          display: true,
          text: label
        }
      }
    });

  }

  chartPie(id, label, labels, data, type) {

    // let options = {
    //   chart: {
    //     type: type,
    //   },
    //   labels: labels,
    //   series: data,
    //   responsive: [{
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         // width: 200,
    //         // height: 200,
    //       },
    //       legend: {
    //         position: 'bottom'
    //       }
    //     }
    //   }]
    // }

    // let chart = new ApexCharts(
    //   document.querySelector("#" + id),
    //   options
    // );

    // chart.render();

    this.chart_pie = new Chart(id, {
      type: type,
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: data,
            backgroundColor: [
              '#3e95cd',
              '#8e5ea2',
              '#3cba9f',
              '#e8c3b9',
              '#c45850',
              'rgba(255, 159, 64, 0.2)'],
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: label
        },
        legend: {
          display: false
        },
        plugins: {
          labels: [
            {
              render: 'label',
              position: 'outside'
            },
            {
              render: 'percentage'
            }
          ],
          fontSize: 12,
          fontColor: '#fff',
          fontStyle: 'normal',
          // tslint:disable-next-line:quotemark
          fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        }
      }
    });
  }

  chartLine(id, label, labels, data) {
    this.chart_line = new Chart(id, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: label,
          data: data,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        title: {
          display: true,
          text: label
        },
        scales: {
          yAxes: [{
            stacked: true
          }]
        }
      }
    });
  }

  chartLineMultiAxis(id, label, labels, data) {
    this.cart_line_multi_axis = new Chart(id, {
      type: 'line',
      data: {
        labels: labels,
        datasets: data
      },
      options: {
        title: {
          display: true,
          text: label
        }
      }
    });
  }

  verificaEscolaAtiva(id_escola) {
    this.service.verificaEscolaAtiva(id_escola).subscribe(data => {
      if (data.json().ativo === 'N') {
        localStorage.clear();
        this.router.navigate(['/']);
      }
    });
  }

  countDenunciasEscolaEmergencia() {
    this.serviceDash.countDenunciasEscolaEmergencia(this.id_escola).subscribe(data => {
      this.qtdEmergencia = data.json().count;
    }, err => {
      console.log(err);
    })
  }

  countAlunos() {
    this.serviceDash.countAlunos(this.id_escola).subscribe(res => {
      this.qtdAlunos = res.json().qtdAlunos;
    });
  }

  countDenunciasParaEscola() {
    this.serviceDash.countDenunciasParaEscola(this.id_escola).subscribe(res => {
      this.qtdDenuncias = res.json().qtdDenuncias;
    });
  }

  tiposDenunciasEscola() {
    this.serviceDash.tiposDenunciasEscola(this.id_escola).subscribe(async res => {
      this.tiposDenuncia = res.json();
      await this.tiposDenuncia.forEach(value => {
        this.desc_tipo_denuncia.push(value.desc_tipo_denuncia);
        // tslint:disable-next-line:radix
        this.count_tipo_denuncia.push(parseInt(value.count));
      });

      this.chartPie('denuncia', 'Nº de Denúncias', this.desc_tipo_denuncia, this.count_tipo_denuncia, 'doughnut');

    });
  }

  countDenunciasEscolaPorSerie() {
    this.serviceDash.countDenunciasEscolaPorSerie(this.id_escola).subscribe(async res => {
      this.ListDenunciasEscola = res.json();
      await this.ListDenunciasEscola.forEach(value => {
        this.desc_denuncia.push(value.desc);
        // tslint:disable-next-line:radix
        this.count_denuncia.push(parseInt(value.count));
      });

      this.chartPie('qtdDenuncia', 'Denúncias', this.desc_denuncia, this.count_denuncia, 'pie');

    });

  }

  countDenunciasEscolaPorIdade() {
    this.serviceDash.countDenunciasEscolaPorIdade(this.id_escola).subscribe(async res => {
      this.ListDenunciasEscola = res.json();
      await this.ListDenunciasEscola.forEach(value => {
        this.desc_denuncia.push(value.idade + ' Anos');
        // tslint:disable-next-line:radix
        this.count_denuncia.push(parseInt(value.count));
      });

      this.chartPie('qtdDenuncia', 'Denúncias', this.desc_denuncia, this.count_denuncia, 'pie');

    });
  }

  countLocalBullying() {

    this.serviceDash.countLocalBullying(this.id_escola).subscribe(async res => {
      this.local_bulying = res.json();
      // tslint:disable-next-line:prefer-const
      let arrayTotal = [];
      await this.local_bulying.forEach(value => {
        this.desc_local_bullying.push(value.desc_local_bullying);
        // tslint:disable-next-line:radix
        // this.count_local_bullying.push({data: [parseInt(value.count)], label: value.desc_local_bullying});
        arrayTotal.push(this.qtdDenuncias);
        // tslint:disable-next-line:radix
        this.count_local_bullying.push(parseInt(value.count));

      });
      // this.chartBar('local', 'Nº de Locais do Bullying', this.desc_local_bullying, this.count_local_bullying, 'bar');
      this.chartBarVertical('local', 'Nº de Locais do Bullying', this.desc_local_bullying, this.count_local_bullying, arrayTotal, 'bar');

    });

  }

  countLocalBullyingEscola() {
    this.serviceDash.countLocalBullyingEscola(this.id_escola).subscribe(async res => {
      this.local_bulying_escola = res.json();
      await this.local_bulying_escola.forEach(value => {
        this.desc_local_bullying_escola.push(value.desc_local_escola);
        // tslint:disable-next-line:radix
        this.count_local_bullying_escola.push(parseInt(value.count));
      });

      // tslint:disable-next-line:max-line-length
      this.chartBar('escola', 'Nº de Locais do Bullying na Escola', this.desc_local_bullying_escola, this.count_local_bullying_escola, 'horizontalBar');

    });
  }

  countTiposDeBullingByEscola() {
    this.serviceDash.countTiposDeBullingByEscola(this.id_escola).subscribe(async res => {
      this.list_tipo_bullying = res.json();
      await this.list_tipo_bullying.forEach(value => {
        this.desc_tipo_bullying.push(value.desc_tipo_bullying);
        // tslint:disable-next-line:radix
        this.count_tipo_bullying.push(parseInt(value.count));
      });

      this.chartLine('tipos_bullying', 'Nº total de Tipos de Bullying Sofridos', this.desc_tipo_bullying, this.count_tipo_bullying);

    });
  }

  tipoBullyingByDataAndEscola(dt_inicial?: any, dt_final?: any) {

    // tslint:disable-next-line:prefer-const
    let arrayDt = [];
    let arrayDatas = [];
    let arrayDesc = [];
    // tslint:disable-next-line:prefer-const
    let dataset = [];
    // tslint:disable-next-line:prefer-const
    let count = [];
    // tslint:disable-next-line:prefer-const
    let colors = ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850', '#466580', '#5cb85c'];
    let indexColors = 0;
    this.serviceDash.tipoBullyingByDataAndEscola({ dt_inicial: dt_inicial, dt_final: dt_final, id_escola: this.id_escola })
      .subscribe(async res => {
        // tslint:disable-next-line:prefer-const
        let object = {
          label: '',
          data: [],
          backgroundColor: '',
          borderColor: '',
          // borderWidth: 1,
          fill: false
        };
        this.list_tipo_bullying_por_data = res.json();

        await this.list_tipo_bullying_por_data.forEach(value => {
          arrayDatas.push(value.data_denuncia);
          arrayDesc.push(value.desc_tipo_bullying);
        });

        arrayDesc = arrayDesc.filter((elem, i, arr) => {
          return arr.indexOf(elem) === i;
        });

        arrayDatas = arrayDatas.filter((elem, i, arr) => {
          return arr.indexOf(elem) === i;
        });

        await arrayDesc.map(value => {
          // tslint:disable-next-line:prefer-const
          let ar = this.list_tipo_bullying_por_data.filter((elem, i, arr) => {
            if (value === elem.desc_tipo_bullying) {
              return arr.indexOf(elem) === i;
            }
          });
          ar.forEach(elem => {
            count.push(elem.count);
            object = {
              label: elem.desc_tipo_bullying,
              data: count,
              backgroundColor: colors[indexColors],
              borderColor: colors[indexColors],
              // borderWidth: 1,
              fill: false
            };
            dataset.push(object);
          });

          count = [];
          indexColors++;

        });

        dataset = await dataset.reduce((acc, value, index) => {
          // tslint:disable-next-line:no-unused-expression
          (index === 0) && (acc.push(value));
          // tslint:disable-next-line:no-unused-expression
          (acc.every(v => JSON.stringify(v) !== JSON.stringify(value))) && (acc.push(value));
          return acc;
        }, []);



        arrayDatas = arrayDatas.map(elem => {
          return `${new Date(elem).getDate()}-${new Date(elem).getMonth() + 1}-${new Date(elem).getFullYear()}`;
        });
        this.chartLineMultiAxis('tipos_bullying_por_data', 'Nº de Tipos de Bullying Sofridos pro périodos de datas', arrayDatas, dataset);


      });
  }

  async buscarTiposDeBullyingPorData() {

    // tslint:disable-next-line:prefer-const
    let { dt_inicial, dt_final } = this.dtForm.controls;

    if (!this.dtForm.valid) {
      if (!dt_inicial.valid) {
        this.errorDtInicial = true;
        this.messageDt_inicial = 'Por Favor, Preencha a data inicial!';
        this.showAlert('error', this.messageDt_inicial);
      } else {
        this.messageDt_inicial = '';
      }

      if (!dt_final.valid) {
        this.errorDtFinal = true;
        this.messageDt_final = 'Por Favor, Preencha a data final!';
        this.showAlert('error', this.messageDt_final);
      } else {
        this.messageDt_final = '';
      }
    } else {
      const dtIncial = this.dtForm.value.dt_inicial;
      const dtFinal = this.dtForm.value.dt_final;
      // tslint:disable-next-line:max-line-length
      await this.tipoBullyingByDataAndEscola(`${dtIncial._i.year}-${dtIncial._i.month + 1}-${dtIncial._i.date}`, `${dtFinal._i.year}-${dtFinal._i.month + 1}-${dtFinal._i.date}`);
    }
  }

  buscarDenunciasPor(filter: string) {
    this.desc_denuncia = [];
    this.count_denuncia = [];
    if (filter === 'series') {
      this.countDenunciasEscolaPorSerie();
    }

    if (filter === 'idade') {
      this.countDenunciasEscolaPorIdade();
    }

    if (filter === 'horario') {

    }
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }
}
