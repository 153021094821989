<notifier-container></notifier-container>
<main class="j3">
<div class="j4">
    <mat-card>
        <img matCardImage src="assets/img/marca_horizontal_.png">
        <mat-card-header>
            <mat-card-title>Login Administrativo</mat-card-title>
            <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
        </mat-card-header>
        <form [formGroup]="loginFormGroup" (ngSubmit)="login()" #loginForm="ngForm">
          <mat-card-content>
            <div class="example-container">

                <mat-form-field>
                  <input matInput placeholder="login" type="text" formControlName="login" required>
                </mat-form-field>

                <mat-form-field>
                  <input matInput placeholder="senha" type="password" formControlName="senha" required>
                </mat-form-field>

            </div>

          </mat-card-content>
          <mat-card-actions align="start">
              <button mat-raised-button color="primary" [disabled]="!loginForm.form.valid">Entrar</button>
          </mat-card-actions>
        </form>

        <mat-card-footer>
        </mat-card-footer>
    </mat-card>
    <div style="color: #fff; margin: 10px;">
        © Inovapp 2018
    </div>
</div>
</main>
