<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="dadosPessoaisFormGroup">
    <ng-template matStepLabel>Dados Pessoais</ng-template>
    <mat-list>
      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="nome" placeholder="Nome" [(ngModel)]="pagSeguro.dados.nome"
            #nome="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="cpf" placeholder="CPF" [(ngModel)]="pagSeguro.dados.cpf" #cpf="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="nascimento" placeholder="Data Nascimento"
            [(ngModel)]="pagSeguro.dados.nascimento" #nascimento="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="telefone" placeholder="Telegone (DD) 123456789"
            [(ngModel)]="pagSeguro.dados.telefone" #telefone="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="email" name="email" placeholder="E-mail" [(ngModel)]="pagSeguro.dados.email"
            #email="ngModel" />
        </mat-form-field>
      </mat-list-item>
    </mat-list>
    <div class="form-group col-sm-12">
      <button type="button" class="btn btn-primary btn-block" matStepperNext>Prosseguir</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="formaDePagamentoFormGroup">
    <ng-template matStepLabel>Formas de Pagamento</ng-template>
    <mat-list>
      <!-- <h6 class="title">Selecione a forma de pagamento</h6> -->
      <mat-radio-group aria-label="Select an option">
        <div class="row" id="radio">
          <mat-radio-button value="1">
            <div class="col" (click)="selecionarFormaDePagamento('BOLETO')">
              <h6>{{ BOLETO.name }}</h6>
              <img src="assets/img/code_barre.png" />
            </div>
          </mat-radio-button>
          <mat-radio-button value="2">
            <div class="col" (click)="selecionarFormaDePagamento('CRED_CARD')">
              <h6>Cartão de Crédito</h6>
              <img src="assets/img/credito.png" />
            </div>
          </mat-radio-button>
        </div>
      </mat-radio-group>
      <div class="row">
        <button type="button" class="btn btn-primary btn-block" matStepperNext (click)="proseguirFormaDePagamento()"
          [disabled]="formaDePagamentoSelecionado == null">Prosseguir</button>
      </div>
    </mat-list>
  </mat-step>
  <mat-step *ngIf="formaDePagamentoSelecionado == 'CRED_CARD'" [stepControl]="cartaoDeCreditoFormGroup">
    <ng-template matStepLabel>Dados do cartão</ng-template>
    <mat-list>
      <div class="card-details">
        <!-- <h6 class="title">Dados do Cartão</h6> -->
        <div class="row">
          <div class="form-group col-sm-7">
            <label for="card-holder">Titular do cartão</label>
            <input id="card-holder" type="text" class="form-control" placeholder="CPF ou CNPJ"
              aria-label="Card Holder" aria-describedby="basic-addon1" [(ngModel)]="pagSeguro.dados.CardCpf">
          </div>
          <div class="form-group col-sm-5">
            <label for="">Data Validade</label>
            <div class="input-group expiration-dasste">
              <input type="number" class="form-control" placeholder="MM" aria-label="MM" aria-describedby="basic-addon1"
                [(ngModel)]="pagSeguro.dados.mesValidadeCard">
              <span class="date-separator">/</span>
              <input type="number" class="form-control" placeholder="AA" aria-label="AA" aria-describedby="basic-addon1"
                [(ngModel)]="pagSeguro.dados.anoValidadeCard">
            </div>
          </div>
          <div class="form-group col-sm-7">
            <label for="card-number">Número do Cartão
              <img *ngIf="brandName != null"
                src="https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/42x20/{{brandName}}.png"
                style="float: right;">
            </label>
            <input id="card-number" type="text" class="form-control" placeholder="Card Number" aria-label="Card Holder"
              aria-describedby="basic-addon1" [(ngModel)]="pagSeguro.dados.numCard" (ngModelChange)="buscaBandeira()">
          </div>
          <div class="form-group col-sm-5">
            <!-- <label for="cvc">CVC
                <img src="assets/img/cvc.png" class="cvc">
            </label> -->
            <label for="cvc">Código de Segurança</label>
            <div class="input-group expiration-date">
              <input id="cvc" type="number" class="form-control" placeholder="CVC" aria-label="Card Holder"
                aria-describedby="basic-addon1" [(ngModel)]="pagSeguro.dados.codSegCard">
              <img src="assets/img/cvc.png" class="cvc">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-5">
            <label for="">Parcelas</label>
            <select #par class="custom-select" [(ngModel)]="parcela">
              <option>Selecione o parcelamento</option>
              <option *ngFor="let item of parcelas" [ngValue]="item">
                {{ item.quantity }} parcelas de R$: {{ parseFloat(item.installmentAmount) }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-primary btn-block" (click)="finalizarCompra()">Prosseguir</button>
        </div>
      </div>
    </mat-list>
  </mat-step>
  <!-- <mat-step *ngIf="formaDePagamentoSelecionado == 'CRED_CARD'">
    <mat-list>
      <ng-template matStepLabel>Dados de Endereço</ng-template>
      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="logradouro" placeholder="Endereço" [(ngModel)]="pagSeguro.dados.logradouro"
            #logradouro="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="numero" placeholder="Número" [(ngModel)]="pagSeguro.dados.numero"
            #numero="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="bairro" placeholder="Bairro" [(ngModel)]="pagSeguro.dados.bairro"
            #bairro="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="cep" placeholder="CEP" [(ngModel)]="pagSeguro.dados.cep" #cep="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="estado" placeholder="Estado" [(ngModel)]="pagSeguro.dados.estado"
            #estado="ngModel" />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <mat-form-field>
          <input matInput type="text" name="cidade" placeholder="Cidade" [(ngModel)]="pagSeguro.dados.cidade"
            #cidade="ngModel" />
        </mat-form-field>
      </mat-list-item>
    </mat-list>
  </mat-step> -->

  <!-- <button ion-button block (click)="pagSeguro.pagar()">Salvar</button> -->
</mat-horizontal-stepper>