import ScrollReveal from 'scrollreveal';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-bullying',
  templateUrl: './bullying.component.html',
  styleUrls: ['./bullying.component.css']
})
export class BullyingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.animations();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      const element = document.getElementById('navbar');
      element.classList.add('fixed-top');
    } else {
      const element = document.getElementById('navbar');
      element.classList.remove('fixed-top');
    }
  }

  animations() {

    ScrollReveal().reveal('.hero-title, .hero-paragraph, .player-nobullying', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'left',
      interval: 150
    });


    ScrollReveal().reveal('.animate-box', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'left',
      interval: 150
    });

    const pricingTables = document.querySelectorAll('.pricing-table');

    pricingTables.forEach(pricingTable => {
      const pricingTableHeader = [].slice.call(pricingTable.querySelectorAll('.pricing-table-header'))
      const pricingTableList = [].slice.call(pricingTable.querySelectorAll('.pricing-table-features li'))
      const pricingTableCta = [].slice.call(pricingTable.querySelectorAll('.pricing-table-cta'))
      const elements = pricingTableHeader.concat(pricingTableList).concat(pricingTableCta)

      ScrollReveal().reveal(elements, {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        interval: 100,
        origin: 'bottom',
        viewFactor: 0.5
      });
    });
  }

}
