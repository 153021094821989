import { Component, OnInit, ViewChild } from '@angular/core';
import { PeriodicElement } from 'src/app/alunos/alunos.component';
import { NotifierService } from 'angular-notifier';
import { MatPaginator, MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { ServiceService } from 'src/app/service.service';
import { LoadingComponent } from 'src/app/utils/loading.component';
import { PainelAdminService } from '../painel-admin.service';

@Component({
  selector: 'app-escolas',
  templateUrl: './escolas.component.html',
  styleUrls: ['./escolas.component.css']
})
export class EscolasComponent implements OnInit {
  ELEMENT_DATA: PeriodicElement[];
  notifier: NotifierService;

  displayedColumns: string[] = ['nome_escola', 'tipo_escola', 'telefone', 'ativo'];
  dataSource: any;
  listEscola: any = [];
  nome_escola: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private service: ServiceService,
    notifierService: NotifierService,
    private painelService: PainelAdminService) {
    this.notifier = notifierService;
    this.listarEscola();
  }

  ngOnInit() {
  }

  listarEscola() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.service.listarEscola().subscribe(data => {
      // this.listEscola = data.json();
      this.ELEMENT_DATA = data.json();
      this.listEscola = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
      this.listEscola.paginator = this.paginator;
      loading.close();
    }, err => {
      loading.close();
      this.showAlert('error', err.json().error);
    });
  }

  pesquisarEscola(event) {
    if (this.nome_escola) {
      if (event.code !== 'Space') {
        this.service.getEscolaByName(this.nome_escola).subscribe(data => {
          this.ELEMENT_DATA = data.json();
          this.listEscola = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.listEscola.paginator = this.paginator;
        });
      }
    }
    if (event.key === 'Enter' && this.listEscola === '') {
      this.listarEscola();
    }
  }

  alterarStatusEscola(element, event) {
    if (event._checked) {
      this.painelService.alterarStatusEscola({ id_escola: element.id_escola, ativo: 'S' })
        .subscribe(res => {
          this.showAlert('success', element.nome_escola + ' Ativada');
        }, err => {
          console.log(err);
          this.showAlert('error', err);
        });
    } else {
      this.painelService.alterarStatusEscola({ id_escola: element.id_escola, ativo: 'N' })
        .subscribe(res => {
          this.showAlert('warning', element.nome_escola + ' Inativada');
        }, err => {
          console.log(err);
          this.showAlert('error', err);
        });
    }
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }

}

export interface PeriodicElement {
  id_usuario: number;
  id_escola: number;
  id_endereco: number;
  nome_escola: Date;
  telefone: string;
  cnpj: string;
  ativo: string;
  tipo_escola: string;
}
