import { Component, OnInit, HostListener } from '@angular/core';
import ScrollReveal from 'scrollreveal';
import { PageSeguroService } from 'src/app/provider/pagseguro.service';

declare var $: any;

@Component({
  selector: 'app-donation-page',
  templateUrl: './donation-page.component.html',
  styleUrls: ['./donation-page.component.css']
})
export class DonationPageComponent implements OnInit {
  urlBackground: string;
  /**
   * Config para Ow-carousel
   */
  public urlJson = 'assets/schools.json';
  public mySlideImages = [
    {
      'image': 'assets/img/school-default.svg',
      'title': 'Escola Municial Doutor Pompeu'
    },
    {
      'image': 'assets/img/school-default.svg',
      'title': 'Sarmento '
    }
  ];
  public mySlideOptions = {
    items: 4,
    loop: true,
    center: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 5,
        nav: false
      },
      1000: {
        items: 5,
        dots: true,
        nav: false,
        loop: true
      }
    }
  };

  constructor(private pagSeguro: PageSeguroService) {
    const doc = document.documentElement;
    this.urlBackground = 'assets/img/bg-no.jpg';

    doc.classList.remove('no-js');
    doc.classList.add('js');
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.makeEffect();
    this.linkChangeTabs();
    this.accordionFaqs();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      const element = document.getElementById('navbar');
      element.classList.add('fixed-top');
    } else {
      const element = document.getElementById('navbar');
      element.classList.remove('fixed-top');
    }
  }

  makeEffect(): any {
    const body = document.querySelector('.has-animations');
    if (body.classList.contains('has-animations')) {

      const sr = ScrollReveal();

      sr.reveal('.hero-title, .donation-paragraph, .hero-cta', {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        origin: 'top',
        interval: 150
      });

      sr.reveal('.topics', {
        duration: 1000,
        distance: '40px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        interval: 100,
        origin: 'bottom',
        scale: 0.9,
        viewFactor: 0.5
      });

      sr.reveal('.donation-illustration', {
        duration: 600,
        distance: '40px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        origin: 'bottom'
      });

      sr.reveal('.feature, .tabs-links li, .testimonial, .pricing-faqs', {
        duration: 600,
        distance: '40px',
        easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
        interval: 100,
        origin: 'bottom',
        viewFactor: 0.2
      });

      sr.reveal('.feature-extended:nth-child(odd) .feature-extended-image', {
        duration: 600,
        distance: '40px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        origin: 'left',
        viewFactor: 0.5
      });
    }
  }

  linkChangeTabs(): any {
    const tabLinksAll = document.getElementsByClassName('tab-link');

    if (tabLinksAll.length) {
      for (let i = 0; i < tabLinksAll.length; i++) {
        tabLinksAll[i].addEventListener('click', function (e) {
          e.preventDefault();
          const tabLinksContainer = (<HTMLElement>(<HTMLElement>tabLinksAll[i]).parentNode.parentNode);

          const tabPanels = tabLinksContainer.nextElementSibling.getElementsByClassName('tab-panel');
          const tabLinks = tabLinksContainer.getElementsByClassName('tab-link');
          // Remove is-active class from all links and panels
          for (let i = 0; i < tabLinks.length; i++) {
            tabLinks[i].classList.remove('is-active');
          }
          for (let i = 0; i < tabPanels.length; i++) {
            tabPanels[i].classList.remove('is-active');
          }
          // Get the ID of panel to display
          const tabID = this.getAttribute('href');
          // Add is-active class to matching link and panel
          tabLinksAll[i].classList.add('is-active');
          document.querySelector(tabID).classList.add('is-active');
        });
      }
    }
  }

  accordionFaqs(): void {
    const accordionElement = document.getElementsByClassName('accordion-title');

    if (accordionElement.length) {
      for (let i = 0; i < accordionElement.length; i++) {
        accordionElement[i].addEventListener('click', function () {
          this.parentNode.classList.toggle('is-open');
          const panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = `${panel.scrollHeight}px`;
          }
        });
      }
    }
  }

}
