import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ServiceService } from 'src/app/service.service';
import { LoadingComponent } from 'src/app/utils/loading.component';
var ɵ0 = MAT_MOMENT_DATE_FORMATS;
var EditarAlunoComponent = /** @class */ (function () {
    function EditarAlunoComponent(data, dialogRef, dialog, formBuilder, notifierService, service) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.service = service;
        this.submitted = false;
        this.isLinear = true;
        this.estados = [
            'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
            'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
            'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
            'SC', 'SP', 'SE', 'TO'
        ];
        this.turnos = ['Manhã', 'Tarde', 'Noite', 'Integral'];
        this.turmas = ['A', 'B', 'C', 'D', 'E', 'F'];
        this.series = [
            '1º ano Fundamental', '2º ano Fundamental', '3º ano Fundamental', '4º ano Fundamental',
            '5º ano Fundamental', '6º ano Fundamental', '7º ano Fundamental', '8º ano Fundamental',
            '9º ano Fundamental', '1º ano Médio', '2º ano Médio', '3º ano Médio',
        ];
        this.notifier = notifierService;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.id_escola = this.user.id_escola;
        this.data.aluno.data_nascimento = this.dataNascimentoFormatada(this.data.aluno.data_nascimento);
    }
    EditarAlunoComponent.prototype.ngOnInit = function () {
        this.firstFormGroup = this.formBuilder.group({
            id_escola: this.id_escola,
            data_nascimento: [this.data.aluno.data_nascimento, Validators.required],
            nome_aluno: [this.data.aluno.nome_aluno, Validators.required],
            sexo_aluno: [this.data.aluno.sexo_aluno, Validators.required],
            serie_aluno: [this.data.aluno.serie_aluno, Validators.required],
            turno_aluno: [this.data.aluno.turno_aluno],
            turma_aluno: [this.data.aluno.turma_aluno],
            tel_aluno: [this.data.aluno.tel_aluno, Validators.required]
        });
        this.secondFormGroup = this.formBuilder.group({
            estado: [this.data.aluno.estado],
            municipio: [this.data.aluno.municipio],
            bairro: [this.data.aluno.bairro],
            logradouro: [this.data.aluno.logradouro],
            numero: [this.data.aluno.numero],
            cep: [this.data.aluno.cep],
            complemento: [this.data.aluno.complemento]
        });
    };
    EditarAlunoComponent.prototype.dataNascimentoFormatada = function (date) {
        var data = new Date(date), dia = (data.getDate() + 1).toString(), diaF = (dia.length == 1) ? '0' + dia : dia, mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes, anoF = data.getFullYear();
        return anoF + "-" + mesF + "-" + diaF;
    };
    EditarAlunoComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    EditarAlunoComponent.prototype.editarAluno = function () {
        var _this = this;
        var loading = this.dialog.open(LoadingComponent, {
            width: '150px',
        });
        var objAluno = {
            usuario: {},
            endereco: this.secondFormGroup.value,
            aluno: this.firstFormGroup.value
        };
        var obj = {
            ids: {
                id_aluno: this.data.aluno.id_aluno,
                id_endereco: this.data.aluno.id_endereco,
                id_escola: this.data.aluno.id_escola,
                id_usuario: this.data.aluno.id_usuario
            },
            aluno: objAluno
        };
        this.service.verificarSeTelefoneAlunoPossuiCadastro(this.firstFormGroup.value.tel_aluno).subscribe(function (data) {
            if (data.json().length > 0 && data.json()[0].id_aluno != obj.ids.id_aluno) {
                _this.showAlert('warning', 'Este Telefone já possui cadastro! Tente outro número!');
                loading.close();
            }
            else {
                _this.service.editarAluno(obj).subscribe(function (res) {
                    console.log(res);
                    _this.showAlert('success', 'Sucesso!!');
                    _this.onNoClick();
                    loading.close();
                }, function (err) {
                    console.log(err);
                    _this.showAlert('error', err);
                    loading.close();
                });
            }
        });
    };
    EditarAlunoComponent.prototype.showAlert = function (type, message) {
        var _this = this;
        this.notifier.show({
            type: type,
            message: message
        });
        setTimeout(function () {
            _this.notifier.hideOldest();
        }, 5000);
    };
    return EditarAlunoComponent;
}());
export { EditarAlunoComponent };
export { ɵ0 };
