import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatListModule,
  MatStepperModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatInputModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatDialogRef,
  
} from '@angular/material';

import { NgxMaskModule } from 'ngx-mask';
import { NotifierModule } from 'angular-notifier';
import { EscolasComponent } from './escolas.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatStepperModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      }
    }),
    NgxMaskModule.forRoot()
  ],
  declarations: [
    EscolasComponent
  ],
  exports: [
    EscolasComponent
  ],
  entryComponents: [
    EscolasComponent
  ],
  providers: [
    {provide: MatDialogRef, useValue: {}},
  ]
})
export class EscolasModule { }
