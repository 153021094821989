<nav class="navbar navbar-expand-lg navbar-light bg-light" id="navbar">
  <div class="container">
    <a class="navbar-brand header-brand" routerLink="/">
      <img src="assets/img/marca_horizontal_.png" alt="NoBully" />
    </a>

    <button
      class="navbar-toggler"
      (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse"
      [ngClass]="{ 'show': navbarOpen }">
      <ul class="navbar-nav mr-auto"></ul>

      <span class="navbar-text">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item"><a class="nav-link" routerLink="/">Início</a></li>
          <li class="nav-item"><a class="nav-link"  routerLink="/bullying">Bullying</a></li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/sale" routerLinkActive="active" role="button"  aria-haspopup="true" aria-expanded="false">
              Serviços
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Colabore
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/partner" routerLinkActive="active">Parcerias</a>
              <a class="dropdown-item" routerLink="/donation" routerLinkActive="active">Doação</a>
            </div>
          </li>
          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Login
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" routerLink="/login" routerLinkActive="active">Entrar</a>
                <a class="dropdown-item" routerLink="/cadastro" routerLinkActive="active">Cadastre-se</a>
              </div>
            </li>
        </ul>
      </span>
    </div>
  </div>
</nav>

