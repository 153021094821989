import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard, PermissionGuard } from './service.service';
import { LoginAdmComponent } from './login/login-adm/login-adm.component';
import { PainelAdminComponent } from './painel-admin/painel-admin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SalePageComponent } from './site-layout/pages/sale-page/sale-page.component';
import { CadastroEscolaComponent } from './escola/cadastro-escola/cadastro-escola.component';
import { DonationPageComponent } from './site-layout/pages/donation-page/donation-page.component';
import { PartnerPageComponent } from './site-layout/pages/partner-page/partner-page.component';
import { InicioComponent } from './site-layout/pages/inicio/inicio.component';
import { BullyingComponent } from './site-layout/pages/bullying/bullying.component';
import { CheckoutPagseguroComponent } from './site-layout/checkout-pagseguro/checkout-pagseguro.component';

const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: LoginAdmComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'cadastro', component: CadastroEscolaComponent },
  { path: 'painel', component: PainelAdminComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: 'sale', component: SalePageComponent },
  { path: 'donation', component: DonationPageComponent },
  { path: 'partner', component: PartnerPageComponent },
  { path: 'bullying', component: BullyingComponent },
  { path: 'checkout/:page', component: CheckoutPagseguroComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  LoginComponent,
  HomeComponent,
  NotFoundComponent,
  LoginAdmComponent,
  PainelAdminComponent,
  CadastroEscolaComponent,
  CheckoutPagseguroComponent];
