import { Component, OnInit, HostListener } from '@angular/core';
import ScrollReveal from 'scrollreveal';
import ModalVideo from 'modal-video';
import { PageSeguroService } from '../../../provider/pagseguro.service';
import { ServiceService } from '../../../service.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ModalPagseguroComponent } from './modal-pagseguro/modal-pagseguro.component';
import { environment } from 'src/environments/environment';
import { LoadingComponent } from '../../../utils/loading.component';
import { HttpClient } from '@angular/common/http';


declare let $: any;

@Component({
  selector: 'app-sale-page',
  templateUrl: './sale-page.component.html',
  styleUrls: ['./sale-page.component.css'],
})
export class SalePageComponent implements OnInit {

  planos: any = [];
  MeiosPagamento: any;
  listEscolas: any = [];
  /**
   * Config para Ow-carousel
   */
  public urlJson = 'assets/schools.json';
  public mySlideOptions = {
    items: 4,
    loop: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: true,
    nav: false,
    responsive: {
      0: {
          items: 1,
          nav: true
      },
      600: {
          items: 5,
          nav: false
      },
      1000: {
          items: 5,
          dots: true,
          nav: false,
          loop: true
      }
    }
  };

  constructor(
    private pagSeguro: PageSeguroService,
    private service: ServiceService,
    public dialog: MatDialog,
    private _httpClient: HttpClient) {
    // this.listarPlanos();
    this.listEscolasClientes();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.animations();
    const modalVideo = new ModalVideo('.hero-cta', { channel: 'youtube' });
  }

  listEscolasClientes() {
    this.service.listEscolasByAtivo('S').subscribe(data => {
      this.listEscolas = data.json().data
      .map(element => {
        element.image = 'assets/img/school-default.svg';
        return element;
      });
    }, err => {
      console.log(err);
    });
  }

  iniciarSessionPagSeguro(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.pagSeguro.iniciar('inovappweb@gmail.com',
    environment.pagSeguroToken,
    environment.isSandBox
      ).then(async res => {
        await this.pagSeguro.buscarMeiosDepagamento().then(async result => {
          this.MeiosPagamento = result;
          this.openPagSeguro(item);
          loading.close();
        });
      });
  }

  listarPlanos() {
    this.service.listarPlanos().subscribe(data => {
      this.planos = data.json();
    }, err => {
      console.log(err);
    });
  }

  openPagSeguro(item) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.data = {
      MeiosPagamento: this.MeiosPagamento.paymentMethods,
      planoSelecionado: item
    };
    const dialogRef = this.dialog.open(ModalPagseguroComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //  this.animal = result;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      const element = document.getElementById('navbar');
      element.classList.add('fixed-top');
    } else {
      const element = document.getElementById('navbar');
      element.classList.remove('fixed-top');
    }
  }

  animations() {
    ScrollReveal().reveal('.hero-title, .hero-paragraph, .hero-cta', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'left',
      interval: 150
    });

    ScrollReveal().reveal('.hero-illustration', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'right',
      interval: 150
    });

    ScrollReveal().reveal('.feature', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      interval: 100,
      origin: 'bottom',
      scale: 0.9,
      viewFactor: 0.5
    });

    ScrollReveal().reveal('.topics', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      interval: 100,
      origin: 'bottom',
      scale: 0.9,
      viewFactor: 0.5
    });

    ScrollReveal().reveal('.feature-extended-image', {
      duration: 600,
      scale: 0.9,
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      viewFactor: 0.5
    });

    const pricingTables = document.querySelectorAll('.pricing-table');

    pricingTables.forEach(pricingTable => {
      const pricingTableHeader = [].slice.call(pricingTable.querySelectorAll('.pricing-table-header'))
      const pricingTableList = [].slice.call(pricingTable.querySelectorAll('.pricing-table-features li'))
      const pricingTableCta = [].slice.call(pricingTable.querySelectorAll('.pricing-table-cta'))
      const elements = pricingTableHeader.concat(pricingTableList).concat(pricingTableCta)

      ScrollReveal().reveal(elements, {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        interval: 100,
        origin: 'bottom',
        viewFactor: 0.5
      });
    });
  }
}
