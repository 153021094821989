import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ServiceService } from 'src/app/service.service';
import { LoadingComponent } from 'src/app/utils/loading.component';
var ɵ0 = MAT_MOMENT_DATE_FORMATS;
var CadastroAlunoComponent = /** @class */ (function () {
    function CadastroAlunoComponent(dialogRef, dialog, formBuilder, notifierService, service) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.service = service;
        this.submitted = false;
        this.isLinear = true;
        this.estados = [
            'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
            'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
            'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
            'SC', 'SP', 'SE', 'TO'
        ];
        this.series = [
            '1º ano Fundamental', '2º ano Fundamental', '3º ano Fundamental', '4º ano Fundamental',
            '5º ano Fundamental', '6º ano Fundamental', '7º ano Fundamental', '8º ano Fundamental',
            '9º ano Fundamental', '1º ano Médio', '2º ano Médio', '3º ano Médio',
        ];
        this.turnos = ['Manhã', 'Tarde', 'Noite', 'Integral'];
        this.turmas = ['A', 'B', 'C', 'D', 'E', 'F'];
        this.notifier = notifierService;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.id_escola = this.user.id_escola;
    }
    CadastroAlunoComponent.prototype.ngOnInit = function () {
        this.firstFormGroup = this.formBuilder.group({
            id_escola: this.id_escola,
            data_nascimento: ['', Validators.required],
            nome_aluno: ['', Validators.required],
            sexo_aluno: ['', Validators.required],
            serie_aluno: ['', Validators.required],
            turno_aluno: ['', Validators],
            turma_aluno: ['', Validators],
            tel_aluno: ['', Validators.required]
        });
        this.secondFormGroup = this.formBuilder.group({
            estado: [''],
            municipio: [''],
            bairro: [''],
            logradouro: [''],
            numero: [''],
            cep: [''],
            complemento: ['']
        });
    };
    CadastroAlunoComponent.prototype.cadastrarAluno = function () {
        var _this = this;
        var loading = this.dialog.open(LoadingComponent, {
            width: '150px',
        });
        var object = {
            usuario: {},
            endereco: this.secondFormGroup.value,
            aluno: this.firstFormGroup.value
        };
        this.service.verificarSeTelefoneAlunoPossuiCadastro(this.firstFormGroup.value.tel_aluno).subscribe(function (data) {
            if (data.json().length > 0) {
                _this.showAlert('warning', 'Este Telefone já possui cadastro! Tente outro número!');
                loading.close();
            }
            else {
                _this.service.cadastrarAluno(object).subscribe(function (res) {
                    _this.showAlert('success', 'Sucesso!!');
                    _this.onNoClick();
                    loading.close();
                }, function (err) {
                    console.log(err);
                    _this.showAlert('error', err);
                    loading.close();
                });
            }
        });
    };
    CadastroAlunoComponent.prototype.showAlert = function (type, message) {
        var _this = this;
        this.notifier.show({
            type: type,
            message: message
        });
        setTimeout(function () {
            _this.notifier.hideOldest();
        }, 3000);
    };
    CadastroAlunoComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return CadastroAlunoComponent;
}());
export { CadastroAlunoComponent };
export { ɵ0 };
