import { LoadingComponent } from 'src/app/utils/loading.component';
import { DashBoardService } from './../../dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-relatos-detalhes',
  templateUrl: './relatos-detalhes.component.html',
  styleUrls: ['./relatos-detalhes.component.css']
})
export class RelatosDetalhesComponent implements OnInit {

  detalhe: any;
  denuncia: any;
  arrayTiposBullying: any = [];
  arraySentimentos: any = [];
  desc_tipo_denuncia: string;
  localBullying: string;
  localBullyingEscola: string;
  relato: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RelatosDetalhesComponent>,
    private serviceDash: DashBoardService,
    public dialog: MatDialog) {
      this.denuncia = this.data.relato;
      this.getDetalhesDenuncia(this.data.relato.id_denuncia);
    }

  ngOnInit() {
  }

  getDetalhesDenuncia(id_denuncia) {
    const loading = this.dialog.open(LoadingComponent, {
      width: '150px',
    });

    this.serviceDash.getDetalhesDenuncia(id_denuncia).subscribe(data => {
      this.detalhe = data.json();
      this.arrayTiposBullying = data.json().tipo_bullying;
      this.arraySentimentos = data.json().sentimento;
      this.desc_tipo_denuncia = data.json().desc_tipo_denuncia;
      this.localBullying =  data.json().desc_local_bullying;
      this.localBullyingEscola = data.json().desc_local_escola;
      this.relato = data.json().relato;
      loading.close();
    }, err => {
      loading.close();
      console.log(err);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
