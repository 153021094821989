import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatStepperModule,
  MatListModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatInputModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatChipsModule,
  MatTabsModule,
  MatRadioModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { RelatosDetalhesComponent } from './relatos-detalhes/relatos-detalhes.component';
import { RelatosComponent } from './relatos.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatStepperModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatRadioModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      }
    }),
  ],
  declarations: [
    RelatosComponent,
    RelatosDetalhesComponent
  ],
  exports: [
    RelatosComponent,
    RelatosDetalhesComponent
  ],
  entryComponents: [
    RelatosComponent,
    RelatosDetalhesComponent
  ]
})
export class RelatosModule { }
