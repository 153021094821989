import { CadastroAlunoComponent } from './cadastro-aluno/cadastro-aluno.component';
import { AlunosComponent } from './alunos.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatStepperModule,
  MatListModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatInputModule,
  MatTooltipModule,
  MatDatepickerModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { NgxMaskModule } from 'ngx-mask';
import { EditarAlunoComponent } from './editar-aluno/editar-aluno.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatStepperModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      }
    }),
    NgxMaskModule.forRoot()
  ],
  declarations: [
    AlunosComponent,
    CadastroAlunoComponent,
    EditarAlunoComponent
  ],
  exports: [
    AlunosComponent,
    CadastroAlunoComponent,
    EditarAlunoComponent
  ],
  entryComponents: [
    AlunosComponent,
    CadastroAlunoComponent,
    EditarAlunoComponent
  ]
})
export class AlunosModule { }
