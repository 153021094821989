import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
// tslint:disable-next-line:import-blacklist
import * as Rx from 'rxjs/Rx';
import { environment } from '../environments/environment';
import { ServiceService } from './service.service';

@Injectable()
export class SocketService {
  private socket;
  private url =  ServiceService.URL;
  user = JSON.parse(localStorage.getItem('user'));
  id_escola: number;
  constructor() {
    this.id_escola = this.user.id_escola;
  }

  connect(): Rx.Subject<MessageEvent> {
    // If you aren't familiar with environment variables then
    // you can hard code `environment.ws_url` as `http://localhost:5000`
    this.socket = io(this.url);

    // We define our observable which will observe any incoming messages
    // from our socket.io server.
    // tslint:disable-next-line:no-shadowed-variable
    const observable = new Observable((observer: any) => {
      this.socket.on(`${this.id_escola}`, (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    const observer = {
      next: (data: Object) => {
        this.socket.emit('message', JSON.stringify(data));
      },
    };

    // we return our Rx.Subject which is a combination
    // of both an observer and observable.
    return Rx.Subject.create(observer, observable);
  }
}
