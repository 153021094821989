import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { LoadingComponent } from 'src/app/utils/loading.component';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-email-dialog',
  template: ` <h4>Esqueceu sua senha?</h4>
              <p>Esqueceu sua senha? Insira seu endereço de e-mail abaixo. Nós enviaremos para o seu e-mail.</p>
              <form [formGroup]="emailFormGroup" (ngSubmit)="enviarEmailRecuperarSenhaEscola()" #emailForm="ngForm">
                <mat-form-field style="width: 100%;">
                  <input type="text" matInput placeholder="Email cadastrado" formControlName="email" required>
                </mat-form-field>
                <button mat-raised-button color="primary" [disabled]="!emailForm.form.valid">Enviar</button>
                <button mat-stroked-button color="primary" (click)="onNoClick(); $event.preventDefault()">Fechar</button>
              </form>
            `,
  styleUrls: ['./login.component.css']
})
export class EmailDialogComponent implements OnInit {

  emailFormGroup: FormGroup;
  notifier: NotifierService;

  constructor(
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private service: ServiceService) {
      this.notifier = notifierService;
      this.emailFormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
      }
    );
  }

  ngOnInit() {
  }

  enviarEmailRecuperarSenhaEscola() {
    const loading = this.dialog.open(LoadingComponent, {
      width: '150px',
    });

    this.service.enviarEmailRecuperarSenhaEscola(this.emailFormGroup.value.email).subscribe(res => {
      loading.close();
      if (JSON.parse(res.json()).type === 1) {
        this.showAlert('success', JSON.parse(res.json()).mensagem);
        this.onNoClick();
      } else {
        this.showAlert('warning', JSON.parse(res.json()).mensagem);
      }
    }, err => {
      console.log(err);
      this.showAlert('error', err);
      loading.close();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 5000);
  }
}

