import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../../utils/MatchPassword';
import { EmailValidation } from '../../utils/EmailValidation';
import { ServiceService } from '../../service.service';
import { NotifierService } from 'angular-notifier';
import { LoadingComponent } from '../../utils/loading.component';

import ScrollReveal from 'scrollreveal';
import ModalVideo from 'modal-video';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-cadastro-escola',
  templateUrl: './cadastro-escola.component.html',
  styleUrls: ['./cadastro-escola.component.css']
})
export class CadastroEscolaComponent implements OnInit {

  submitted = false;

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  estados: string[] = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
    'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
    'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
    'SC', 'SP', 'SE', 'TO'
  ];
  list_tipo_escola: string[] = [
    'Pública', 'Privada'
  ];

  notifier: NotifierService;
  termo_de_uso: boolean;
  salvo: boolean;

  constructor(
    public dialogRef: MatDialogRef<CadastroEscolaComponent>,
    // @Inject(MAT_DIALOG_DATA)
    // public data: DialogData,
    private formBuilder: FormBuilder,
    private service: ServiceService,
    notifierService: NotifierService,
    public dialog: MatDialog,
    private router: Router) {
    this.notifier = notifierService;
  }

  ngOnInit() {

    this.firstFormGroup = this.formBuilder.group({
      nome_escola: ['', Validators.required],
      telefone: ['', Validators.required],
      cnpj: ['', Validators.required],
      tipo_escola: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      senha: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    },
      {
        validator: PasswordValidation.MatchPassword
        // validator: [PasswordValidation.MatchPassword, EmailValidation.EmailExisting]// your validation method
      }
    );
    this.secondFormGroup = this.formBuilder.group({
      estado: ['', Validators.required],
      municipio: ['', Validators.required],
      bairro: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      cep: [''],
      complemento: ['']
    });

    this.animations();
    const modalVideo = new ModalVideo('.hero-cta', {channel: 'youtube'});
  }

  cadastrarUsuarioEscola() {
    const loading = this.dialog.open(LoadingComponent, {
      width: '150px',
    });

    const object = {
      usuario: {
        email: this.firstFormGroup.value.email,
        senha: this.firstFormGroup.value.senha,
      },
      endereco: this.secondFormGroup.value,
      escola: {
        nome_escola: this.firstFormGroup.value.nome_escola,
        telefone: this.firstFormGroup.value.telefone,
        cnpj: this.firstFormGroup.value.cnpj,
        tipo_escola: this.firstFormGroup.value.tipo_escola,
        ativo: 'N'
      }
    };

    this.service.validarEmailUsuario(this.firstFormGroup.value.email).subscribe(data => {
      if (data.json().length === 0) {

        this.service.cadastrarUsuarioEscola(object).subscribe(res => {
          this.showAlert('success', 'Sucesso!!');
          this.service.enviarEmailBemVindoParaUsuario(this.firstFormGroup.value.email)
            .subscribe(result => {
              this.showAlert('success', 'Seja Bem Vindo!!');
            }, error => {
              this.showAlert('error', error);
              loading.close();
            });

          this.service.mensagemEmailEscolaCadastrada({
            email: 'appnobully@gmail.com',
            escola: {
              email: object.usuario.email,
              nome: object.escola.nome_escola
            }
          }).subscribe((ob) => { }, error => {
            this.showAlert('error', error);
            loading.close();
          });

          // this.onNoClick();
          // if (object.escola.tipo_escola = 'Privada') {
          //   this.router.navigate(['/sale']);
          // }

          // if (object.escola.tipo_escola = 'Pública') {
          //   this.router.navigate(['/adoption']);
          // }

          this.salvo = true;
          loading.close();
        }, err => {
          console.log(err);
          this.showAlert('error', err);
          loading.close();
        });

      } else {
        this.showAlert('warning', 'Este E-mail já possui cadastro');
        loading.close();
      }
    }, err => {
      console.log(err);
      this.showAlert('error', err);
      loading.close();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }

  //Animation

  animations() {
    ScrollReveal().reveal('.hero-title, .hero-paragraph, .hero-cta', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'left',
      interval: 150
    });

    ScrollReveal().reveal('.hero-illustration', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'right',
      interval: 150
    });

    ScrollReveal().reveal('.feature', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      interval: 100,
      origin: 'bottom',
      scale: 0.9,
      viewFactor: 0.5
    });

    ScrollReveal().reveal('.feature-extended-image', {
      duration: 600,
      scale: 0.9,
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      viewFactor: 0.5
    });

    const pricingTables = document.querySelectorAll('.pricing-table');

    pricingTables.forEach(pricingTable => {
      const pricingTableHeader = [].slice.call(pricingTable.querySelectorAll('.pricing-table-header'))
      const pricingTableList = [].slice.call(pricingTable.querySelectorAll('.pricing-table-features li'))
      const pricingTableCta = [].slice.call(pricingTable.querySelectorAll('.pricing-table-cta'))
      const elements = pricingTableHeader.concat(pricingTableList).concat(pricingTableCta)

      ScrollReveal().reveal(elements, {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        interval: 100,
        origin: 'bottom',
        viewFactor: 0.5
      });
    });
  }
}
