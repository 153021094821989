<notifier-container></notifier-container>

<div class="relatos">
  <mat-card class="card">
    <div>
      <table style="width:100%;">
        <tr *ngFor="let item of listRelatos">
          <div class="media">
            <td class="pull-left">
              <img src="assets/img/boy.png" class="media-photo" *ngIf="!item.anonimo">
              <img src="assets/img/user.png" class="media-photo" *ngIf="item.anonimo">
            </td>
            <td class="media-body">
              <div>
                <label *ngIf="!item.anonimo" class="title">
                  {{ item.nome_aluno }}
                </label>
                <label *ngIf="item.anonimo" class="title">
                  Anônimo
                </label>
                <p class="summary">{{ item.relato }}</p>
              </div>
            </td>
            <td class="date">
              {{ item.dth_cadastro | date: 'dd/MM/yyyy hh:mm'}} {{ item.hora_denuncia }}
            </td>
            <td>
              <mat-form-field>
                <mat-label>{{ item.status_escola }}</mat-label>
                <mat-select matNativeControl>
                  <mat-option value="RESOLVIDO" (click)="alterarDenunciaStatusEscola('RESOLVIDO', item.id_denuncia)">
                    RESOLVIDO</mat-option>
                  <mat-option value="NÃO RESOLVIDO"
                    (click)="alterarDenunciaStatusEscola('NÃO RESOLVIDO', item.id_denuncia)">NÃO RESOLVIDO</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <button mat-raised-button color="accent" (click)="openDetalhes(item)">Detalhes</button>
            </td>
          </div>
        </tr>
      </table>
    </div>
    <mat-paginator [length]="totalRelatos" [pageSize]="limit" [pageSizeOptions]="[5, 10, 20]"
      (page)="pageEventRelato($event)"></mat-paginator>
  </mat-card>

</div>
