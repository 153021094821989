<!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
    {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
  </button> -->
<notifier-container></notifier-container>
<div class="body-wrap boxed-container">
  <app-menu></app-menu>
  <main>

  <section class="hero">
    <div class="container">
      <div class="hero-inner">
        <div class="hero-copy">
          <h1 class="hero-title h2-mobile mt-0 is-revealing">Fomos feitos para sua escola.</h1>
          <p class="hero-paragraph is-revealing">#Juntos no combate ao Bullying</p>
          <p class="hero-cta is-revealing" data-video-id="n1fjJ6pLrag"><a class="button button-primary button-shadow">Assistir ao vídeo </a></p>
        </div>

        <div class="hero-illustration is-revealing">
          <!-- <img src="assets/img/nobully.svg" alt=""> -->
          <div class="play hero-cta" data-video-id="n1fjJ6pLrag">
            <img src="assets/img/play.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>

    <section style="padding-top: 90px">
      <div class="row">
        <div class="col">
          <div class="container" *ngIf="!salvo">
            <h3 class="mt-0" style="margin-left: 20px">Cadastre sua escola</h3>
            <small class="hero-paragraph" style="margin-left: 20px">#NoBully Na Sua Escola</small>
            <mat-horizontal-stepper [linear]="isLinear" #stepper>
              <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                  <ng-template matStepLabel>Escola</ng-template>
                  <mat-list>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Nome Escola" formControlName="nome_escola" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Telefone" formControlName="telefone" prefix="+55 "
                          mask="(00) 00000-0000" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="CNPJ" formControlName="cnpj" mask="00.000.000/0000-00"
                          required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <mat-select placeholder="Escola" formControlName="tipo_escola" required>
                          <mat-option *ngFor="let tipo_escola of list_tipo_escola" [value]="tipo_escola">{{tipo_escola}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="email" placeholder="Email" formControlName="email" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="password" placeholder="Senha" formControlName="senha" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="password" placeholder="Confirmar Senha" formControlName="confirmPassword"
                          required>
                      </mat-form-field>
                      <div class="alert alert-danger"
                        *ngIf="firstFormGroup.controls.confirmPassword.errors?.MutchPassword">
                        Password not match</div>
                    </mat-list-item>
                    <div>
                      <button mat-button color="primary" (click)="onNoClick()">Fechar</button>
                      <button mat-stroked-button color="primary" matStepperNext>Próximo</button>
                    </div>
                  </mat-list>
                </form>
              </mat-step>
              <mat-step [stepControl]="secondFormGroup">
                <form [formGroup]="secondFormGroup">
                  <ng-template matStepLabel>Endereço</ng-template>
                  <mat-list>
                    <mat-list-item>
                      <mat-form-field>
                        <mat-select placeholder="Estado" formControlName="estado" required>
                          <mat-option>None</mat-option>
                          <mat-option *ngFor="let estado of estados" [value]="estado">{{estado}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Municipio" formControlName="municipio" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Bairro" formControlName="bairro" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Rua" formControlName="logradouro" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Número" formControlName="numero" required>
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Complemento" formControlName="complemento">
                      </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-form-field>
                        <input matInput type="text" placeholder="Cep" formControlName="cep" mask="00000-000">
                      </mat-form-field>
                    </mat-list-item>
                    <div>
                      <button mat-button color="primary" matStepperPrevious>Voltar</button>
                      <button mat-stroked-button color="primary" matStepperNext>Próximo</button>
                    </div>
                  </mat-list>
                </form>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Confirmar</ng-template>
                Confirmar cadastro ?
                <div>
                  <button mat-button color="primary" matStepperPrevious>Voltar</button>
                  <button mat-raised-button color="primary" (click)="cadastrarUsuarioEscola()" [disabled]="!termo_de_uso">Finalizar</button>
                  <div class="alerta atencao" *ngIf="!termo_de_uso" >Você precisa aceitar os termos de uso para finalizar.</div>
                  <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </div>
          <div class="container" *ngIf="salvo">
            <div class="alerta sucesso">Cadastrado com Sucesso!!</div>
          </div>
        </div>
        <div class="col">
          <div class="container">
            <iframe src="assets/pdf/TERMO E CONDIÇÕES DO SISTEMA WEB 17.05.19.pdf"></iframe>
            <mat-checkbox [(ngModel)]="termo_de_uso">Aceitar Termos de Uso</mat-checkbox>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer></app-footer>
</div>
