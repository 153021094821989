import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { LoadingComponent } from 'src/app/utils/loading.component';
import { ServiceService } from 'src/app/service.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-modal-partner',
  templateUrl: './modal-partner.component.html',
  styleUrls: ['./modal-partner.component.css']
})
export class ModalPartnerComponent implements OnInit {

  estados: string[] = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
    'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
    'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
    'SC', 'SP', 'SE', 'TO'
  ];

  isLinear = true;
  editar = false;
  notifier: NotifierService;

  cnpjFormGroup: FormGroup;
  dadosFormGroup: FormGroup;
  enderecoFormGroup: FormGroup;
  qtd_escolas: number = 1;
  valor: number = 2000;

  parceiroJaCadastrado: any = {};
  parceiroDados: any = {};
  parceiroEndereco: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private service: ServiceService,
    public dialogRef: MatDialogRef<ModalPartnerComponent>,
    public dialog: MatDialog,
    notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  ngOnInit() {

    this.cnpjFormGroup = this.formBuilder.group({
      cnpj: ['', Validators.required]
    });

    this.dadosFormGroup = this.formBuilder.group({
      razaosocial: ['', Validators.required],
      nomefantasia: ['', Validators],
      email: ['', [Validators.required, Validators.email]],
      telefone: ['', Validators],
    });

    this.enderecoFormGroup = this.formBuilder.group({
      estado: ['', Validators.required],
      municipio: ['', Validators.required],
      bairro: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      cep: [''],
      complemento: ['']
    });

  }

  addQuantidade() {
    this.qtd_escolas += 1;
    this.valor = 2000 * this.qtd_escolas;
  }

  removeQuantidade() {
    this.qtd_escolas -= 1;
    this.valor = 2000 * this.qtd_escolas;
  }

  finalizar() {
    const content: any = {
      parceiro: {},
      endereco: {},
      parceria_proposta: {}
    };

    content.parceiro = this.dadosFormGroup.value;
    content.parceiro.cnpj = this.cnpjFormGroup.value.cnpj;
    content.endereco = this.enderecoFormGroup.value;
    content.parceria_proposta.qtd_escolas = this.qtd_escolas;
    content.parceria_proposta.valor = this.valor;

    if (this.editar) {
      this.editarParceiro(content);
    } else {
      this.insertParceiro(content);
    }
  }

  insertParceiro(content) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.service.insertParceiro(content).subscribe(res => {
      loading.close();
      this.showAlert('success', 'Enviamos um E-mail para você!!');
      this.onNoClick();
    }, err => {
      loading.close();
      this.showAlert('error', err);
      console.log(err);
    });
  }

  editarParceiro(content) {
    content.ids = {
      id_parceiro: this.parceiroJaCadastrado.id_parceiro,
      id_endereco: this.parceiroJaCadastrado.id_endereco
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);
    this.service.editarParceiro(content).subscribe(res => {
      loading.close();
      this.showAlert('success', 'Enviamos um E-mail para você!!');
      this.onNoClick();
    }, err => {
      loading.close();
      this.showAlert('error', err);
      console.log(err);
    });
  }

  findParceiroByCNPJ() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.service.findParceiroByCNPJ(this.cnpjFormGroup.value.cnpj).subscribe(data => {
      loading.close();
      if (data.json().length !== 0) {
        this.editar = true;
        this.sanitizeDados(data.json()[0]);
        this.sanitizeEndereco(data.json()[0]);
        this.parceiroJaCadastrado.cnpj = data.json()[0].cnpj;
        this.parceiroJaCadastrado.id_parceiro = data.json()[0].id_parceiro;
        this.parceiroJaCadastrado.id_endereco = data.json()[0].id_endereco;
      } else {
        this.editar = false;
        this.parceiroDados = {};
        this.parceiroEndereco = {};
        this.dadosFormGroup.reset();
        this.enderecoFormGroup.reset();
      }
    }, err => {
      loading.close();
      console.log(err);
    });
  }

  sanitizeDados(dados) {
    delete dados.id_parceiro;
    delete dados.id_endereco;
    delete dados.dth_cadastro;
    delete dados.cnpj;
    delete dados.cep;
    delete dados.estado;
    delete dados.municipio;
    delete dados.bairro;
    delete dados.logradouro;
    delete dados.complemento;
    delete dados.numero;
    this.parceiroDados = dados;
    this.dadosFormGroup.setValue(dados);
    return dados;
  }

  sanitizeEndereco(dados) {
    delete dados.id_parceiro;
    delete dados.id_endereco;
    delete dados.dth_cadastro;
    delete dados.cnpj;
    delete dados.razaosocial;
    delete dados.nomefantasia;
    delete dados.email;
    delete dados.telefone;
    this.parceiroEndereco = dados;
    this.enderecoFormGroup.setValue(dados);
    return dados;
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 6000);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
