import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DashBoardService } from '../dashboard/dashboard.service';

declare var $: any;

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent implements OnInit {

  page = 'Dashboard';
  user: any;
  id_escola: number;
  listDenuncias: any = [];
  newNotification: boolean = false;
  totalDenuncias: number;

  skip: number = 0;
  limit: number = 10;

  emergencias: Array<any> = JSON.parse(localStorage.getItem('emergencias'));

  countEmergencias: number;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private serviceDash: DashBoardService) {
    // tslint:disable-next-line:triple-equals
    if (this.emergencias != null && this.emergencias != undefined) {
      this.countEmergencias = this.emergencias.length;
    }

    this.user = JSON.parse(localStorage.getItem('user'));
    this.id_escola = this.user.id_escola;
  }

  ngOnInit() {
    this.addActive();
    // $('.main').slimScroll({
    //   color: '#e2e2e2',
    //   size: '5px',
    //   height: 'auto',
    //   alwaysVisible: false
    // });

    this.serviceDash.emergencys.subscribe(msg => {
      setTimeout(() => {
        this.emergencias = JSON.parse(localStorage.getItem('emergencias'));
        this.countEmergencias = this.emergencias.length;
      }, 500);
      this.newNotification = true;
      // this.getDenunciasEscolas(this.id_escola);
    });
  }

  setPage(page) {
    this.page = page;
  }

  addActive() {
    // tslint:disable-next-line:prefer-const
    let btnContainer = document.getElementById('list');

    // tslint:disable-next-line:prefer-const
    let btns = btnContainer.getElementsByClassName('btn');

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function () {
        // tslint:disable-next-line:prefer-const
        let current = document.getElementsByClassName('active');
        current[0].className = current[0].className.replace(' active', '');
        this.className += ' active';
      });
    }

  }

  openNotifications() {
    this.getDenunciasEscolas(this.id_escola);
  }

  getDenunciasEscolas(id_escola) {
    this.serviceDash.getDenunciasEscolas(id_escola, this.skip, this.limit).subscribe(data => {
      this.listDenuncias = data.json().data;
      this.totalDenuncias = data.json().total;
      if (localStorage.getItem('totalDenuncias') == null
        || localStorage.getItem('totalDenuncias') == undefined) {
        localStorage.setItem('totalDenuncias', JSON.stringify(this.totalDenuncias));
        this.newNotification = false;
      } else {
        if (this.totalDenuncias == parseInt(localStorage.getItem('totalDenuncias'))) {
          this.newNotification = false;
        } else {
          this.newNotification = true;
          localStorage.setItem('totalDenuncias', JSON.stringify(this.totalDenuncias));
        }
      }
    }, err => {
      console.log(err);
    })
  }

  onScroll() {
    console.log('scrolled!!');
    this.skip = this.skip + this.limit;
    // this.limit = this.limit * 2
    this.serviceDash.getDenunciasEscolas(this.id_escola, this.skip, this.limit).subscribe(data => {
      if (data.json().data.length > 0) {
        for (let item of data.json().data) {
          this.listDenuncias.push(item);
        }
      }
    }, err => {
      console.log(err);
    })
  }

  logoff() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
