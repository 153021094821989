<body class="is-boxed has-animations">
  <div class="container" *ngIf="tipo === 'donation'">
    <mat-card style="margin-top: 5%;">

      <div class="termos">
        <h6>TERMO DE DOAÇÃO</h6>

        <p>
          DONATÁRIA: INOVAÇÕES EM APLICATIVOS SOCIEDADE LTDA, constituída legalmente por contrato social devidamente
          arquivado na Junta Comercial do Estado de Alagoas, sob NIRE nº 27200642408, com sede na Rua da Mãe Rainha, 89,
          Serraria, Maceió – AL, CEP 57046-302, inscrita no Cadastro Nacional de Pessoa Jurídica/MF sob o nº
          30.877.208/0001-54.
        </p>

        <p>
          I - Por meio deste termo o doador manifesta sua vontade de doar para a empresa Inovações em Aplicativos
          Sociedade Ltda. quantia em dinheiro cuja utilização estará vinculada ao serviço de implantação dos aplicativos
          NO BULLY - ALUNO e NO BULLY GESTÃO, assim como do Sistema WEB a eles relacionados, em escolas públicas
          cadastradas no site www.nobully.com.br.
        </p>

        <p>
          II - A donatária, pelo presente termo, manifesta sua vontade de aceitar a doação e obrigar-se nos termos
          acima.
        </p>

        <p>
          III - O doador fica desde logo ciente de que a doação por ele realizada poderá ser destinada a quaisquer
          medidas e ações relacionadas ao fim social do serviço prestado (prevenção e combate ao bullying em escolas),
          podendo a donatária ainda aplicar os recursos em contratação de pessoal, publicidade, pagamento de tributos e
          quaisquer outros custos necessários à plena realização do objetivo acima referido, além de poder destinar
          parte da doação para investimentos e criação de outros projetos com fim social e converter parcela dos
          recursos em lucro a ser objeto de distribuição entre os sócios, haja vista se tratar a donatária de sociedade
          empresarial com fins lucrativos.
        </p>

        <p>
          IV - O donatário não estará sujeito a prazo para a aplicação da doação na finalidade para a qual esta foi
          realizada, renunciando o doador, antecipadamente, o seu direito de revogar a liberalidade por inexecução do
          encargo assumido por aquele.
        </p>

        <p>
          Maceió, dia, mês e ano.
          Inovações em Aplicativos Sociedade Ltda.
        </p>

      </div>

      <mat-checkbox [(ngModel)]="checkedTerms">Aceitar Termos</mat-checkbox>

      <div style="margin-top: 5%">
        <div>
          <label for="amount">Valor da Doação</label>
          <div class="flex">
            <span class="currency">R$:</span>
            <input currencyMask id="amount" name="amount" type="text" [(ngModel)]="valor"
              [options]="{ prefix: '', thousands: '.', decimal: ',' }" autocomplete="off" maxlength="10" />
          </div>
          <p *ngIf="!valor" style="color: rgb(138, 121, 121); font-size: 15px; position: fixed;">Insira um valor</p>
        </div>
        <button type="submit" class="mainActionButton pagseguro-button" *ngIf="validator()"
          (click)="pagSeguroRedirect(1, 'Donation')">Avançar</button>

        <button type="submit" class="mainActionButtonDisabled pagseguro-button" *ngIf="!validator()">Avançar</button>
      </div>

    </mat-card>
  </div>

  <div class="container" *ngIf="tipo === 'partner'">
    <mat-card style="margin-top: 5%;">
      <div class="termos">
        <h6></h6>
      </div>

      <mat-checkbox [(ngModel)]="checkedTerms">Aceitar Termos</mat-checkbox>

      <div style="margin-top: 5%">
        <label for="amount">Valor por escola R$: 2.000,00</label>
        <div>
          <label>Quantas escolas você deseja patrocinar ?</label>
          <button mat-mini-fab (click)="removeQuantidade()" [disabled]="quantidade == 1"> - </button>
          {{ quantidade }}
          <button mat-mini-fab (click)="addQuantidade()"> + </button>
        </div>
        <button type="submit" class="mainActionButton pagseguro-button" *ngIf="validator()"
          (click)="pagSeguroRedirect(quantidade, 'Partner')">Avançar</button>

        <button type="submit" class="mainActionButtonDisabled pagseguro-button" *ngIf="!validator()">Avançar</button>
      </div>

    </mat-card>
  </div>

  <form id="pagar" action="" mathod="post" onsubmit="PagSeguroLightbox(this); return false; ">
    <input type="hidden" name="code" id="code" value="">
  </form>

</body>
