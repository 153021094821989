import { Http } from '@angular/http';
import { ServiceService } from '../service.service';
import { SocketService } from '../socket.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../socket.service";
var DashBoardService = /** @class */ (function () {
    function DashBoardService(http, sockService) {
        this.http = http;
        this.sockService = sockService;
        this.emergencys = sockService
            .connect()
            .map(function (response) {
            return response;
        });
        this.Headers = new Headers();
        this.Headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.Headers.append('Content-Type', 'application/json');
        this.Headers.append('Authorization', 'Auth_Token');
        this.Headers.append('RequestToken', localStorage.getItem('token'));
        this.headers = { headers: this.Headers };
    }
    DashBoardService.prototype.countAlunos = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/countAlunos/id_escola/" + id_escola);
    };
    DashBoardService.prototype.countDenunciasParaEscola = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/countDenunciasParaEscola/id_escola/" + id_escola);
    };
    DashBoardService.prototype.tiposDenunciasEscola = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/tiposDenunciasEscola/id_escola/" + id_escola);
    };
    DashBoardService.prototype.countLocalBullying = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/countLocalBullying/id_escola/" + id_escola);
    };
    DashBoardService.prototype.countLocalBullyingEscola = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/countLocalBullyingEscola/id_escola/" + id_escola);
    };
    // findRelatosByEscola(id_escola, skip, limit) {
    //   return this.http.get(`${ServiceService.URL}/dashboard/relatos/escola/${id_escola}/?skip=${skip}&limit=${limit}`);
    // }
    DashBoardService.prototype.findRelatosByEscola = function (id_escola, skip, limit) {
        return this.http.get(ServiceService.URL + ("/denuncia/relatos/escola/" + id_escola + "/?skip=" + skip + "&limit=" + limit));
    };
    DashBoardService.prototype.countTiposDeBullingByEscola = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/countTiposDeBullingByEscola/id_escola/" + id_escola);
    };
    DashBoardService.prototype.getDetalhesDenuncia = function (id_denuncia) {
        return this.http.get(ServiceService.URL + "/denuncia/detalhe/" + id_denuncia);
    };
    DashBoardService.prototype.countDenunciasEscolaPorSerie = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/countDenunciasEscolaPorSerie/id_escola/" + id_escola);
    };
    DashBoardService.prototype.countDenunciasEscolaPorIdade = function (id_escola) {
        return this.http.get(ServiceService.URL + "/dashboard/countDenunciasEscolaPorIdade/id_escola/" + id_escola);
    };
    DashBoardService.prototype.tipoBullyingByDataAndEscola = function (object) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(ServiceService.URL + "/dashboard/tiposDeBullyingPorData/dt_inicial/" + object.dt_inicial + "/dt_final/" + object.dt_final + "/id_escola/" + object.id_escola);
    };
    DashBoardService.prototype.getDenunciasEscolas = function (id_escola, skip, limit) {
        return this.http.get(ServiceService.URL + "/denuncia/emergencia/escola/" + id_escola + "/?skip=" + skip + "&limit=" + limit);
    };
    DashBoardService.prototype.countDenunciasEscolaEmergencia = function (id_escola) {
        return this.http.get(ServiceService.URL + "/denuncia/emergencia/escola/" + id_escola + "/count/");
    };
    DashBoardService.ngInjectableDef = i0.defineInjectable({ factory: function DashBoardService_Factory() { return new DashBoardService(i0.inject(i1.Http), i0.inject(i2.SocketService)); }, token: DashBoardService, providedIn: "root" });
    return DashBoardService;
}());
export { DashBoardService };
