import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../utils/MatchPassword';
import { EmailValidation } from '../utils/EmailValidation';
import { ServiceService } from '../service.service';
import { NotifierService } from 'angular-notifier';
import { LoadingComponent } from '../utils/loading.component';
import { Router } from '@angular/router';


interface Escola {
  dth_cadastro: any;
  id_endereco: number;
  id_escola: number;
  id_usuario: number;
  nome_escola: string;
  telefone: string;
}

interface Usuario {
  email: string;
  id_tipo_usuario: number;
  id_usuario: number;
  login: any;
  senha;
}

interface Endereco {
  bairro: string;
  cep: string;
  complemento: string;
  estado: string;
  id_endereco: number;
  logradouro: string;
  municipio: string;
  numero: string;
}


@Component({
  selector: 'app-escola',
  templateUrl: './escola.component.html',
  styleUrls: ['./escola.component.css']
})
export class EscolaComponent implements OnInit {

  submitted = false;

  editar: Boolean = false;

  public formDisabled = true;

  escola: Escola = {
    dth_cadastro: null,
    id_endereco: null,
    id_usuario: null,
    id_escola: null,
    nome_escola: '',
    telefone: ''
  };
  usuario: Usuario = {
    email: '',
    id_tipo_usuario: null,
    id_usuario: null,
    login: null,
    senha: ''
  };
  endereco: Endereco = {
    bairro: '',
    cep: '',
    complemento: '',
    estado: '',
    id_endereco: null,
    logradouro: '',
    municipio: '',
    numero: ''
  };

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  estados: string[] = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
    'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
    'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
    'SC', 'SP', 'SE', 'TO'
  ];
  notifier: NotifierService;

  constructor(
    public dialogRef: MatDialogRef<EscolaComponent>,
    // @Inject(MAT_DIALOG_DATA)
    // public data: DialogData,
    private formBuilder: FormBuilder,
    private service: ServiceService,
    notifierService: NotifierService,
    public dialog: MatDialog,
    private router: Router) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getEscolaById();
    this.verificaEscolaAtiva(JSON.parse(localStorage.getItem('user')).id_escola);

    this.firstFormGroup = this.formBuilder.group({
      nome_escola: [{ value: '', disabled: this.formDisabled }, Validators.required],
      telefone: [{ value: '', disabled: this.formDisabled }, Validators.required],
      email: [{ value: '', disabled: this.formDisabled }, [Validators.required, Validators.email]],
      senha: [{ value: '', disabled: this.formDisabled }, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [{ value: '', disabled: this.formDisabled }, [Validators.required, Validators.minLength(6)]]
    },
      {
        validator: PasswordValidation.MatchPassword
        // validator: [PasswordValidation.MatchPassword, EmailValidation.EmailExisting]// your validation method
      }
    );
    this.secondFormGroup = this.formBuilder.group({
      estado: [{ value: '', disabled: this.formDisabled }, Validators.required],
      municipio: [{ value: '', disabled: this.formDisabled }, Validators.required],
      bairro: [{ value: '', disabled: this.formDisabled }, Validators.required],
      logradouro: [{ value: '', disabled: this.formDisabled }, Validators.required],
      numero: [{ value: '', disabled: this.formDisabled }, Validators.required],
      cep: [{ value: '', disabled: this.formDisabled }],
      complemento: [{ value: '', disabled: this.formDisabled }]
    });
  }

  async getEscolaById() {
    await this.service.getEscolaById(JSON.parse(localStorage.getItem('user')).id_escola)
      .subscribe(res => {
        console.log(res.json());
        this.escola = res.json().escola;
        this.usuario = res.json().usuario;
        this.endereco = res.json().endereco;

        this.firstFormGroup.setValue({
          nome_escola: this.escola.nome_escola,
          telefone: this.escola.telefone,
          email: this.usuario.email,
          senha: this.usuario.senha,
          confirmPassword: this.usuario.senha
        });

        this.secondFormGroup.setValue({
          estado: this.endereco.estado,
          municipio: this.endereco.municipio,
          bairro: this.endereco.bairro,
          logradouro: this.endereco.logradouro,
          numero: this.endereco.numero,
          cep: this.endereco.cep,
          complemento: this.endereco.complemento
        });
      });
  }


  activateEdit() {
    this.editar ? this.editar = false : this.editar = true;
    this.formDisabled = !this.formDisabled;
    const state = this.formDisabled ? 'disable' : 'enable';
    Object.keys(this.firstFormGroup.controls).forEach((controlName) => {
      this.firstFormGroup.controls[controlName][state]();
    });
    Object.keys(this.secondFormGroup.controls).forEach((controlName) => {
      this.secondFormGroup.controls[controlName][state]();
    });
  }

  verifyEdit() {
    const loading = this.dialog.open(LoadingComponent, {
      width: '150px',
    });

    this.usuario.email = this.firstFormGroup.controls.email.value;
    this.usuario.senha = this.firstFormGroup.controls.senha.value;

    this.endereco = this.secondFormGroup.value;

    this.escola.nome_escola = this.firstFormGroup.controls.nome_escola.value;
    this.escola.telefone = this.firstFormGroup.controls.telefone.value;

    const objEscola = {
      usuario: this.usuario,
      endereco: this.endereco,
      escola: this.escola,
    };

    const obj = {
      ids: {
        id_escola: this.escola.id_escola,
        id_endereco: this.escola.id_endereco,
        id_usuario: this.escola.id_usuario
      },
      escola: objEscola
    };

    const user = JSON.parse(localStorage.getItem('user'));

    const objLocalStorage = {
      email: objEscola.usuario.email,
      id_escola: user.id_escola,
      id_tipo_usuario: user.id_tipo_usuario,
      id_usuario: user.id_usuario
    };

    if (user.email !== obj.escola.usuario.email) {
      this.service.validarEmailUsuario(obj.escola.usuario.email)
        .subscribe(res => {
          if (res.json().length > 0) {
            this.showAlert('warning', 'Este e-mail já está sendo utilizado por outro usuário!');
            loading.close();
          } else {
            this.editEscola(obj, loading);
            this.setLocalStorage(objLocalStorage);
          }
        });
    } else {
      this.editEscola(obj, loading);
      this.setLocalStorage(objLocalStorage);
    }

  }

  editEscola(obj, loading) {
    this.service.editEscola(obj).subscribe(r => {
      console.log(r);
      this.showAlert('success', 'Sucesso!!');
      this.activateEdit();
      loading.close();
    }, err => {
      console.log(err);
      this.showAlert('error', err);
      loading.close();
    });
  }

  setLocalStorage(obj) {
    localStorage.setItem('user', JSON.stringify(obj));
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }

  verificaEscolaAtiva(id_escola) {
    this.service.verificaEscolaAtiva(id_escola).subscribe(data => {
      if (data.json().ativo == 'N') {
        localStorage.clear();
        this.router.navigate(['/']);
      }
    });
  }
}
