import { Component, OnInit } from '@angular/core';
import { PageSeguroService } from 'src/app/provider/pagseguro.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingComponent } from '../../../../utils/loading.component';
import { MatDialog, MatDialogConfig } from '@angular/material';

declare var PagSeguroDirectPayment: any;

@Component({
  selector: 'app-modal-pagseguro',
  templateUrl: './modal-pagseguro.component.html',
  styleUrls: ['./modal-pagseguro.component.css']
})
export class ModalPagseguroComponent implements OnInit {

  pagSeguro: PageSeguroService;

  isLinear = true;
  dadosPessoaisFormGroup: FormGroup;
  formaDePagamentoFormGroup: FormGroup;
  cartaoDeCreditoFormGroup: FormGroup;
  imprimirBoletoFormGroup: FormGroup;

  BOLETO: any = {
    name: '',
    options: {}
  };
  linkBaixarBoleto: string;

  formaDePagamentoSelecionado: string;
  brandName: string = null;
  parcelas: any = [];
  parcela: any;
  tokenCard: any;
  planoSelecionado: any;
  valorReal: number;

  constructor(
    public dialogRef: MatDialogRef<ModalPagseguroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pagSeguroService: PageSeguroService,
    private datepipe: DatePipe,
    public dialog: MatDialog) {
    this.pagSeguro = this.pagSeguroService;
    this.BOLETO.name = data.MeiosPagamento.BOLETO.options.BOLETO.displayName;
    // this.BOLETO.img =  data.MeiosPagamento.BOLETO.options.BOLETO.images.MEDIUM.path
    this.BOLETO.options = data.MeiosPagamento.BOLETO.options;
    this.planoSelecionado = data.planoSelecionado;
    this.valorReal = this.planoSelecionado.valor_plano.toFixed(2);
    this.pagSeguro.dados.itemAmount1 = this.valorReal.toString();
    this.pagSeguro.dados.itemDescription1 = data.planoSelecionado.desc_plano;
  }

  ngOnInit() {
    this.buscaBandeira();
  }

  selecionarFormaDePagamento(forma: string) {
    this.formaDePagamentoSelecionado = forma;
  }

  proseguirFormaDePagamento() {
    if (this.formaDePagamentoSelecionado == 'BOLETO') {
      this.gerarBoleto();
    }
  }

  gerarBoleto() {
    if (this.linkBaixarBoleto == null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '150px';

      const loading = this.dialog.open(LoadingComponent, dialogConfig);

      this.pagSeguroService.gerarBoleto(this.pagSeguro.dados).subscribe(res => {
        this.linkBaixarBoleto = res.paymentLink[0];
        this.abrirBoleto(this.linkBaixarBoleto);
        loading.close();
      }, err => {
        console.log(err);
        loading.close();
      });
    } else {
      this.abrirBoleto(this.linkBaixarBoleto);
    }
  }

  abrirBoleto(url) {
    window.open(url, "_blank");
  }

  buscaBandeira() {
    let qtdCaracteres = this.pagSeguro.dados.numCard.length;
    if (qtdCaracteres >= 6) {
      this.pagSeguroService.buscaBandeira(this.pagSeguro.dados.numCard).then(res => {
        this.brandName = res['brand'].name;
        this.buscaParcelas();
      }, err => {
        console.log(err);
        this.brandName = null;
        this.parcelas = [];
      })
    } else {
      this.brandName = null;
      this.parcelas = [];
    };
  }

  buscaParcelas() {
    this.pagSeguroService.buscaParcelas(this.valorReal, this.brandName).then(res => {
      this.parcelas = res['installments'][this.brandName];
    }, err => {
      this.brandName = null;
      this.parcelas = [];
    });
  }

  parseFloat(valor) {
    return parseFloat(valor);
  }

  finalizarCompra() {
    let numCard = this.pagSeguro.dados.numCard;
    let brand = this.brandName;
    let cvc = this.pagSeguro.dados.codSegCard;
    let mes = this.pagSeguro.dados.mesValidadeCard;
    let ano = this.pagSeguro.dados.anoValidadeCard;
    let paymentMethod = 'creditCard';
    this.pagSeguro.dados.installmentQuantity = this.parcela.quantity;
    this.pagSeguro.dados.installmentValue = this.parcela.installmentAmount.toFixed(2).toString();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.pagSeguroService.finalizarCompra(numCard, brand, cvc, mes, ano, paymentMethod).then(res => {
      console.log('SUSSS', res);
      loading.close();
    }, err => {
      console.log('ERROR', err);
      loading.close();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

