import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import * as jwt from 'jwt-decode';
import { ServiceService } from '../service.service';
import { Observable } from 'rxjs/Observable';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs/Rx';
import { SocketService } from '../socket.service';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {

  private headers: {};
  private Headers: Headers;

  emergencys: Subject<any>;

  constructor(private http: Http, private sockService: SocketService) {

    this.emergencys = <Subject<any>>sockService
      .connect()
      .map((response: any): any => {
        return response;
      });

    this.Headers = new Headers();
    this.Headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.Headers.append('Content-Type', 'application/json');
    this.Headers.append('Authorization', 'Auth_Token');
    this.Headers.append('RequestToken', localStorage.getItem('token'));
    this.headers = { headers: this.Headers };
  }
  countAlunos(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/countAlunos/id_escola/${id_escola}`);
  }

  countDenunciasParaEscola(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/countDenunciasParaEscola/id_escola/${id_escola}`);
  }

  tiposDenunciasEscola(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/tiposDenunciasEscola/id_escola/${id_escola}`);
  }

  countLocalBullying(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/countLocalBullying/id_escola/${id_escola}`);
  }

  countLocalBullyingEscola(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/countLocalBullyingEscola/id_escola/${id_escola}`);
  }

  // findRelatosByEscola(id_escola, skip, limit) {
  //   return this.http.get(`${ServiceService.URL}/dashboard/relatos/escola/${id_escola}/?skip=${skip}&limit=${limit}`);
  // }

  findRelatosByEscola(id_escola, skip, limit) {
    return this.http.get(ServiceService.URL + `/denuncia/relatos/escola/${id_escola}/?skip=${skip}&limit=${limit}`);
  }

  countTiposDeBullingByEscola(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/countTiposDeBullingByEscola/id_escola/${id_escola}`);
  }

  getDetalhesDenuncia(id_denuncia) {
    return this.http.get(`${ServiceService.URL}/denuncia/detalhe/${id_denuncia}`);
  }

  countDenunciasEscolaPorSerie(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/countDenunciasEscolaPorSerie/id_escola/${id_escola}`);
  }

  countDenunciasEscolaPorIdade(id_escola) {
    return this.http.get(`${ServiceService.URL}/dashboard/countDenunciasEscolaPorIdade/id_escola/${id_escola}`);
  }

  tipoBullyingByDataAndEscola(object) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${ServiceService.URL}/dashboard/tiposDeBullyingPorData/dt_inicial/${object.dt_inicial}/dt_final/${object.dt_final}/id_escola/${object.id_escola}`);
  }

  getDenunciasEscolas(id_escola, skip, limit) {
    return this.http.get(`${ServiceService.URL}/denuncia/emergencia/escola/${id_escola}/?skip=${skip}&limit=${limit}`);
  }

  countDenunciasEscolaEmergencia(id_escola) {
    return this.http.get(`${ServiceService.URL}/denuncia/emergencia/escola/${id_escola}/count/`);
  }

}
