import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-painel-admin',
  templateUrl: './painel-admin.component.html',
  styleUrls: ['./painel-admin.component.css']
})
export class PainelAdminComponent implements OnInit {
  page = 'Escolas';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {

  }

  ngOnInit() {
    this.addActive();
  }
  setPage(page) {
    this.page = page;
  }

  addActive() {
    // tslint:disable-next-line:prefer-const
    let btnContainer = document.getElementById('list');

    // tslint:disable-next-line:prefer-const
    let btns = btnContainer.getElementsByClassName('btn');

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function () {
        // tslint:disable-next-line:prefer-const
        let current = document.getElementsByClassName('active');
        current[0].className = current[0].className.replace(' active', '');
        this.className += ' active';
      });
    }

  }

  logoff() {
    localStorage.clear();
    this.router.navigate(['/']);
  }


}
