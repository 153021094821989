<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
    <mat-toolbar><img matCardImage src="assets/img/marca_horizontal_.png"></mat-toolbar>
    <mat-divider></mat-divider>
    <mat-nav-list id="list">
      <!-- <a mat-list-item href="#">DashBoard</a>
      <a mat-list-item href="#">Alunos</a> -->
      <a mat-list-item class="btn active" (click)="setPage('Dashboard')">
        <mat-icon>dashboard</mat-icon> DashBoard
      </a>
      <a mat-list-item class="btn" (click)="setPage('Alunos')">
        <mat-icon>supervisor_account</mat-icon> Alunos
      </a>
      <a mat-list-item class="btn" (click)="setPage('Relatos')">
        <mat-icon>announcement</mat-icon> Relatos
      </a>
      <a mat-list-item class="btn" (click)="setPage('Escola')">
        <mat-icon>school</mat-icon> Escola
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{ page }}</span>
      <span class="spacer"></span>
      <mat-icon class="icon-rigth"></mat-icon>
      <button mat-icon-button (click)="openNotifications()">
        <mat-icon matBadge='{{countEmergencias}}' [matMenuTriggerFor]="notfication" matBadgeColor="warn">
          notifications
        </mat-icon>
        <span *ngIf="newNotification" class="notification"></span>
      </button>
      <mat-menu #notfication="matMenu">
        <mat-list class="scroll-notification" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()" [scrollWindow]="false">
          <mat-list-item *ngFor="let item of listDenuncias" class="media" style="border-bottom: 1px solid #ccc;">
            <!-- <p *ngIf="!item.anonimo">{{ item.nome_aluno }}</p>
            <p *ngIf="item.anonimo">Anônimo</p> -->
            <div class="pull-left">
              <img src="assets/img/boy.png" class="media-photo" *ngIf="!item.anonimo">
              <img src="assets/img/user.png" class="media-photo" *ngIf="item.anonimo">
            </div>
            <div class="media-body">
              <h4 *ngIf="!item.anonimo" class="title">
                {{ item.nome_aluno }}
                <div *ngIf="item.id_tipo_denuncia == 4" class="cancelado">Emergência</div>
                <div class="media-meta pull-right">{{ item.dth_cadastro | date: 'dd/MM/yyyy HH:mm'}} </div>
                <!-- <span class="pull-right pagado">(Pagado)</span> -->
              </h4>
              <h4 *ngIf="item.anonimo" class="title">
                Anônimo
                <div *ngIf="item.id_tipo_denuncia == 4" class="cancelado">Emergência</div>
                <div class="media-meta pull-right">{{ item.dth_cadastro | date: 'dd/MM/yyyy HH:mm'}} </div>
              </h4>
            </div>
            <!-- <mat-divider></mat-divider> -->

          </mat-list-item>
        </mat-list>
      </mat-menu>
      <button mat-icon-button (click)="logoff()">
        <mat-icon>power_settings_new</mat-icon>
      </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div class="main" appScrollSmooth>
      <app-dashboard *ngIf="page == 'Dashboard'"></app-dashboard>
      <app-alunos *ngIf="page == 'Alunos'"></app-alunos>
      <app-escola *ngIf="page == 'Escola'"></app-escola>
      <app-relatos *ngIf="page == 'Relatos'"></app-relatos>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
