import { MatDialog } from '@angular/material';
import { Component, OnInit, HostListener } from '@angular/core';
import ScrollReveal from 'scrollreveal';
import ModalVideo from 'modal-video';

declare let $: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  public mySlideOptions = {
    items: 1,
    loop: false,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: false
      },
      1000: {
        items: 1,
        dots: true,
        nav: false,
        loop: true
      }
    }
  };

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
    // window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;

    this.animations();
    const modalVideo = new ModalVideo('.player-nobullying', { channel: 'youtube' });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      const element = document.getElementById('navbar');
      element.classList.add('fixed-top');
    } else {
      const element = document.getElementById('navbar');
      element.classList.remove('fixed-top');
    }
  }

  animations() {
    ScrollReveal().reveal('.hero-title, .hero-paragraph, .player-nobullying', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'left',
      interval: 150
    });

    ScrollReveal().reveal('.animate-box', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'left',
      interval: 150
    });

    const pricingTables = document.querySelectorAll('.pricing-table');

    pricingTables.forEach(pricingTable => {
      const pricingTableHeader = [].slice.call(pricingTable.querySelectorAll('.pricing-table-header'))
      const pricingTableList = [].slice.call(pricingTable.querySelectorAll('.pricing-table-features li'))
      const pricingTableCta = [].slice.call(pricingTable.querySelectorAll('.pricing-table-cta'))
      const elements = pricingTableHeader.concat(pricingTableList).concat(pricingTableCta)

      ScrollReveal().reveal(elements, {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        interval: 100,
        origin: 'bottom',
        viewFactor: 0.5
      });
    });
  }

}
