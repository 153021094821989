import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';

import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';

@Directive({ selector: '[appScrollSmooth]' })
export class ScrollSmoothDirective implements OnInit, OnDestroy {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    Scrollbar.use(OverscrollPlugin);
    Scrollbar.init(this.el.nativeElement, {
      plugins: {
        overscroll: true,
        effect: 'bounce',
        damping: 0.2,
        renderByPixels: true
      }
    });
  }

  ngOnDestroy() {
    Scrollbar.init(this.el.nativeElement, {
      plugins: {
        overscroll: true,
        effect: 'bounce',
        maxOverscroll: 101,
        damping: 0.2,
        renderByPixels: true
      }
    });
  }

}
