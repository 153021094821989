import { OnInit } from '@angular/core';
import ScrollReveal from 'scrollreveal';
import { PageSeguroService } from 'src/app/provider/pagseguro.service';
var DonationPageComponent = /** @class */ (function () {
    function DonationPageComponent(pagSeguro) {
        this.pagSeguro = pagSeguro;
        /**
         * Config para Ow-carousel
         */
        this.urlJson = 'assets/schools.json';
        this.mySlideImages = [
            {
                'image': 'assets/img/school-default.svg',
                'title': 'Escola Municial Doutor Pompeu'
            },
            {
                'image': 'assets/img/school-default.svg',
                'title': 'Sarmento '
            }
        ];
        this.mySlideOptions = {
            items: 4,
            loop: true,
            center: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            dots: true,
            nav: false,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                600: {
                    items: 5,
                    nav: false
                },
                1000: {
                    items: 5,
                    dots: true,
                    nav: false,
                    loop: true
                }
            }
        };
        var doc = document.documentElement;
        this.urlBackground = 'assets/img/bg-no.jpg';
        doc.classList.remove('no-js');
        doc.classList.add('js');
    }
    DonationPageComponent.prototype.ngOnInit = function () {
        window.scrollTo(0, 0);
        this.makeEffect();
        this.linkChangeTabs();
        this.accordionFaqs();
    };
    DonationPageComponent.prototype.onWindowScroll = function (e) {
        if (window.pageYOffset > 100) {
            var element = document.getElementById('navbar');
            element.classList.add('fixed-top');
        }
        else {
            var element = document.getElementById('navbar');
            element.classList.remove('fixed-top');
        }
    };
    DonationPageComponent.prototype.makeEffect = function () {
        var body = document.querySelector('.has-animations');
        if (body.classList.contains('has-animations')) {
            var sr = ScrollReveal();
            sr.reveal('.hero-title, .donation-paragraph, .hero-cta', {
                duration: 600,
                distance: '20px',
                easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
                origin: 'top',
                interval: 150
            });
            sr.reveal('.topics', {
                duration: 1000,
                distance: '40px',
                easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
                interval: 100,
                origin: 'bottom',
                scale: 0.9,
                viewFactor: 0.5
            });
            sr.reveal('.donation-illustration', {
                duration: 600,
                distance: '40px',
                easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
                origin: 'bottom'
            });
            sr.reveal('.feature, .tabs-links li, .testimonial, .pricing-faqs', {
                duration: 600,
                distance: '40px',
                easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
                interval: 100,
                origin: 'bottom',
                viewFactor: 0.2
            });
            sr.reveal('.feature-extended:nth-child(odd) .feature-extended-image', {
                duration: 600,
                distance: '40px',
                easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
                origin: 'left',
                viewFactor: 0.5
            });
        }
    };
    DonationPageComponent.prototype.linkChangeTabs = function () {
        var tabLinksAll = document.getElementsByClassName('tab-link');
        if (tabLinksAll.length) {
            var _loop_1 = function (i) {
                tabLinksAll[i].addEventListener('click', function (e) {
                    e.preventDefault();
                    var tabLinksContainer = tabLinksAll[i].parentNode.parentNode;
                    var tabPanels = tabLinksContainer.nextElementSibling.getElementsByClassName('tab-panel');
                    var tabLinks = tabLinksContainer.getElementsByClassName('tab-link');
                    // Remove is-active class from all links and panels
                    for (var i_1 = 0; i_1 < tabLinks.length; i_1++) {
                        tabLinks[i_1].classList.remove('is-active');
                    }
                    for (var i_2 = 0; i_2 < tabPanels.length; i_2++) {
                        tabPanels[i_2].classList.remove('is-active');
                    }
                    // Get the ID of panel to display
                    var tabID = this.getAttribute('href');
                    // Add is-active class to matching link and panel
                    tabLinksAll[i].classList.add('is-active');
                    document.querySelector(tabID).classList.add('is-active');
                });
            };
            for (var i = 0; i < tabLinksAll.length; i++) {
                _loop_1(i);
            }
        }
    };
    DonationPageComponent.prototype.accordionFaqs = function () {
        var accordionElement = document.getElementsByClassName('accordion-title');
        if (accordionElement.length) {
            for (var i = 0; i < accordionElement.length; i++) {
                accordionElement[i].addEventListener('click', function () {
                    this.parentNode.classList.toggle('is-open');
                    var panel = this.nextElementSibling;
                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                    }
                    else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                    }
                });
            }
        }
    };
    return DonationPageComponent;
}());
export { DonationPageComponent };
