<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Aluno</ng-template>
      <mat-list>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Nome Aluno" formControlName="nome_aluno" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <!-- <mat-form-field>
            <input matInput [matDatepicker]="myDatepicker" placeholder="Data Nascimento" formControlName="data_nascimento">
            <mat-datepicker #myDatepicker></mat-datepicker>
          </mat-form-field> -->
          <mat-form-field>
              <input matInput [matDatepicker]="picker" placeholder="Data Nascimento" formControlName="data_nascimento">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <mat-select placeholder="Sexo" formControlName="sexo_aluno" required>
              <mat-option value="M">Masculino</mat-option>
              <mat-option value="F">Feminino</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Telefone" formControlName="tel_aluno" prefix="+55 " mask="(00) 00000-0000"
              required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <mat-select placeholder="Série" formControlName="serie_aluno" required>
              <mat-option *ngFor="let item of series" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <mat-select placeholder="Turno" formControlName="turno_aluno">
              <mat-option *ngFor="let item of turnos" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <mat-select placeholder="Turma" formControlName="turma_aluno">
              <mat-option *ngFor="let item of turmas" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>
        <div>
          <button mat-button color="primary" (click)="onNoClick()">Fechar</button>
          <button mat-stroked-button color="primary" matStepperNext>Próximo</button>
        </div>
      </mat-list>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Endereço</ng-template>
      <mat-list>
        <mat-list-item>
          <mat-form-field>
            <mat-select placeholder="Estado" formControlName="estado">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let estado of estados" [value]="estado">{{estado}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Municipio" formControlName="municipio">
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Bairro" formControlName="bairro">
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Rua" formControlName="logradouro">
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Número" formControlName="numero">
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Complemento" formControlName="complemento">
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Cep" formControlName="cep" mask="00000-000">
          </mat-form-field>
        </mat-list-item>
        <div>
          <button mat-button color="primary" matStepperPrevious>Voltar</button>
          <button mat-stroked-button color="primary" matStepperNext>Próximo</button>
        </div>
      </mat-list>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Feito</ng-template>
    Editar?
    <div>
      <button mat-button color="primary" matStepperPrevious>Voltar</button>
      <button mat-raised-button color="primary" (click)="editarAluno()">Finalizar</button>
      <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
    </div>
  </mat-step>
</mat-horizontal-stepper>
