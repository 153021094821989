import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ServiceService } from 'src/app/service.service';
import { LoadingComponent } from 'src/app/utils/loading.component';

@Component({
  selector: 'app-cadastro-aluno',
  templateUrl: './cadastro-aluno.component.html',
  styleUrls: ['./cadastro-aluno.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class CadastroAlunoComponent implements OnInit {

  submitted = false;

  isLinear = true;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  notifier: NotifierService;
  id_escola: number;
  user: any;

  estados: string[] = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES',
    'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
    'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
    'SC', 'SP', 'SE', 'TO'
  ];

  series: string[] = [
    '1º ano Fundamental', '2º ano Fundamental', '3º ano Fundamental', '4º ano Fundamental',
    '5º ano Fundamental', '6º ano Fundamental', '7º ano Fundamental', '8º ano Fundamental',
    '9º ano Fundamental', '1º ano Médio', '2º ano Médio', '3º ano Médio',
  ];

  turnos: string[] = ['Manhã', 'Tarde', 'Noite', 'Integral'];

  turmas: string[] = ['A', 'B', 'C', 'D', 'E', 'F'];

  constructor(
    public dialogRef: MatDialogRef<CadastroAlunoComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private service: ServiceService) {
    this.notifier = notifierService;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.id_escola = this.user.id_escola;
  }

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      id_escola: this.id_escola,
      data_nascimento: ['', Validators.required],
      nome_aluno: ['', Validators.required],
      sexo_aluno: ['', Validators.required],
      serie_aluno: ['', Validators.required],
      turno_aluno: ['', Validators],
      turma_aluno: ['', Validators],
      tel_aluno: ['', Validators.required]
    }
    );
    this.secondFormGroup = this.formBuilder.group({
      estado: [''],
      municipio: [''],
      bairro: [''],
      logradouro: [''],
      numero: [''],
      cep: [''],
      complemento: ['']
    });
  }

  cadastrarAluno() {
    const loading = this.dialog.open(LoadingComponent, {
      width: '150px',
    });

    const object = {
      usuario: {},
      endereco: this.secondFormGroup.value,
      aluno: this.firstFormGroup.value
    };

    this.service.verificarSeTelefoneAlunoPossuiCadastro(this.firstFormGroup.value.tel_aluno).subscribe(data => {
      if (data.json().length > 0) {
        this.showAlert('warning', 'Este Telefone já possui cadastro! Tente outro número!');
        loading.close();
      } else {

        this.service.cadastrarAluno(object).subscribe(res => {
          this.showAlert('success', 'Sucesso!!');
          this.onNoClick();
          loading.close();
        }, err => {
          console.log(err);
          this.showAlert('error', err);
          loading.close();
        });

      }
    });
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
