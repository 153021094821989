import { Http, RequestOptions, Headers } from '@angular/http';
import { DatePipe } from '@angular/common';
import { VarGlobalProvider } from './var-global';
import { ServiceService } from '../service.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "@angular/common";
import * as i3 from "./var-global";
var PageSeguroService = /** @class */ (function () {
    function PageSeguroService(http, datepipe, varGlobais) {
        this.http = http;
        this.datepipe = datepipe;
        this.varGlobais = varGlobais;
        this.dados = new Dados();
    }
    // MÉTODO QUE DISPARA OUTROS MÉTODOS NECESSÁRIOS PARA A UTILIZAÇÃO DA API DO PAGSEGURO
    PageSeguroService.prototype.iniciar = function (email, token, isSandBox) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.credencial = new Credencial();
            _this.dados = new Dados();
            _this.credencial.key = _this.datepipe.transform(new Date(), 'ddMMyyyyHHmmss');
            _this.getSession(email, token, isSandBox).then(function () {
                _this.carregaPagSeguroDirectPayment().then(function () {
                    PagSeguroDirectPayment.setSessionId(_this.credencial.idSession);
                    localStorage.setItem('credencial', JSON.stringify(_this.credencial));
                    resolve({ status: 'ok' });
                    // this.buscarMeiosDepagamento();
                });
            });
            // if (!this.varGlobais.getStatusScript()) {
            //   this.getSession(email, token, isSandBox).then(() => {
            //     this.carregaPagSeguroDirectPayment().then(() => {
            //       PagSeguroDirectPayment.setSessionId(this.credencial.idSession);
            //       localStorage.setItem('credencial', JSON.stringify(this.credencial));
            //       console.log('PagSeguroDirectPayment', PagSeguroDirectPayment);
            //       this.buscarMeiosDepagamento();
            //     });
            //   });
            // }
        });
    };
    // RETORNA A SESSÃO QUE VAI SER UTILIZADA PELA API
    // ESTE É UM ID QUE É GERADO PELA API DO PAGSEGURO PARA FAZER O
    // CONSUMO PARA CONCRETIZAR A TRANSAÇÃO
    PageSeguroService.prototype.getSession = function (email, token, isSandBox) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/session', {
                email: email, token: token, isSandBox: isSandBox
            }).subscribe(function (data) {
                _this.credencial = data.json();
                resolve(_this.credencial);
            });
        });
    };
    // CARREGA O JAVASCRIPT DO PAGSEGURO PARA NOSSA VARIÁVEL
    PageSeguroService.prototype.carregaPagSeguroDirectPayment = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var script = document.createElement('script');
            script.addEventListener('load', function (r) { return resolve(); });
            script.src = _this.credencial.urlPagSeguroDirectPayment;
            document.head.appendChild(script);
        });
    };
    //Lista meios de pagamentos disponiveis
    PageSeguroService.prototype.buscarMeiosDepagamento = function () {
        return new Promise(function (resolve, reject) {
            PagSeguroDirectPayment.getPaymentMethods({
                amount: 500.00,
                success: function (response) {
                    // Retorna os meios de pagamento disponíveis.
                    resolve(response);
                },
                error: function (response) {
                    // Callback para chamadas que falharam.
                    reject(response);
                }
            });
        });
    };
    //busca bandeira de cartão de credito
    PageSeguroService.prototype.buscaBandeira = function (cardBin) {
        return new Promise(function (resolve, reject) {
            PagSeguroDirectPayment.getBrand({
                cardBin: cardBin,
                success: function (response) {
                    //bandeira
                    resolve(response);
                },
                error: function (response) {
                    //tratamento do erro
                    reject(response);
                }
            });
        });
    };
    //busca pafcelas do cartão
    PageSeguroService.prototype.buscaParcelas = function (valor, brand) {
        var _this = this;
        this.dados.noInterestInstallmentQuantity = '2';
        return new Promise(function (resolve, reject) {
            PagSeguroDirectPayment.getInstallments({
                amount: valor,
                maxInstallmentNoInterest: parseInt(_this.dados.noInterestInstallmentQuantity),
                brand: brand,
                success: function (response) {
                    // Retorna as opções de parcelamento disponíveis
                    resolve(response);
                },
                error: function (response) {
                    // callback para chamadas que falharam.
                    reject(response);
                },
            });
        });
    };
    //obter token do cartão de credito
    PageSeguroService.prototype.finalizarCompra = function (cardNumber, brand, cvv, expirationMonth, expirationYear, paymentMethod) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            PagSeguroDirectPayment.createCardToken({
                cardNumber: cardNumber,
                brand: brand,
                cvv: cvv,
                expirationMonth: expirationMonth,
                expirationYear: expirationYear,
                success: function (response) {
                    // Retorna o cartão tokenizado.
                    _this.dados.hashCard = response.card.token;
                    // Obtem Token do comprador
                    PagSeguroDirectPayment.onSenderHashReady(function (response) {
                        if (response.status == 'error') {
                            reject(response.message);
                        }
                        else {
                            _this.dados.paymentMethod = paymentMethod;
                            var hash = response.senderHash; //Hash estará disponível nesta variável.
                            _this.dados.hashComprador = hash;
                            _this.pagarCheckoutTransp(_this.dados).subscribe(function (res) {
                                resolve(res);
                            }, function (err) {
                                reject(err);
                            });
                        }
                    });
                },
                error: function (response) {
                    // Callback para chamadas que falharam.
                    reject(response);
                }
            });
        });
    };
    //Envia dados de compra para o servidor
    PageSeguroService.prototype.pagarCheckoutTransp = function (dados) {
        var headers = new Headers({ 'Content-Type': 'application/json' });
        var options = new RequestOptions({ headers: headers });
        return this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/pagar', { dados: dados, credencial: this.credencial }, options);
    };
    PageSeguroService.prototype.gerarBoleto = function (dados) {
        return this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/boleto', { dados: dados, credencial: this.credencial }).map(function (res) { return res.json(); });
    };
    PageSeguroService.prototype.checkoutPagSeguro = function (dados, credencial) {
        return this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/checkout', { dados: dados, credencial: credencial });
    };
    PageSeguroService.ngInjectableDef = i0.defineInjectable({ factory: function PageSeguroService_Factory() { return new PageSeguroService(i0.inject(i1.Http), i0.inject(i2.DatePipe), i0.inject(i3.VarGlobalProvider)); }, token: PageSeguroService, providedIn: "root" });
    return PageSeguroService;
}());
export { PageSeguroService };
// CLASSE PARA ARMAZENAR NOSSOS DADOS DE ACESSO A CONTA DO PAGSEGURO
var Credencial = /** @class */ (function () {
    function Credencial() {
    }
    return Credencial;
}());
export { Credencial };
// CLASSE PARA ARMAZENAR OS DADOS DA TRANSAÇÃO DE CHECKOUT NECESSÁRIOS PARA CONSUMIR A API
var Dados = /** @class */ (function () {
    function Dados(obj) {
        this.nome = 'Janiel Tertuliano';
        this.ddd = '77';
        this.telefone = '988997889';
        this.email = 'c73229586123411607449@sandbox.pagseguro.com.br';
        this.cpf = '957.937.874-61'.replace(/(\.|\/|\-)/g, "");
        this.CardCpf = '957.937.874-61'.replace(/(\.|\/|\-)/g, "");
        this.nascimento = '16/09/1987';
        this.logradouro = 'Av Central';
        this.numero = '45';
        this.bairro = 'Centro';
        this.cep = '45002-055';
        this.cidade = 'Vitória da Conquista';
        this.estado = 'BA';
        this.numCard = '4111111111111111';
        this.mesValidadeCard = '12';
        this.anoValidadeCard = '2030';
        this.codSegCard = '123';
        this.parcelas = []; // preenchido dinamicamente
        Object.assign(this, obj, {}, {});
    }
    return Dados;
}());
export { Dados };
