<div class="body-wrap boxed-container" (scroll)="onWindowScroll($event);">
  <app-menu></app-menu>
  <main>
    <section class="hero">
      <div class="container">
        <div class="hero-inner">
          <div class="hero-copy">
            <h1 class="hero-title h2-mobile mt-0 is-revealing">Fomos feitos para sua escola.</h1>
            <p class="hero-paragraph is-revealing">#Juntos no combate ao Bullying</p>
            <p class="is-revealing">
              <a class="button button-primary button-shadow" routerLink="/cadastro">Cadastre-se</a>
            </p>
          </div>

          <div class="hero-illustration is-revealing">
            <!-- <img src="assets/img/nobully.svg" alt=""> -->
            <div class="play hero-cta" data-video-id="n1fjJ6pLrag">
              <img src="assets/img/play.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features-extended section">
      <div class="container">
        <div class="features-extended-inner section-inner has-top-divider">
          <div class="feature-extended">
            <div class="feature-extended-image is-revealing">
              <img src="assets/img/intro-01.png" alt="">
            </div>
            <div class="feature-extended-body">
              <small class="hero-paragraph">#Vantagens e Benefícios</small>
              <h3>Fomos feito para a sua escola</h3>
              <p class="text-sm">
                <ul class="list-description" [style.]>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Inovação e tecnologia</p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Canal de comunicação</p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Cumprimento das leis</p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Escola mais segura e saudável
                    </p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Inclusão social</p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Marketing positivo</p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Maior rendimento escolar</p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Captação de alunos</p>
                  </li>
                  <li class="topics">
                    <p> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#C3E678;"
                          d="M256,512C114.844,512,0,397.156,0,256S114.844,0,256,0s256,114.844,256,256S397.156,512,256,512z" />
                        <path style="fill:#A5D76E;" d="M375.467,426.667c-141.156,0-256-114.844-256-256c0-59.087,20.318-113.41,54.071-156.783
                          C72.768,48.311,0,143.72,0,256c0,141.156,114.844,256,256,256c82.069,0,155.049-38.974,201.929-99.217
                          C432.012,421.638,404.342,426.667,375.467,426.667z" />
                        <path style="fill:#FFFFFF;" d="M203.034,388.414c-4.518,0-9.038-1.725-12.483-5.173L84.62,277.31
                          c-6.897-6.892-6.897-18.073,0-24.966c6.888-6.897,18.078-6.897,24.966,0l93.449,93.444l181.724-181.72
                          c6.888-6.897,18.078-6.897,24.966,0c6.897,6.892,6.897,18.073,0,24.966L215.517,383.241
                          C212.073,386.689,207.552,388.414,203.034,388.414z" /> </svg> Menos evasão escolar</p>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="features section text-center">
      <div class="section-square"></div>
      <div class="container">
        <div class="features-inner section-inner">
          <div class="features-wrap">
            <div class="feature is-revealing">
              <div class="feature-inner">
                <div class="feature-icon">
                  <img src="https://img.icons8.com/color/48/000000/touchscreen-smartphone.png">
                </div>
                <h6 class="feature-title h3-mobile">Relato</h6>
                <p class="text-sm">No aplicativo, o usuário pode relatar o ocorrido. Este relato pode ser feito em anonimato.</p>
              </div>
            </div>
            <div class="feature is-revealing">
              <div class="feature-inner">
                <div class="feature-icon">
                  <img src="https://img.icons8.com/color/48/000000/expired.png">
                </div>
                <h6 class="feature-title h3-mobile">Alerta</h6>
                <p class="text-sm">Conta com uma ferramenta de alerta instantâneo, onde é enviado um
                  pedido de socorro em tempo real para a escola.</p>
              </div>
            </div>
            <div class="feature is-revealing">
              <div class="feature-inner">
                <div class="feature-icon">
                  <img src="https://img.icons8.com/color/48/000000/google-docs.png">
                </div>
                <h6 class="feature-title h3-mobile">Educativos</h6>
                <p class="text-sm">Disponibiliza materiais educativos desenvolvidos por psicólogos, que educa e
                  conscientiza os alunos sobre o Bullying.</p>
              </div>
            </div>
            <div class="feature is-revealing">
              <div class="feature-inner">
                <div class="feature-icon">
                  <img src="https://img.icons8.com/color/48/000000/controller.png">
                </div>
                <h6 class="feature-title h3-mobile">Jogos</h6>
                <p class="text-sm">Dispõe de jogo educativo de perguntas e repostas. O jogo é um mecanismo que auxilia na
                  conscientização do Bullying e usabilidade do aplicativo.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features" class="section-padding">
      <div class="container">
        <div class="section-header text-center">
          <small class="hero-paragraph">#Produtos e Serviços</small>
          <h2 class="section-title wow fadeInDown">Por que contratar a NoBully?</h2>
          <div class="shape wow fadeInDown"></div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="content-left">
              <div class="box-item wow fadeInLeft" data-wow-delay="0.3s">
                <span class="icon">
                  <i class="lni-emoji-smile"></i>
                </span>
                <div class="text">
                  <h4>COMBATE AO BULLYING</h4>
                  <p>Nosso projeto é conscientizar, prevenir e combater o Bullying nas escolas.</p>
                </div>

              </div>
              <div class="box-item wow fadeInLeft" data-wow-delay="0.6s">
                <span class="icon">
                  <i class="lni-mobile"></i>
                </span>
                <div class="text">
                  <h4>APP NO BULLY-ALUNO</h4>
                  <p> É um canal de comunicação sem barreiras para as vítimas, que podem relatar de forma prática,
                    segura e com total sigilo as ocorrências de Bullying sofridos e seus sentimentos. O app dispõe de
                    jogos e materiais educativos elaborados por psicólogos, além de contar com construção de avatar, que
                    trazem melhor experiência de uso.</p>
                </div>
              </div>
              <div class="box-item wow fadeInLeft" data-wow-delay="0.9s">
                <span class="icon">
                  <i class="lni-bar-chart"></i>
                </span>
                <div class="text">
                  <h4>MAPEAMENTO ESTATÍSTICO</h4>
                  <p>Apresenta um cenário de Bullying mais fidedigno, auxiliando as escolas a agirem de maneira
                    preventiva, cuidadosa e amorosa com as vítimas. Além de gerar relatórios periódicos* para as
                    escolas. </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="show-box wow fadeInUp" data-wow-delay="0.3s">
              <img src="assets/img/mockup-gestao.png" alt="">
            </div>
            <p class="text-center is-revealing" [style.margin-top.px]="'10'">
              <a class="button button-primary button-shadow" routerLink="/cadastro">Cadastre-se</a>
            </p>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="content-right">
              <div class="box-item wow fadeInRight" data-wow-delay="0.3s">
                <span class="icon">
                  <i class="lni-control-panel"></i>
                </span>
                <div class="text">
                  <h4>APP NOBULLY-GESTÃO</h4>
                  <p>Mais praticidade e comodidade no acompanhamento dos relatórios para os profissionais responsáveis
                    das escolas.</p>
                </div>
              </div>
              <div class="box-item wow fadeInRight" data-wow-delay="0.6s">
                <span class="icon">
                  <i class="lni-layers"></i>
                </span>
                <div class="text">
                  <h4>RELATÓRIOS PRÁTICOS</h4>
                  <p>Através do nosso Sistema Web, a escola pode cadastrar e alterar dados dos seus alunos, gerenciar os
                    relatos recebidos, ter acesso ao mapeamento estatístico (gráficos analíticos sobre o Bullying) e aos
                    seus relatórios de Bullying.</p>
                </div>
              </div>
              <div class="box-item wow fadeInRight" data-wow-delay="0.9s">
                <span class="icon">
                  <i class="lni-headphone"></i>
                </span>
                <div class="text">
                  <h4>SUPORTE TÉCNICO</h4>
                  <p>Para maior comodidade e garantia de bom funcionamento dos nossos serviços, contamos com Suporte
                    Técnico de alta qualidade.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features-extended section">
      <div class="container">
        <div class="features-extended-inner section-inner has-top-divider">
          <!-- <div class="features-extended-header text-center">
            <div class="container-sm">
              <h2 class="section-title mt-0">Meet Evelyn</h2>
              <p class="section-paragraph">Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation</p>
            </div>
          </div> -->
          <div class="feature-extended">
            <div class="feature-extended-image is-revealing">
              <img src="assets/img/feature-bullyng.svg" alt="">
            </div>
            <div class="feature-extended-body">
              <small class="hero-paragraph">#Barreiras Superadas</small>
              <h3>Bullying é assunto sério</h3>
              <p class="text-sm">
                <ul class="list-description">
                  <li>
                    <p>Falta de um cenário mais fidedigno, impedindo assim de prevenir o Bullying</p>
                  </li>
                  <li>
                    <p>Necessidade de um canal de comunicação sem barreiras para as vítimas que passe segurança para
                      denunciar seu sofrimento</p>
                  </li>
                  <li>
                    <p>Dificuldade em identificar o bullying</p>
                  </li>
                </ul>
            </div>
          </div>
          <div class="feature-extended">
            <div class="feature-extended-image is-revealing">
              <img src="assets/img/prototype.svg" alt="">
            </div>
            <div class="feature-extended-body list-description">
              <small class="hero-paragraph">#Ralatos De Forma Prática e Segura</small>
              <h3 class="mt-0 ">O app na prática</h3>
              <p class="text-sm mb-32">O aplicativo NoBully propõe fomentar a conscientização, prevenção e combate ao
                bullying nas escolas, atuando como uma ponte entre as vítimas e os responsáveis legais daquelas. Com o
                aplicativo é possível relatar de forma prática e segura o bullying, recorrer ao alerta de emergência,
                bem como ter acesso a materiais educativos, jogos e avatares. </p>
              <p class="is-revealing"><a class="button button-primary button-shadow" target="_blank"
                  href="http://nobully.com.br/prototipo-app-nobully">Veja o app na prática </a></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="princing section" *ngIf="false">
      <div class="section-square"></div>
      <div class="container">
        <div class="pricing-inner section-inner has-top-divider">
          <h2 class="section-title mt-0 text-center">Planos</h2>
          <p class="hero-paragraph is-revealing text-center mb-10">Seja um apoiador desta causa e experimente por 30
            dias grátis</p>
          <div class="pricing-tables-wrap" [style.margin-top.px]="'50'">

            <div class="pricing-table" *ngFor="let item of planos">
              <div class="pricing-table-inner">
                <div class="pricing-table-main">
                  <div class="pricing-table-header is-revealing">
                    <div class="pricing-table-price-amount mt-12 mb-8" align="center"> {{ item.desc_plano }} </div>
                    <div class="pricing-table-price mb-32 pb-24">
                      <div align="center">
                        <span class="pricing-table-price-currency h4">R$</span><span
                          class="pricing-table-price-amount h2">{{ item.valor_plano }}</span>/anual
                      </div>
                    </div>
                  </div>
                  <ul class="pricing-table-features list-reset text-xs mt-24 mb-56">
                    <li class="is-revealing">
                      <span class="list-icon">
                        <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                            fill="#00C6A7" fill-rule="nonzero" />
                        </svg>
                      </span>
                      <span>{{ item.qtd_aluno }} acessos para alunos no App NOBULLY.</span>
                    </li>
                    <li class="is-revealing">
                      <span class="list-icon">
                        <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                            fill="#00C6A7" fill-rule="nonzero" />
                        </svg>
                      </span>
                      <span>Acesso da escola ao sistema web mediante a login e senha através do site.</span>
                    </li>
                    <li class="is-revealing">
                      <span class="list-icon">
                        <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                            fill="#00C6A7" fill-rule="nonzero" />
                        </svg>
                      </span>
                      <span>Atende alunos do Ensino Fundamental ao Ensino Médio.</span>
                    </li>
                    <li class="is-revealing">
                      <span class="list-icon">
                        <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                            fill="#00C6A7" fill-rule="nonzero" />
                        </svg>
                      </span>
                      <span>Recomendado para escolas de pequeno porte.</span>
                    </li>
                    <li class="is-revealing">
                      <span class="list-icon">
                        <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                            fill="#00C6A7" fill-rule="nonzero" />
                        </svg>
                      </span>
                      <span>Treinamento para uso das ferramentas.</span>
                    </li>
                  </ul>
                </div>
                <div class="pricing-table-cta">
                  <!-- <a href="{{ item.link_pagseguro }}" target="_blank" class="button button-primary button-shadow button-block" title="Pagar com PagSeguro">
                        Experimente
                    </a> -->

                  <a class="button button-primary button-shadow button-block"
                    (click)="iniciarSessionPagSeguro(item)">Experimente</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="princing section">
      <div class="container" style="margin-bottom: 20px; border-top: #e2e8ed solid 1px;
      padding-top: 60px; padding-bottom: 60px;">
        <div class="section-header text-center">
          <small class="hero-paragraph">#Participe</small>
          <h2 class="section-title wow fadeInDown">Faça seu cadastro e entre em contato conosco</h2>
          <a class="button button-primary button-shadow" routerLink="/cadastro"
            routerLinkActive="active">Cadastre-se</a>
          <br />
        </div>
      </div>
    </section>

    <section class="princing section" [style.padding-top.em]="'6'" [style.padding-bottom.em]="'10'">
      <div class="container">
        <div class="section-header text-center">
          <small class="hero-paragraph">#Clientes #No bully</small>
          <h2 class="section-title wow fadeInDown">Nossos Clientes</h2>
          <br />
        </div>
      </div>

      <div class="container" *ngIf="listEscolas.length != 0">
        <owl-carousel [options]="mySlideOptions" [carouselClasses]="['owl-theme', 'sliding']">
          <div class="item" *ngFor="let school of listEscolas;let i = index">
            <div class="owl-content">
              <div class="owl-image">
                <img [src]="school.image" alt="Clientes" />
              </div>
              <div class="owl-title">
                <p class="owl-header">{{ school.nome_escola }}</p>
              </div>
            </div>
          </div>
        </owl-carousel>
      </div>
    </section>
  </main>

  <app-footer></app-footer>
</div>