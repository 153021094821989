<div>
  <h4>Informe os dados solicitados</h4>
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="cnpjFormGroup">
      <form [formGroup]="cnpjFormGroup">
        <ng-template matStepLabel>CNPJ</ng-template>
        <mat-list>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="CNPJ" formControlName="cnpj" mask="00.000.000/0000-00" required>
            </mat-form-field>
          </mat-list-item>
          <div>
            <button mat-button color="primary" (click)="onNoClick()">Fechar</button>
            <button mat-stroked-button color="primary" matStepperNext (click)="findParceiroByCNPJ()">Próximo</button>
          </div>
        </mat-list>
      </form>
    </mat-step>
    <mat-step [stepControl]="dadosFormGroup">
      <form [formGroup]="dadosFormGroup">
        <ng-template matStepLabel>Dados</ng-template>
        <mat-list>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="email" placeholder="Email" formControlName="email" required>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Razão Social" formControlName="razaosocial" required>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Nome fantasia" formControlName="nomefantasia">
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Telefone" formControlName="telefone" prefix="+55 "
                mask="(00) 00000-0000">
            </mat-form-field>
          </mat-list-item>
          <div>
            <button mat-button color="primary" matStepperPrevious>Voltar</button>
            <button mat-stroked-button color="primary" matStepperNext>Próximo</button>
          </div>
        </mat-list>
      </form>
    </mat-step>
    <mat-step [stepControl]="enderecoFormGroup">
      <form [formGroup]="enderecoFormGroup">
        <ng-template matStepLabel>Endereço</ng-template>
        <mat-list>
          <mat-list-item>
            <mat-form-field>
              <mat-select placeholder="Estado" formControlName="estado" required>
                <mat-option>None</mat-option>
                <mat-option *ngFor="let estado of estados" [value]="estado">{{estado}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Municipio" formControlName="municipio" required>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Bairro" formControlName="bairro" required>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Rua" formControlName="logradouro" required>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Número" formControlName="numero" required>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Complemento" formControlName="complemento">
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <input matInput type="text" placeholder="Cep" formControlName="cep" mask="00000-000">
            </mat-form-field>
          </mat-list-item>
          <div>
            <button mat-button color="primary" matStepperPrevious>Voltar</button>
            <button mat-stroked-button color="primary" matStepperNext>Próximo</button>
          </div>
        </mat-list>
      </form>
    </mat-step>
    <mat-step>
      <form>
        <ng-template matStepLabel>Adotar Escolas</ng-template>
        <div style="margin-top: 5%">
          <label for="amount">Valor por escola R$: 2.000,00</label>
          <div>
            <label style="margin-right: 20px;">Quantas escolas você deseja patrocinar ?</label>
            <button mat-mini-fab (click)="removeQuantidade()" [disabled]="qtd_escolas == 1"> - </button>
            {{ qtd_escolas }}
            <button mat-mini-fab (click)="addQuantidade()"> + </button>
          </div>
          <label for="amount">Valor total R$: {{ valor }},00</label>
        </div>
        <div>
          <button mat-button color="primary" matStepperPrevious>Voltar</button>
          <button mat-stroked-button color="primary" matStepperNext>Próximo</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Confirmar</ng-template>
      <div>
        <button mat-button color="primary" matStepperPrevious>Voltar</button>
        <button mat-raised-button color="primary" (click)="finalizar()">Finalizar</button>
        <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
