import { Http } from '@angular/http';
import { ServiceService } from '../service.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
var PainelAdminService = /** @class */ (function () {
    function PainelAdminService(http) {
        this.http = http;
        this.Headers = new Headers();
        this.Headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.Headers.append('Content-Type', 'application/json');
        this.Headers.append('Authorization', 'Auth_Token');
        this.Headers.append('RequestToken', localStorage.getItem('token'));
        this.headers = { headers: this.Headers };
    }
    PainelAdminService.prototype.alterarStatusEscola = function (obj) {
        return this.http.put(ServiceService.URL + '/escola/alterarStatusEscola', obj, this.headers);
    };
    PainelAdminService.ngInjectableDef = i0.defineInjectable({ factory: function PainelAdminService_Factory() { return new PainelAdminService(i0.inject(i1.Http)); }, token: PainelAdminService, providedIn: "root" });
    return PainelAdminService;
}());
export { PainelAdminService };
