<notifier-container></notifier-container>
<div class="mat-elevation-z8">

  <div class="box">
    <div class="container-1">
      <span class="icon"><i class="fa fa-search"></i></span>
      <input type="search" id="search" [(ngModel)]="nome_escola" placeholder="Nome da escola..." (keypress)="pesquisarEscola($event)" />
    </div>
  </div>

  <table mat-table [dataSource]="listEscola" style="margin-top: 5%">
    <!-- Position Column -->
    <ng-container matColumnDef="nome_escola">
      <th mat-header-cell *matHeaderCellDef> Nome Escola </th>
      <td mat-cell *matCellDef="let element"> {{element.nome_escola}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="telefone">
      <th mat-header-cell *matHeaderCellDef> Telefone </th>
      <td mat-cell *matCellDef="let element"> {{element.telefone}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef> CNPJ </th>
      <td mat-cell *matCellDef="let element"> {{element.cnpj}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="tipo_escola">
      <th mat-header-cell *matHeaderCellDef> Tipo Escola </th>
      <td mat-cell *matCellDef="let element"> {{element.tipo_escola}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="ativo">
      <th mat-header-cell *matHeaderCellDef> Ativo </th>
      <td mat-cell *matCellDef="let element"> {{element.ativo}}
        <mat-slide-toggle #check [checked]="element.ativo == 'S'" (change)="alterarStatusEscola(element, check)"></mat-slide-toggle>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <!-- <mat-paginator [pageSizeOptions]="[15]" showFirstLastButtons></mat-paginator> -->
  <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
</div>
