import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import * as jwt from 'jwt-decode';
import { DatePipe } from '@angular/common';
import { VarGlobalProvider } from './var-global';
import xml2js from 'xml2js';
import { ServiceService } from '../service.service';

declare var PagSeguroDirectPayment: any;

@Injectable({
  providedIn: 'root'
})
export class PageSeguroService {

  public credencial: Credencial;
  public dados = new Dados();
  constructor(
    private http: Http,
    private datepipe: DatePipe,
    private varGlobais: VarGlobalProvider) {
  }

  // MÉTODO QUE DISPARA OUTROS MÉTODOS NECESSÁRIOS PARA A UTILIZAÇÃO DA API DO PAGSEGURO
  iniciar(email, token, isSandBox) {
    return new Promise((resolve) => {
      this.credencial = new Credencial();
      this.dados = new Dados();
      this.credencial.key = this.datepipe.transform(new Date(), 'ddMMyyyyHHmmss');

      this.getSession(email, token, isSandBox).then(() => {
        this.carregaPagSeguroDirectPayment().then(() => {
          PagSeguroDirectPayment.setSessionId(this.credencial.idSession);
          localStorage.setItem('credencial', JSON.stringify(this.credencial));
          resolve({ status: 'ok' });
          // this.buscarMeiosDepagamento();
        });
      });
      // if (!this.varGlobais.getStatusScript()) {
      //   this.getSession(email, token, isSandBox).then(() => {
      //     this.carregaPagSeguroDirectPayment().then(() => {
      //       PagSeguroDirectPayment.setSessionId(this.credencial.idSession);
      //       localStorage.setItem('credencial', JSON.stringify(this.credencial));
      //       console.log('PagSeguroDirectPayment', PagSeguroDirectPayment);
      //       this.buscarMeiosDepagamento();
      //     });
      //   });
      // }
    });
  }
  // RETORNA A SESSÃO QUE VAI SER UTILIZADA PELA API
  // ESTE É UM ID QUE É GERADO PELA API DO PAGSEGURO PARA FAZER O
  // CONSUMO PARA CONCRETIZAR A TRANSAÇÃO
  getSession(email, token, isSandBox) {
    return new Promise((resolve) => {
      this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/session', {
        email: email, token: token, isSandBox: isSandBox
      }).subscribe(data => {
        this.credencial = data.json();
        resolve(this.credencial);
      })
    });
  }

  // CARREGA O JAVASCRIPT DO PAGSEGURO PARA NOSSA VARIÁVEL
  carregaPagSeguroDirectPayment() {
    return new Promise((resolve) => {
      let script: HTMLScriptElement = document.createElement('script');
      script.addEventListener('load', r => resolve());
      script.src = this.credencial.urlPagSeguroDirectPayment;
      document.head.appendChild(script);
    });
  }

  //Lista meios de pagamentos disponiveis
  buscarMeiosDepagamento() {
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.getPaymentMethods({
        amount: 500.00,
        success: (response) => {
          // Retorna os meios de pagamento disponíveis.
          resolve(response);
        },
        error: (response) => {
          // Callback para chamadas que falharam.
          reject(response);
        }
      });
    });
  }

  //busca bandeira de cartão de credito
  buscaBandeira(cardBin) {
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.getBrand({
        cardBin: cardBin,
        success: (response) => {
          //bandeira
          resolve(response);
        },
        error: (response) => {
          //tratamento do erro
          reject(response);
        }
      });
    });
  }

  //busca pafcelas do cartão
  buscaParcelas(valor, brand) {
    this.dados.noInterestInstallmentQuantity = '2';
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.getInstallments({
        amount: valor,
        maxInstallmentNoInterest: parseInt(this.dados.noInterestInstallmentQuantity),
        brand: brand,
        success: (response) => {
          // Retorna as opções de parcelamento disponíveis
          resolve(response);
        },
        error: (response) => {
          // callback para chamadas que falharam.
          reject(response);
        },
      });
    });
  }

  //obter token do cartão de credito
  finalizarCompra(cardNumber, brand, cvv, expirationMonth, expirationYear, paymentMethod) {
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.createCardToken({
        cardNumber: cardNumber, // Número do cartão de crédito
        brand: brand, // Bandeira do cartão
        cvv: cvv, // CVV do cartão
        expirationMonth: expirationMonth, // Mês da expiração do cartão
        expirationYear: expirationYear, // Ano da expiração do cartão, é necessário os 4 dígitos.
        success: (response) => {
          // Retorna o cartão tokenizado.
          this.dados.hashCard = response.card.token;
          // Obtem Token do comprador
          PagSeguroDirectPayment.onSenderHashReady((response) => {
            if (response.status == 'error') {
              reject(response.message);
            } else {
              this.dados.paymentMethod = paymentMethod;
              let hash = response.senderHash; //Hash estará disponível nesta variável.
              this.dados.hashComprador = hash;
              this.pagarCheckoutTransp(this.dados).subscribe(res => {
                resolve(res);
              }, err => {
                reject(err);
              });
            }
          });
        },
        error: (response) => {
          // Callback para chamadas que falharam.
          reject(response);
        }
      });
    });
  }

  //Envia dados de compra para o servidor
  pagarCheckoutTransp(dados: Dados) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/pagar',
      { dados: dados, credencial: this.credencial }, options);
  }

  gerarBoleto(dados: Dados) {
    return this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/boleto', { dados: dados, credencial: this.credencial }).map(res => res.json());
  }

  checkoutPagSeguro(dados, credencial) {
    return this.http.post(ServiceService.URL_ApiPagamento + '/pagseguro/checkout', { dados: dados, credencial: credencial });
  }
}

// CLASSE PARA ARMAZENAR NOSSOS DADOS DE ACESSO A CONTA DO PAGSEGURO
export class Credencial {
  key: string;
  urlSession: string;
  urlPagSeguroDirectPayment: string;
  urlTransacao: string;
  idSession: string;
  email: string;
  token: string;
  isSandBox: boolean;
}

// CLASSE PARA ARMAZENAR OS DADOS DA TRANSAÇÃO DE CHECKOUT NECESSÁRIOS PARA CONSUMIR A API
export class Dados {
  public id: number;
  public nome: string = 'Janiel Tertuliano';
  public ddd: string = '77';
  public telefone: string = '988997889';
  public email: string = 'c73229586123411607449@sandbox.pagseguro.com.br';
  public cpf: string = '957.937.874-61'.replace(/(\.|\/|\-)/g, "");
  public CardCpf: string = '957.937.874-61'.replace(/(\.|\/|\-)/g, "");
  public nascimento: string = '16/09/1987';
  public logradouro: string = 'Av Central';
  public numero: string = '45';
  public bairro: string = 'Centro';
  public cep: string = '45002-055';
  public cidade: string = 'Vitória da Conquista';
  public estado: string = 'BA';
  public numCard: string = '4111111111111111';
  public mesValidadeCard: string = '12';
  public anoValidadeCard: string = '2030';
  public codSegCard: string = '123';
  public hashComprador: string;        // preenchido dinamicamente
  public bandCard: string;             // preenchido dinamicamente
  public hashCard: string;             // preenchido dinamicamente
  public parcelas: Array<Object> = []; // preenchido dinamicamente
  public paymentMethod: string;
  public itemDescription1: string;
  public itemAmount1: string;
  public noInterestInstallmentQuantity: string;
  public installmentQuantity: string;
  public installmentValue: string;
  constructor(obj?) {
    Object.assign(this, obj, {}, {});
  }
}
