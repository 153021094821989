import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import * as jwt from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  static URL: string = environment.urlApi;
  static URL_ApiPagamento: string = environment.urlApiPagamento;
  private headers: {};
  private Headers: Headers;

  constructor(private http: Http) {
    ServiceService.URL = environment.urlApi;
    // ServiceService.URL = 'http://nobully.com.br:5000';
    // ServiceService.URL = 'http://localhost:3000';

    this.Headers = new Headers();
    this.Headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.Headers.append('Content-Type', 'application/json');
    this.Headers.append('Authorization', 'Auth_Token');
    this.Headers.append('RequestToken', localStorage.getItem('token'));
    this.headers = { headers: this.Headers };
  }

  validarEmailUsuario(email: string) {
    return this.http.get(ServiceService.URL + '/usuarios/email/' + email);
  }

  cadastrarUsuarioEscola(obj) {
    return this.http.post(ServiceService.URL + '/usuarios/escola', obj, this.headers);
  }

  login(obj) {
    return this.http.post(ServiceService.URL + '/auth/login', obj, this.headers);
  }

  loginAdmin(obj) {
    return this.http.post(ServiceService.URL + '/auth/admin/login', obj, this.headers);
  }

  listarAlunos(id_escola: number, skip = 0, limit = 10) {
    return this.http.get(ServiceService.URL + '/aluno/id_escola/' + id_escola + `/?skip=${skip}&limit=${limit}`, this.headers);
  }

  getAlunoEscolaByName(id_escola: number, nome_aluno: string) {
    return this.http.get(ServiceService.URL + '/aluno/id_escola/' + id_escola + '/nome_aluno/' + nome_aluno, this.headers);
  }

  cadastrarAluno(obj) {
    return this.http.post(ServiceService.URL + '/aluno', obj, this.headers);
  }

  verificarSeTelefoneAlunoPossuiCadastro(tel_aluno) {
    return this.http.get(ServiceService.URL + '/aluno/validar_telefone/' + tel_aluno, this.headers);

  }

  getAlunoById(id) {
    return this.http.get(`${ServiceService.URL}/aluno/id_aluno/${id}`);
  }

  editarAluno(obj) {
    return this.http.put(`${ServiceService.URL}/aluno/id_aluno/${JSON.stringify(obj.ids)}`, obj.aluno);
  }

  getEscolaById(id) {
    return this.http.get(`${ServiceService.URL}/escola/id_escola/${id}`);
  }

  editEscola(object) {
    return this.http.put(`${ServiceService.URL}/escola/id_escola/${JSON.stringify(object.ids)}`, object.escola);
  }

  enviarEmailRecuperarSenhaEscola(email: string) {
    return this.http.get(`${ServiceService.URL}/usuarios/recuperar_senha/${email}`);
  }

  enviarEmailBemVindoParaUsuario(email: string) {
    return this.http.get(`${ServiceService.URL}/usuarios/bem_vindo/${email}`);
  }

  mensagemEmailEscolaCadastrada(obj: any) {
    return this.http.get(`${ServiceService.URL}/usuarios/escola_cadastrada/${JSON.stringify(obj)}`);
  }

  listarEscola() {
    return this.http.get(`${ServiceService.URL}/escola`);
  }

  getEscolaByName(nome_escola) {
    return this.http.get(`${ServiceService.URL}/escola/nome_escola/${nome_escola}`);
  }

  verificaEscolaAtiva(id_escola) {
    return this.http.get(`${ServiceService.URL}/usuarios/escola/${JSON.stringify(id_escola)}/ativa`);
  }

  listarPlanos() {
    return this.http.get(`${ServiceService.URL}/planos`);
  }

  listEscolasByAtivo(ativo) {
    return this.http.get(`${ServiceService.URL}/escola/lista?ativo=${ativo}`);
  }

  listEscolasParaAdocao(ativo, tipo) {
    return this.http.get(`${ServiceService.URL}/escola/lista?ativo=${ativo}&tipo_escola=${tipo}`);
  }

  alterarDenunciaStatusEscola(object) {
    return this.http.put(`${ServiceService.URL}/denuncia/status`, object, this.headers);
  }

  insertParceiro(object) {
    return this.http.post(`${ServiceService.URL}/parceiro`, object, this.headers);
  }

  editarParceiro(object) {
    return this.http.put(`${ServiceService.URL}/parceiro`, object, this.headers);
  }

  findParceiroByCNPJ(cnpj) {
    return this.http.get(`${ServiceService.URL}/parceiro/cnpj/${cnpj}`);
  }

}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private service: ServiceService, private router: Router) {

  }

  canActivate() {
    // console.log(jwt(localStorage.getItem('token')));
    if (localStorage.getItem('token')) {
      const decoded: any = jwt(localStorage.getItem('token'));
      if (decoded.data.ativo === 'S' || decoded.data.login === 'admin') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

}

@Injectable()
export class PermissionGuard implements CanActivate {
  user: any;
  constructor(private service: ServiceService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  canActivate() {
    if (this.user.id_tipo_usuario === 3) {
      return true;
    } else {
      return false;
    }
  }

}
