<notifier-container></notifier-container>
<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Escola</ng-template>
      <mat-list>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Nome Escola" formControlName="nome_escola" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Telefone" formControlName="telefone" prefix="+55 "
              mask="(00) 00000-0000" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="email" placeholder="Email" formControlName="email" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="password" placeholder="Senha" formControlName="senha" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="password" placeholder="Confirmar Senha" formControlName="confirmPassword" required>
          </mat-form-field>
          <div class="alert alert-danger" *ngIf="firstFormGroup.controls['confirmPassword'].errors?.MutchPassword">
            Password
            not match</div>
        </mat-list-item>
        <div>
          <button mat-button color="primary" (click)="activateEdit()">Editar</button>
          <button mat-stroked-button color="primary" *ngIf="editar" matStepperNext>Próximo</button>
        </div>
      </mat-list>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Endereço</ng-template>
      <mat-list>
        <mat-list-item>
          <mat-form-field>
            <mat-select placeholder="Estado" formControlName="estado" required>
              <mat-option>None</mat-option>
              <mat-option *ngFor="let estado of estados" [value]="estado">{{estado}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Municipio" formControlName="municipio" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Bairro" formControlName="bairro" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Rua" formControlName="logradouro" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Número" formControlName="numero" required>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Complemento" formControlName="complemento">
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input matInput type="text" placeholder="Cep" formControlName="cep" mask="00000-000">
          </mat-form-field>
        </mat-list-item>
        <div>
          <button mat-button color="primary" *ngIf="editar" matStepperPrevious>Voltar</button>
          <button mat-stroked-button *ngIf="editar" color="primary" matStepperNext>Próximo</button>
        </div>
      </mat-list>
    </form>
  </mat-step>
  <mat-step *ngIf="editar">
    <ng-template matStepLabel>Feito</ng-template>
    Salvar edições?
    <div>
      <button mat-button color="primary" matStepperPrevious>Voltar</button>
      <button mat-raised-button color="primary" (click)="verifyEdit()">Finalizar</button>
      <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
    </div>
  </mat-step>
</mat-horizontal-stepper>
