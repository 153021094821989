import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
import { LoadingComponent } from 'src/app/utils/loading.component';

@Component({
  selector: 'app-login-adm',
  templateUrl: './login-adm.component.html',
  styleUrls: ['./login-adm.component.css']
})
export class LoginAdmComponent implements OnInit {

  loginFormGroup: FormGroup;
  notifier: NotifierService;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private service: ServiceService,
    private router: Router) {
    this.notifier = notifierService;
  }
  ngOnInit() {
    document.body.classList.add('body_login_adm');

    this.loginFormGroup = this.formBuilder.group({
      login: ['', Validators.required],
      senha: ['', Validators.required],
    });
  }


  login() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '150px';

    const loading = this.dialog.open(LoadingComponent, dialogConfig);

    this.service.loginAdmin(this.loginFormGroup.value).subscribe(res => {
      localStorage.setItem('token', res.json().token);
      localStorage.setItem('user', JSON.stringify(res.json().usuario));
      this.router.navigate(['/painel']);
      loading.close();
    }, err => {
        this.showAlert('warning', err.json().error);
      loading.close();
    });
  }

  showAlert(type, message) {
    this.notifier.show({
      type: type, // success, error, warning
      message: message
    });

    setTimeout(() => {
      this.notifier.hideOldest();
    }, 3000);
  }
}
