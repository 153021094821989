import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
  navbarOpen: boolean;

  constructor() {
    this.navbarOpen = false;
  }
  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }
}
